import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Radio from "../Inputs/Radio";
import generateChangePermissionSchema from "./ChangePermissionModalValidation";

import { Membership } from "interfaces/UserInterface";

interface ModalProps {
  show: boolean;
  membership: Membership;
  handleClose: (permission: Membership["permissionGroup"] | null, save: boolean) => void;
}

const ChangePermissionModal: React.FC<ModalProps> = ({ show, membership, handleClose }) => {
  const { t } = useTranslation();
  const { handleSubmit, setValue, register, errors } = useForm({
    validationSchema: generateChangePermissionSchema(t),
  });

  const permissionOptions = [
    { value: "location_admin", labelValue: t("permission.location_admin") },
    { value: "location_user", labelValue: t("permission.location_user") },
  ];

  /**
   * Onlys close
   */
  function onlyClose() {
    handleClose(null, false);
  }

  /**
   * Validates and save
   * @param data
   */
  function validateAndSave(data: Record<string, any>) {
    const { permissionGroup } = data;
    handleClose(permissionGroup, true);
  }

  return (
    <Modal
      restoreFocus={false}
      enforceFocus={false}
      autoFocus={false}
      show={show}
      animation={false}
      onHide={onlyClose}
      className="permission-modal"
      size="sm"
      centered
      style={{ overflow: "hidden" }}>
      <Modal.Header className={"border-0"} closeButton>
        <Modal.Title style={{ wordBreak: "break-word" }} className="txt-marine-blue font-size-base font-weight-bold">
          {t("users.changePermission") + " \n" + membership.user.email}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(validateAndSave)}>
        <Modal.Body className="border-0" style={{ overflow: "hidden" }}>
          <hr className="mt-0 mb-3 w-100" />
          <div className="d-flex justify-content-center">
            <div className="justify-content-start w-100">
              <Radio
                name="permissionGroup"
                setValue={setValue}
                error={errors.permission}
                reference={register}
                options={permissionOptions}
                defaultCheckedValue={membership.permissionGroup}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button className="btn primary ml-2 capitalized" data-testid="submitPermissionChange" type="submit">
            {t("users.permissionChange.save")}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChangePermissionModal;
