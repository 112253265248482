import { ThunkResult } from "interfaces/StoreInterface";
import { CurrentUser, Membership } from "interfaces/UserInterface";
import * as AccountActions from "store/actions/AccountActions";
import { AccountService } from "services";
import { MembershipUserForm } from "components/UserDataForm/UserDataForm";
import { ChangePasswordForm } from "pages/ChangePasswordPage/ChangePasswordPage";

/**
 * Sets new session using credentials passed in params
 * @param email
 * @param password
 * @param rememberMe
 */
export function login(email: string, password: string, rememberMe: boolean): ThunkResult<Promise<CurrentUser>> {
  return async (dispatch) => {
    await AccountService.login(email, password, rememberMe);
    const response = await AccountService.fetchCurrentUser();
    const user = response.data.user;
    dispatch(AccountActions.setSession(user));
    return user;
  };
}

/**
 * Fetchs current user
 * @returns current user
 */
export function fetchCurrentUser(): ThunkResult<Promise<CurrentUser>> {
  return async (dispatch) => {
    const response = await AccountService.fetchCurrentUser();
    const user = response.data.user;
    dispatch(AccountActions.updateSession(user));
    return user;
  };
}
/**
 * Logs out the user (removes session and cookies)
 */
export function logout(): ThunkResult<void> {
  return (dispatch) => {
    dispatch(AccountActions.removeSession());
    AccountService.logout();
  };
}

/**
 * Sets user password
 * @param password
 * @param passwordRepeat
 * @param id
 * @returns password
 */
export function setPassword(password: string, passwordRepeat: string, id: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await AccountService.setPassword({ password, passwordRepeat, id });
  };
}

/**
 * Requests email with procedure to restart the user pasword for given email address
 * @param email
 * @returns password
 */
export function resetPassword(email: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await AccountService.resetPassword({ email });
  };
}

/**
 * Gets user by reset password id
 * @param id
 * @returns user by reset password id
 */
export function getUserByResetPasswordId(id: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await AccountService.getUserByResetPasswordId(id);
  };
}

/**
 * Invites new user to company
 * @param activeMembership
 * @param email
 * @param permissionGroup
 * @returns new user to company
 */
export function inviteNewUserToCompany(
  activeMembership: Membership,
  email: string,
  permissionGroup: string,
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await AccountService.inviteNewUserToCompany(activeMembership, email, permissionGroup);
  };
}

/**
 * Determines whether activated membership is
 * @param activationId
 * @returns
 */
export function isActivatedMembership(activationId: string) {
  return async (dispatch: any) => {
    return await AccountService.isActivatedMembership(activationId);
  };
}

/**
 * Activates membership
 * @param formData
 * @param accountId
 * @returns
 */
export function ActivateMembership(formData: any, accountId: string) {
  return async (dispatch: any) => {
    return await AccountService.ActivateMembership(formData, accountId);
  };
}

/**
 * Gets memberships from company
 * @param activeMembership
 * @param company_id
 * @param [query]
 * @returns memberships from company
 */
export function getMembershipsFromCompany(
  activeMembership: Membership,
  company_id: string,
  query: string = "",
): ThunkResult<Promise<any>> {
  return async (dispatch: any) => {
    return await AccountService.getMembershipsFromCompany(activeMembership, company_id, query);
  };
}

/**
 * Deletes membership by id
 * @param activeMembershipId
 * @param membershipId
 * @returns membership by id
 */
export function deleteMembershipById(activeMembershipId: string, membershipId: string): ThunkResult<Promise<any>> {
  return async (dispatch: any) => {
    return await AccountService.deleteMembershipById(activeMembershipId, membershipId);
  };
}

/**
 * Changes user permission
 * @param activeMembership
 * @param permissionGroup
 * @param membershipId
 * @returns user permission
 */
export function changeUserPermission(
  activeMembership: Membership,
  permissionGroup: Membership["permissionGroup"],
  membershipId: string,
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await AccountService.changeUserPermission(activeMembership, permissionGroup, membershipId);
  };
}

/**
 * Gets user by id
 * @param activeMembership
 * @param id
 * @returns user by id
 */
export function getUserById(activeMembership: Membership, id: string): ThunkResult<Promise<Membership>> {
  return async (dispatch) => {
    const { data } = await AccountService.getUserById(activeMembership, id);
    return data;
  };
}

/**
 * Updates user by id
 * @param activeMembership
 * @param id
 * @param userData
 * @returns user by id
 */
export function updateUserById(
  activeMembership: Membership,
  id: string,
  userData: MembershipUserForm,
): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    await AccountService.updateUserById(activeMembership, id, userData);
  };
}

/**
 * Changes user password
 * @param activeMembership
 * @param id
 * @param changePasswordForm
 * @returns user password
 */
export function changeUserPassword(
  activeMembership: Membership,
  id: string,
  changePasswordForm: ChangePasswordForm,
): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    await AccountService.changeUserPassword(activeMembership, id, changePasswordForm);
  };
}
