import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import FullActivateAccountForm from "components/AccountActivation/FullActivateAccountForm/FullActivateAccountForm";
import PasswordOnlyActivateAccountForm from "components/AccountActivation/PasswordOnlyActivateAccountForm/PasswordOnlyActivateAccountForm";
import OnlyButtonActivateAccountForm from "components/AccountActivation/OnlyButtonActivateAccountForm/OnlyButtonActivateAccountForm";

import { ActivateMembership, isActivatedMembership } from "store/thunk/AccountThunk";
import { mapAgreesDataToBoolean } from "utils";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";
import ActivateAccountFormProps from "interfaces/ActivationInterface";
import ActivationSubheaders from "components/AccountActivation/ActivationSubheaders/ActivationSubheaders";
import { FullActivationValidation, PasswordOnlyValidation } from "./ValidationSchemas";
import {
  updateActivationUserData,
  disablePersonalDataForm,
  updateHasUserActivatedMembership,
} from "store/actions/ActivationActions";
import { AppState } from "store/reducers/RootReducer";
import { ActivationState } from "store/reducers/ActivationReducer";

export const FULL_ACTIVATION = "full";
export const PASSWORD_ONLY_ACTIVATION = "passwordOnly";
export const ONLY_BUTTON_ACTIVATION = "onlyButton";

const ActivateAccountPage: React.FC = () => {
  const { activationId = "" } = useParams();
  const { t } = useTranslation();
  const { setMessage, showSpinner } = useMessage();
  const history = useHistory();

  const [activation_type, set_activation_type] = useState<any>(null);
  const [displayForm, setDisplayForm] = useState(false);

  const { isFullRegistration, hasUserActivatedMembership, disablePersonalForm, personal }: ActivationState =
    useSelector((state: AppState) => state.activationForm);

  const [activationState, setActivationState] = useState<ActivateAccountFormProps["activationState"]>({
    isFullRegistration: null,
    hasUserActivatedMembership: null,
    email: "",
    phoneNumber: "",
    isAdmin: null,
    isFirstAdmin: null,
    confirmedStatute: null,
    agreeToSellingEmails: "",
    agreeToSellingPhones: "",
    agreeToSharingData: "",
    agreeToMarketingEmails: "",
    agreeToMarketingPhones: "",
  });
  const [error, setError] = useState<boolean>();
  const dispatch = useDispatch();

  const schemaValidatorGenerator = () => {
    if (activationState.isFullRegistration) {
      return !activationState.hasUserActivatedMembership && PasswordOnlyValidation(t);
    } else {
      return FullActivationValidation(t, activationState.hasUserActivatedMembership, activationState.isFirstAdmin);
    }
  };

  const { handleSubmit, ...form } = useForm({
    validationSchema: schemaValidatorGenerator(),
    mode: "onChange",
  });

  /**
   * onSubmit callback for user activation on the platform
   * @param data
   */
  function onSubmit(data: Record<string, any>) {
    const mappedData = mapAgreesDataToBoolean(data);
    try {
      dispatch(ActivateMembership(mappedData, activationId));
      history.push("/login");
      setMessage(NPWSStatus.ACTIVATE_ACCOUNT_SUCCESS);
    } catch (err) {
      setMessage(NPWSStatus.ACTIVATE_ACCOUNT_ERROR);
      setError(true);
    }
  }

  /**
   * Determines whether the user is active
   * @param activationId
   */
  async function isActive(activationId: string) {
    showSpinner(true);
    try {
      const {
        data: {
          hasUserActivatedMembership,
          isFullRegistration,
          email,
          phoneNumber,
          isAdmin,
          isFirstAdmin,
          confirmedStatute,
          agreeToSellingEmails,
          agreeToSellingPhones,
          agreeToSharingData,
          agreeToMarketingEmails,
          agreeToMarketingPhones,
        },
      }: any = await dispatch(isActivatedMembership(activationId));

      setActivationState({
        hasUserActivatedMembership,
        isFullRegistration,
        email,
        phoneNumber,
        isAdmin,
        isFirstAdmin,
        confirmedStatute,
        agreeToSellingEmails: agreeToSellingEmails?.toString(),
        agreeToSellingPhones: agreeToSellingPhones?.toString(),
        agreeToSharingData: agreeToSharingData?.toString(),
        agreeToMarketingEmails: agreeToMarketingEmails?.toString(),
        agreeToMarketingPhones: agreeToMarketingPhones?.toString(),
      });
      dispatch(
        updateActivationUserData({
          ...personal,
          user: {
            ...personal.user,
            email,
            phoneNumber,
            isAdmin,
            confirmedStatute,
            agreeToSellingEmails: agreeToSellingEmails?.toString(),
            agreeToSellingPhones: agreeToSellingPhones?.toString(),
            agreeToSharingData: agreeToSharingData?.toString(),
            agreeToMarketingEmails: agreeToMarketingEmails?.toString(),
            agreeToMarketingPhones: agreeToMarketingPhones?.toString(),
          },
        }),
      );
      dispatch(updateHasUserActivatedMembership(hasUserActivatedMembership));
      if (hasUserActivatedMembership) {
        dispatch(disablePersonalDataForm(true));
      }
      setDisplayForm(true); // Set form visibility after checking all the data
      showSpinner(false);
    } catch (err) {
      history.push("/login");
      setMessage({
        type: "error",
        message: t(err.response.data.message),
        duration: 5000,
      });
      showSpinner(false);
    }
  }

  useEffect(() => {
    const wrapper = async () => {
      await isActive(activationId);
    };
    wrapper();
  }, [activationId]);

  useEffect(() => {
    if (activationState.isFullRegistration !== null && activationState.hasUserActivatedMembership !== null) {
      if (activationState.isFullRegistration) {
        set_activation_type(
          activationState.hasUserActivatedMembership ? ONLY_BUTTON_ACTIVATION : PASSWORD_ONLY_ACTIVATION,
        );
      } else {
        set_activation_type(FULL_ACTIVATION);
      }
    }
  }, [activationState]);

  const routeHelper = () => {
    switch (activation_type) {
      case FULL_ACTIVATION:
        return (
          <FullActivateAccountForm activationState={activationState} form={form} formSubmit={handleSubmit(onSubmit)} />
        );
      case PASSWORD_ONLY_ACTIVATION:
        return (
          <PasswordOnlyActivateAccountForm
            activationState={activationState}
            form={form}
            formSubmit={handleSubmit(onSubmit)}
          />
        );
      case ONLY_BUTTON_ACTIVATION:
        return (
          <OnlyButtonActivateAccountForm
            activationState={activationState}
            form={form}
            formSubmit={handleSubmit(onSubmit)}
          />
        );
      default:
        return null;
    }
  };

  return displayForm ? (
    <div className="row d-flex justify-content-center align-items-center mt-5">
      <div className="col-12 bg-white marine-top-border forms-custom-padding">
        <h2 className="txt-marine-blue mt-0 mb-4 font-size-base font-weight-bold my-2">{`${t("activate")} ${t(
          "account",
        )}`}</h2>
        <hr className="mt-0 mb-2 py-2" />
        <div className="pb-3">{!error && <ActivationSubheaders accountActivationType={activation_type} />}</div>
        <div className="activationPage">{!error && routeHelper()}</div>
      </div>
    </div>
  ) : null;
};

export default ActivateAccountPage;
