import React, { useEffect, useState } from "react";

import { ScrollToTop } from "utils/ScrollUp";
import NavButton from "./NavButton/NavButton";

export const TablePagination = (props: any) => {
  const [visiblePages, setVisiblePages] = useState<(number | string)[]>([1]);
  const { pages: allPages, page: activePage, maxPages = 1000 } = props;
  /**
   * Create pagination with given params
   * @param c // current page
   * @param m // last page to be visible
   */
  function pagination(c: number, m: number) {
    var current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 2,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || i === last - 1 || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  } // thanks to: @kottenator

  useEffect(() => {
    setVisiblePages(pagination(activePage, allPages >= maxPages ? maxPages : allPages));
  }, [props.page, props.pages]);

  const changePage = (page: number) => {
    const aPage = props.page + 1;
    if (page === aPage) {
      return;
    }
    props.onPageChange(page - 1);
    ScrollToTop();
  };

  return (
    <div className={["Table__pagination", props.wrapperClass].join(" ")}>
      <div className="Table__visiblePagesWrapper">
        <div className="d-flex justify-content-center">
          <NavButton onClick={() => changePage(1)} disabled={activePage === 0} arrow label={"|<"} />
          <NavButton onClick={() => changePage(activePage)} disabled={activePage === 0} arrow label={"<"} />
          {visiblePages.map((pageSymbol, index) => {
            const whereToGo = typeof pageSymbol === "number" ? pageSymbol : activePage;
            const dots = typeof pageSymbol === "string" ? "..." : pageSymbol;
            return (
              <NavButton
                onClick={() => changePage(whereToGo)}
                active={activePage + 1 === whereToGo}
                label={`${dots}`}
                disabled={typeof pageSymbol === "string"}
                key={index}
              />
            );
          })}
          <NavButton
            onClick={() => changePage(activePage + 2)}
            disabled={allPages === 1 || allPages === activePage + 1}
            arrow
            label={">"}
          />
          <NavButton
            onClick={() => changePage(allPages)}
            disabled={allPages === 1 || activePage + 1 === allPages}
            arrow
            label={">|"}
          />
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
