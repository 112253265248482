import { EditorState } from "draft-js";
import React, { MutableRefObject } from "react";
import { Editor } from "react-draft-wysiwyg";
import CmsService from "services/CmsService";
import { CMS_BLOCK_TYPE, CMS_COLOR_PICKER, CMS_PROJECT_FONTS } from "utils/constants";

interface EditorType {
  ref: MutableRefObject<null>;
  name: string;
  labelText?: string;
  editorState: EditorState | undefined;
  onEditorStateChange: (editState: EditorState, option: object) => void;
  pageSlug?: string;
}

const EditorInput: React.FC<EditorType> = ({
  name = "",
  ref,
  labelText = "",
  editorState,
  onEditorStateChange,
  pageSlug = "",
}) => {
  //Editor need this to give us a possibility to add our own styles
  //is empty because we don't want add inline styles, but we want add css clasess
  const customStyleMap = {
    LINKBUTTON: {},
  };

  //definition of options to render editor state to HTML,
  //more settings: https://www.npmjs.com/package/draft-js-export-html
  const options = {
    inlineStyles: {
      LINKBUTTON: {
        attributes: { class: "btn primary" },
      },
    },
  };

  /**
   * Callback funtion to when an image is uploaded
   * @param file
   * @returns
   */
  function uploadImageCallBack(file: any) {
    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    return new Promise((resolve, reject) => {
      CmsService.saveCmsImage(pageSlug, imageObject.file)
        .then((res) => resolve({ data: { link: res.data } }))
        .catch((err) => console.log(err));
    });
  }

  const toolbarOptions = {
    options: ["inline", "blockType", "fontSize", "textAlign", "colorPicker", "link", "image", "remove"],
    //options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
    inline: {
      options: ["bold", "italic", "underline"],
    },
    image: {
      uploadCallback: uploadImageCallBack,
      urlEnabled: false,
      alt: { present: true, mandatory: false },
    },
    fontFamily: {
      options: [...CMS_PROJECT_FONTS],
    },
    colorPicker: {
      colors: [...CMS_COLOR_PICKER],
    },
    blockType: {
      inDropdown: true,
      options: [...CMS_BLOCK_TYPE],
    },
  };

  return editorState ? (
    <div className="form-group col-md-12">
      <label htmlFor={name}>{labelText}</label>
      <div className={"d-flex "}>
        <Editor
          ref={ref}
          wrapperClassName="w-100"
          editorState={editorState}
          onEditorStateChange={(state) => onEditorStateChange(state, options)}
          // TO DO, need to find way to dispaly LInkButton with other styles from draft-js-wysiwyg
          //   toolbarCustomButtons={[<LinkButton editorState={editorState} onChange={onEditorStateChange} />]}
          customStyleMap={customStyleMap}
          //toolbar={toolbarOptions}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default EditorInput;
