import React from "react";
import { useTranslation } from "react-i18next";

import { Option } from "./Multiselect";
import { ValueContainerProps, OptionTypeBase, components } from "react-select";

interface MultiselectValueContainerProps extends ValueContainerProps<OptionTypeBase> {
  selected: Option[];
  selectOptions: Option[];
}

const MultiselectValueContainer: React.FC<MultiselectValueContainerProps> = ({
  children,
  selected,
  selectOptions,
  ...props
}: any) => {
  const { t } = useTranslation();
  /**
   * Gets selected without all option
   * @param values
   * @returns
   */
  function getSelectedWithoutAllOption(values: Option[]) {
    return values.filter((option: Option) => option.value !== "all");
  }
  let choosenOptions = getSelectedWithoutAllOption(selected).length;
  /**
   * Containers message switcher
   * @returns
   */
  function containerMessage() {
    if (choosenOptions && selected.length >= 2) {
      if (choosenOptions === selectOptions.length) {
        return t("repository.multiselect.selectedAll");
      } else {
        return `${t("repository.multiselect.selected")} ${choosenOptions}`;
      }
    } else if (choosenOptions && selected.length >= 1) {
      if (choosenOptions === selectOptions.length) {
        return t("repository.multiselect.selectedAll");
      } else {
        return `${t("repository.multiselect.selected")} ${choosenOptions}`;
      }
    }
  }
  const indexInValue = props.selectProps.value.findIndex((x: any) => x === props.data);
  const valueLength = props.selectProps.value.length;

  // select none is for comapnies without name, so array is empty
  if (selected.length === 0) {
    return <components.MultiValueContainer {...props}>{children}</components.MultiValueContainer>;
  }

  if (selected.length === 1) {
    return <components.MultiValueContainer {...props}>{children}</components.MultiValueContainer>;
  }

  if (selected.length > 0 && indexInValue === valueLength - 2) {
    return <div className="react-select__value">{containerMessage()}</div>;
  }

  if (indexInValue === valueLength - 2) {
    return <div className="react-select__value">{containerMessage()}</div>;
  }

  return null;
};

export default MultiselectValueContainer;
