import React, { FormEvent, useState, Dispatch, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FileInput from "components/Inputs/FileInput";
import { FieldError } from "react-hook-form";
import { isFileSizeValid, isFileFormatValid } from "pages/UploadDataPage/UploadDataValidation";

interface UploadDataForm {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  form: Record<string, any>;
  setUploadFile: Dispatch<any>;
  uploadFileName?: string;
  setUploadFileName: React.Dispatch<React.SetStateAction<string>>;
}

const UploadDataForm: React.FC<UploadDataForm> = ({
  form: { errors, register, setValue },
  onSubmit,
  setUploadFile,
  uploadFileName,
  setUploadFileName,
}) => {
  const { t } = useTranslation();
  const [fileError, setFileError] = useState<string | undefined>(undefined);
  const [fileName, setFileName] = useState(uploadFileName);

  const updateFile = (file: { [key: string]: any }) => {
    register({ name: "file" }, { required: true });
    if (!isFileSizeValid(file.file.size)) {
      setFileError(t("upload.data.form.fileInput.size.error"));
      setValue("file", "", true);
    } else if (!isFileFormatValid(file.file.type)) {
      setFileError(t("upload.data.form.fileInput.format.error"));
      setValue("file", "", true);
    } else {
      setFileError(undefined);
      setValue("file", file.file.name, true);
      setUploadFile(file.file);
    }
  };

  useEffect(() => {
    setFileName(uploadFileName);
  }, [uploadFileName]);

  return (
    <form onSubmit={onSubmit}>
      <FileInput
        value=""
        changeHandler={updateFile}
        setFileName={setUploadFileName}
        fileName={fileName}
        labelText={t("upload.data.form.fileInput")}
        name="file"
        accept=".csv,.xlsx,.xls"
        error={fileError || errors.file}
      />
      <div className="form-group">
        <label className={errors.description ? "text-danger" : ""} htmlFor="description">
          {t("upload.data.form.comment")}
        </label>
        <textarea
          ref={register}
          data-testid="description"
          placeholder={`${t("upload.data.form.comment")}...`}
          name="description"
          className={"form-control " + (errors.description ? "border border-danger " : "")}
          id="description"
        ></textarea>
        {errors.description && <p className="text-right text-danger">{(errors.description! as FieldError)!.message}</p>}
      </div>
      <div className="form-group d-flex justify-content-end">
        <button data-testid="uploadDataButton" className="btn primary" type="submit">
          {t("upload.data.form.send")}
        </button>
      </div>
    </form>
  );
};

export default UploadDataForm;
