export interface SearchParams {
  gtin_number: string;
  name__contains: string;
  brand__contains: string;
  company_name__contains: string;
  company_nip: string;
  gpc_number: string[];
}

export const defaultSearchParams: SearchParams = {
  gtin_number: "",
  name__contains: "",
  brand__contains: "",
  company_name__contains: "",
  company_nip: "",
  gpc_number: [],
};

export interface AdditionalDefaultSearchParams {
  offset: number;
  limit: number;
}

export const additionalDefaultSearchParams: AdditionalDefaultSearchParams = {
  offset: 0,
  limit: 15,
};

export type FullSearchParams = AdditionalDefaultSearchParams & SearchParams;

export interface AutocompleteValues<T> {
  name_suggest__completion: T;
  company_suggest__completion: T;
  brand_suggest__completion: T;
}

export const initAutocompleteValues: AutocompleteValues<string[]> = {
  name_suggest__completion: [],
  company_suggest__completion: [],
  brand_suggest__completion: [],
};

export const initAutocompleteDefValues: AutocompleteValues<string | undefined> = {
  name_suggest__completion: undefined,
  company_suggest__completion: undefined,
  brand_suggest__completion: undefined,
};
