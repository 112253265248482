import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import { ScrollToTop } from "utils/ScrollUp";
import { useLocation } from "react-router-dom";
import { regexps } from "utils/constants";

export type MessageType = "success" | "error" | "info" | "warning";

interface StatusMessageProps {
  type: MessageType;
  message: string;
  onClose: () => void;
  duration?: number;
}

const StatusMessage: React.FC<StatusMessageProps> = ({
  type = "success",
  message = "",
  onClose = () => {},
  duration = 20000,
}) => {
  const location = useLocation();
  const messageLocation = useMemo(() => location.pathname, []);

  useEffect(() => {
    ScrollToTop();
    if (
      location.pathname !== messageLocation &&
      !regexps.RESET_PASSWORD_EXCEPTION.test(messageLocation) &&
      messageLocation !== "/registration"
    ) {
      onClose();
    } else if (location.pathname !== messageLocation && location.pathname !== "/") {
      onClose();
    } else {
      const closeTimeout = setTimeout(() => onClose(), duration);
      return () => {
        clearTimeout(closeTimeout);
      };
    }
  }, [type, message, location]);

  return (
    <div data-testid={`${type}-message`} id={`${type}-message`} className="status-message__container">
      <div className={classNames("status-message", type)}>
        <div className="status-message__message">{message}</div>
        <button onClick={onClose} type="button" className="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  );
};

export default StatusMessage;
