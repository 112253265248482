import GS1APIClient from "clients/GS1APIClient";
import { Membership } from "../interfaces/UserInterface";

/**
 * Sends data to verify
 * @param activeMembership
 * @param file
 * @param description
 * @param membershipId
 * @returns
 */
function sendDataToVerify(activeMembership: Membership, file: any, description: string, membershipId: string) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("description", description);
  return GS1APIClient.privateRequest({
    url: `/repository/${activeMembership.id}/verify_file/${membershipId}/${file.name}/`,
    method: "POST",
    data: formData,
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  });
}

/**
 * Gets user products
 * @param activeMembership
 * @param id
 * @returns
 */
function getUserProducts(activeMembership: Membership, id: string) {
  return GS1APIClient.privateRequest<any>({
    url: `/repository/${activeMembership.id}/get_files/${id}/`,
    method: "GET",
  });
}

/**
 * Gets verification file
 * @param activeMembership
 * @param id
 * @returns
 */
function getVerificationFile(activeMembership: Membership, id: string) {
  return GS1APIClient.privateRequest<any>({
    url: `/repository/${activeMembership.id}/get_file/${id}`,
    method: "GET",
    responseType: "arraybuffer",
  });
}

/**
 * Gets categories
 * @param [limit]
 * @returns
 */
function getCategories(limit: string = "100") {
  return GS1APIClient.privateRequest<any>({
    url: `/products/get_categories/?limit=${limit}`,
    method: "GET",
  });
}

function getProductsCounter() {
  return GS1APIClient.request<any>({
    url: `/products/product_count/`,
    method: "GET",
  });
}

export { sendDataToVerify, getUserProducts, getCategories, getVerificationFile, getProductsCounter };
