import GS1APIClient from "clients/GS1APIClient";
import { createBrowserHistory } from "history";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./App.scss";
import { Layout } from "./components/layout";
import { configureStore } from "./store/ConfigureStore";

const { store, persistor } = configureStore();

const history = createBrowserHistory();

history.listen((location) => {});

const App: React.FC = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  /**
   * Agrees to cookies
   */
  function agreeCookies() {
    GS1APIClient.agreeCookies();
    setCookiesAccepted(true);
  }

  useEffect(() => {
    setCookiesAccepted(GS1APIClient.isCookiesAccepted());
    if (process.env.NODE_ENV === "production") {
      const analyticsScript = document.createElement("script");
      analyticsScript.src = "/analytics.js";
      analyticsScript.type = "text/javascript";
      analyticsScript.async = true;
      analyticsScript.defer = true;
      document.head.appendChild(analyticsScript);
    }

    const cookieBotScript = document.createElement("script");
    cookieBotScript.src = "https://consent.cookiebot.com/uc.js";
    cookieBotScript.type = "text/javascript";
    cookieBotScript.id = "Cookiebot";
    cookieBotScript.setAttribute("data-cbid", "a71b3549-0d53-428c-b3ab-787e3df80087");
    cookieBotScript.setAttribute("data-blockingmode", "auto");
    document.head.appendChild(cookieBotScript);

    const cookieBotDeclarationScript = document.createElement("script");
    cookieBotDeclarationScript.src = "https://consent.cookiebot.com/a71b3549-0d53-428c-b3ab-787e3df80087/cd.js";
    cookieBotDeclarationScript.async = true;
    cookieBotDeclarationScript.type = "text/javascript";
    cookieBotDeclarationScript.id = "CookieDeclaration";
    document.head.appendChild(cookieBotDeclarationScript);

    const youtubeModalScript = document.createElement("script");
    youtubeModalScript.src = "/modal-video/fslightbox.js";
    youtubeModalScript.async = true;
    youtubeModalScript.type = "text/javascript";
    document.body.appendChild(youtubeModalScript);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Layout />
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
