import * as yup from "yup";
import i18next from "i18next";
import { regexps } from "utils/constants";

const generateFullActivationSchema = (
  t: i18next.TFunction,
  hasUserActivatedMembership: boolean | null,
  isFirstAdmin: boolean | null,
) => {
  let passwordValidators = {};
  let userPhoneNumberValidator = {};
  let isCompanyRepresentative = {};
  if (isFirstAdmin) {
    isCompanyRepresentative = {
      isCompanyRepresentative: yup.boolean().oneOf([true], t("form.conditionConfirm")),
    };
  }
  if (!hasUserActivatedMembership) {
    passwordValidators = {
      password: yup
        .string()
        .required(t("form.error.required"))
        .matches(regexps.PASSWORD_REGEX, {
          excludeEmptyString: true,
          message: t("form.login.error.passwordFormat"),
        }),
      passwordRepeat: yup
        .string()
        .required(t("form.error.required"))
        .oneOf([yup.ref("password"), null], t("set.password.diff.passwords"))
        .required(t("form.error.required"))
        .matches(regexps.PASSWORD_REGEX, {
          excludeEmptyString: true,
          message: t("form.login.error.passwordFormat"),
        }),
    };
    userPhoneNumberValidator = {
      phoneNumber: yup
        .string()
        .matches(regexps.PHONE, `${t("registration.form.incorrectFormat")}`)
        .required(t("form.error.required")),
    };
  }
  return yup.object().shape({
    ...passwordValidators,
    ...isCompanyRepresentative,
    user: yup.object().shape({
      ...userPhoneNumberValidator,
      firstName: yup
        .string()
        .required(t("form.error.required"))
        .matches(regexps.NOT_ONLY_SPEC_CHAR_AND_NUMS, `${t("validation.errors.bad.data")}`)
        .max(50, `${t("registration.form.lengthValidationMax")} ${50}`),
      lastName: yup
        .string()
        .required(t("form.error.required"))
        .matches(regexps.NOT_ONLY_SPEC_CHAR_AND_NUMS, `${t("validation.errors.bad.data")}`)
        .max(50, `${t("registration.form.lengthValidationMax")} ${50}`),
      confirmedStatute: yup.boolean().oneOf([true], t("form.conditionConfirm")),
      agreeToSellingEmails: yup.string().required(t("form.error.required")),
      agreeToSellingPhones: yup.string().required(t("form.error.required")),
      agreeToSharingData: yup.string().required(t("form.error.required")),
      agreeToMarketingEmails: yup.string().required(t("form.error.required")),
      agreeToMarketingPhones: yup.string().required(t("form.error.required")),
    }),
  });
};

export default generateFullActivationSchema;
