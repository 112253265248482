import { Markup } from "interweave";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CmsService } from "services";
import uuid from "uuid";

import FileInput from "components/Inputs/FileInput";
import useAuthentication from "hooks/useAuthentication";
import useMessage from "hooks/useMessageStatus";
import { isFileFormatValid, isFileSizeValid } from "pages/UploadDataPage/UploadDataValidation";
import { sendDataToVerify } from "store/thunk/DataThunk";
import { DATA_VERIFICATION_PAGE_ACCESS } from "utils/constants";
import { haveAccess } from "utils/permissions";
import NPWSStatus from "utils/statuses";
import Tooltip from "../../components/Tooltip/Tooltip";
import { CmsDataInterface, CmsImage, CmsInterface } from "../../interfaces/CmsInterface";

const VerifyDataPage: React.FC<CmsInterface> = ({ preview, cmsData }) => {
  const fileAcceptFormats = ".xlsx,.xls";

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setMessage } = useMessage();
  const history = useHistory();

  const [fileInputError, setFileInputError] = useState<string | undefined>(undefined);
  const [fileValue, setFileValue] = useState<any>(undefined);
  const [fileInProcess, setFileInProcess] = useState<boolean>(false);
  const [fileName, setFileName] = useState("");

  const [cmsDataState, setCmsDataState] = useState(cmsData);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const { activeMembership } = useAuthentication();
  let styleButton = {};
  let styleDivWrapper = {};

  useEffect(() => {
    setButtonDisabled(buttonDisabled);
  }, [buttonDisabled]);

  /**
   * Redirects to
   * @param path
   */
  function redirectTo(path: string) {
    history.push(path);
  }

  /**
   * Gets page content
   */
  async function getPageContent() {
    const response = await CmsService.getPageContent("verify-data", activeMembership?.id);
    setCmsDataState(response.data);
    setFileInProcess(response.data.fileInProcess);
  }

  /**
   * Renders images
   * @param images
   * @returns
   */
  function renderImages(images: any) {
    return images.map((image: CmsImage) => (
      <div key={image.id}>
        <img style={{ height: 300 }} src={image.url} />
      </div>
    ));
  }

  /**
   * Renders new image
   * @param images
   * @returns
   */
  function renderNewImage(images: any) {
    if (images) {
      return images.map((image: File) => (
        <div key={uuid()}>
          <img style={{ height: 300 }} src={URL.createObjectURL(image)} />
        </div>
      ));
    }
  }

  function render(cmsData: CmsDataInterface) {
    // this is tricky, because when we run two functions in Carousel element and the
    // function returns anything then component render a empty photo
    let list = cmsData.images && renderImages(cmsData.images);
    if (cmsData.newImages) {
      list.push(...renderNewImage(cmsData.newImages));
    }
    return list;
  }

  /**
   * Determines whether file change on
   * @param file
   */
  function onFileChange(file: { [key: string]: any }) {
    if (!isFileSizeValid(file.file.size)) {
      setFileInputError(t("upload.data.form.fileInput.size.error"));
      setFileValue(undefined);
    } else if (!isFileFormatValid(file.file.type)) {
      setFileInputError(t("upload.data.form.fileInput.format.error"));
      setFileValue(undefined);
    } else {
      setFileInputError(undefined);
      setFileValue(file.file);
    }
  }

  /**
   * Sends data
   */
  async function sendData() {
    try {
      if (activeMembership) {
        setButtonDisabled(true);
        await dispatch(sendDataToVerify(activeMembership, fileValue, "", activeMembership.id));
        setButtonDisabled(false);
        setMessage(NPWSStatus.FILE_UPLOADED);
        setFileValue(undefined);
        setFileName("");
      } else {
        setMessage(NPWSStatus.ONLY_FOR_ACTIVE_ACCOUNTS_ERROR);
      }
    } catch (err) {
      setButtonDisabled(false);
      if (err.response.status === 401) {
        setMessage(NPWSStatus.NO_LIMIT_FOR_VERIFY);
      } else if (err.response.status === 403 && err.response.data.error === "processing") {
        setMessage(NPWSStatus.FILE_ALREADY_PROCESSING);
        setFileInProcess(true);
      } else if (fileInputError) {
        setMessage({ type: "error", message: fileInputError });
      } else {
        setMessage(NPWSStatus.GENERIC_ERROR);
      }
    }
  }

  useEffect(() => {
    setCmsDataState(cmsData);
  }, [cmsData]);

  useEffect(() => {
    if (activeMembership) {
      getPageContent();
      setFileValue(undefined);
      setFileName("");
    } else {
      getPageContent();
    }
  }, [activeMembership]);

  useEffect(() => {
    setFileInProcess(fileInProcess);
    styleButton = { pointerEvents: "none" };
    styleDivWrapper = {
      cursor: "not-allowed",
    };
    setButtonDisabled(true);
  }, [fileInProcess]);

  useEffect(() => {
    setButtonDisabled(!fileValue);
  }, [fileValue]);

  let button: any = (
    <div style={styleDivWrapper}>
      <button
        onClick={sendData}
        disabled={buttonDisabled}
        type="button"
        style={styleButton}
        className="btn primary mt-3 px-5 w-100">
        {t("verify.data.send.to")}
      </button>
    </div>
  );
  let fileInput: any = (
    <div style={styleDivWrapper}>
      <FileInput
        changeHandler={onFileChange}
        disabled={fileInProcess}
        fileName={fileName}
        setFileName={setFileName}
        accept={fileAcceptFormats}
        value=""
        error={fileInputError as any} // Can't be fixed for now
        labelText={t("chooseFile")}
        labelStyle={styleDivWrapper}
      />
    </div>
  );
  if (fileInProcess) {
    button = <Tooltip tooltipText={t("status.fileAlreadyInProcessing")}>{button}</Tooltip>;
    fileInput = <Tooltip tooltipText={t("status.fileAlreadyInProcessing")}>{fileInput}</Tooltip>;
  }

  return (
    <>
      <div className="position-relative page-banner">
        <div className="w-100 position-absolute img-hover">
          <div className="container">
            <h1 className="first">Zweryfikuj swoją bazę produktową</h1>
          </div>
        </div>
        <div className="position-relative">
          <img className="w-100" src="assets/v3/banners/verify.png"></img>
          <div className="overlay"></div>
        </div>
      </div>

      <div className="verify-data-page mt-n4">
        <div className="col-12 cms ck-content">
          <div className="row d-flex justify-content-between mb-5">
            <div className="container">
              <div className="row">
                <div className="cms-content">
                  {cmsDataState ? <Markup content={cmsDataState && cmsDataState.content} /> : <></>}
                </div>
              </div>
            </div>
            <div className="verification">
              <h2 className="mx-auto second">{t("verify.data.verifyYourData")}</h2>
              <div className="dash mx-auto my-4"></div>
            </div>
          </div>
          {activeMembership && haveAccess(activeMembership, DATA_VERIFICATION_PAGE_ACCESS) ? (
            <div className="row d-flex justify-content-between mt-4">
              <div className="col-6">
                <div className="file-container bg-white">
                  {fileInput}
                  <p>
                    {t("verify.data.accept.files.format")} {fileAcceptFormats.split(".").join(" ")}
                  </p>
                  {button}
                </div>
              </div>
              <div className="col-6 d-flex flex-column">
                <p>
                  <strong>{t("verify.verifyFile.technicalSpecification")}</strong>
                  <ol className="ml-n4">
                    <li>{t("verify.verifyFile.tabelaricFormat")}</li>
                    <li>{t("verify.verifyFile.GTINRecommanded")}</li>
                    <li>{t("verify.verifyFile.GTINOrder")}</li>
                    <ol type="a">
                      <li>{t("verify.verifyFile.XlsMaxRecords")}</li>
                      <li>{t("verify.verifyFile.XlsxMaxRecords")}</li>
                    </ol>
                    <li>{t("verify.verifyFile.maxSize")}</li>
                    <li>{t("verify.verifyFile.warning")}</li>
                  </ol>
                </p>
                <a
                  className="font-size-medium link mb-2 mt-3"
                  href="/media/baza_do_weryfikacji_-_plik_wynikowy.xlsx"
                  download>
                  <img className="mr-1" width="16" height="16" src="/icons/download.png" />
                  {t("verify.data.button.sample.toVerify")}
                </a>
                <a className="font-size-medium link" href="/media/baza_do_weryfikacji_-_plik_wsadowy.xlsx" download>
                  <img className="mr-1" width="16" height="16" src="/icons/download.png" />
                  {t("verify.data.button.sample.data")}
                </a>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center mb-3">
              <p>{t("verify.data.cta")}</p>
              <button
                onClick={() => {
                  redirectTo("/registration");
                }}
                type="button"
                className="btn primary mt-3 px-5 w-50">
                {t("homepage.cta.register")}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VerifyDataPage;
