import * as yup from "yup";
import i18next from "i18next";
import { EmailValidator } from "utils";
import { regexps } from "utils/constants";

const generatePersonalDataSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    user: generateUserSchema(t),
    ...generateConditionsSchema(t),
  });

export const generateUserSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(t("form.error.required"))
      .matches(regexps.NOT_ONLY_SPEC_CHAR_AND_NUMS, `${t("validation.errors.bad.data")}`)
      .max(50, `${t("registration.form.lengthValidationMax")} ${50}`),
    lastName: yup
      .string()
      .required(t("form.error.required"))
      .matches(regexps.NOT_ONLY_SPEC_CHAR_AND_NUMS, `${t("validation.errors.bad.data")}`)
      .max(50, `${t("registration.form.lengthValidationMax")} ${50}`),
    email: yup
      .string()
      .required(t("form.error.required"))
      .test("Valid email", t("form.error.email.format"), (value) => EmailValidator(value)),
    phoneNumber: yup
      .string()
      .matches(regexps.PHONE, `${t("registration.form.incorrectFormat")}`)
      .required(t("form.error.required")),
    confirmedStatute: yup.boolean().oneOf([true], t("registration.form.personal.conditionConfirm")),
    agreeToSellingEmails: yup.string().required(t("form.error.required")),
    agreeToSellingPhones: yup.string().required(t("form.error.required")),
    agreeToSharingData: yup.string().required(t("form.error.required")),
    agreeToMarketingEmails: yup.string().required(t("form.error.required")),
    agreeToMarketingPhones: yup.string().required(t("form.error.required")),
  });

const generateConditionsSchema = (t: i18next.TFunction) => ({
  isCompanyRepresentative: yup.boolean().oneOf([true], t("registration.form.personal.conditionConfirm")),
});

export const generateEmailSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    user: yup.object().shape({
      email: yup
        .string()
        .test("Valid email", t("form.error.email.format"), (value) => EmailValidator(value))
        .required(t("form.error.required")),
      confirmedStatute: yup.boolean().oneOf([true], t("registration.form.personal.conditionConfirm")),
      agreeToSellingEmails: yup.string().required(t("form.error.required")),
      agreeToSellingPhones: yup.string().required(t("form.error.required")),
      agreeToSharingData: yup.string().required(t("form.error.required")),
      agreeToMarketingEmails: yup.string().required(t("form.error.required")),
      agreeToMarketingPhones: yup.string().required(t("form.error.required")),
    }),
    ...generateConditionsSchema(t),
  });

export default generatePersonalDataSchema;
