import * as yup from "yup";
import i18next from "i18next";
import { regexps } from "utils/constants";

const generatePasswordOnlyValidationSchema = (t: i18next.TFunction) => {
  return yup.object().shape({
    password: yup
      .string()
      .required(t("form.error.required"))
      .matches(regexps.PASSWORD_REGEX, {
        excludeEmptyString: true,
        message: t("form.login.error.passwordFormat"),
      }),
    passwordRepeat: yup
      .string()
      .required(t("form.error.required"))
      .oneOf([yup.ref("password"), null], t("set.password.diff.passwords"))
      .required(t("form.error.required"))
      .matches(regexps.PASSWORD_REGEX, {
        excludeEmptyString: true,
        message: t("form.login.error.passwordFormat"),
      }),
  });
};

export default generatePasswordOnlyValidationSchema;
