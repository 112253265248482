import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import TagManager from 'react-gtm-module'

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./i18n/i18n";

if (process.env.NODE_ENV === "production") {
  const tagManagerArgs = {
    gtmId: 'GTM-TPS7HLT'
  }
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY || ""}
    scriptProps={{
      async: false,
      defer: false,
      appendTo: "head",
      nonce: undefined,
    }}
  >
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
