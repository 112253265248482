import React from "react";
import { useTranslation } from "react-i18next";

import { Link, useHistory } from "react-router-dom";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  /**
   * Redirects to
   * @param path
   */
  function redirectTo(path: string) {
    history.push(path);
  }

  return (
    <div className="footer__background">
      <footer className="container footer">
        <div className="row">
          <div className="footer__links">
            <Link className="link" to="/terms">
              {t("terms")}
            </Link>
            <a className="link" href="https://gs1pl.org/polityka-prywatnosci/">
              {t("privacyPolicy")}
            </a>
            <a
              className="link"
              href="https://gs1pl.org/app/uploads/2022/02/Polityka_cookies_serwis-eprodukty.gs1_.pl_.pdf">
              {t("cookiesPolicy")}
            </a>
            <Link className="link" to="/contact">
              {t("contact")}
            </Link>

            <Link className="link" to="/about_us">
              {t("navbar.aboutUs")}
            </Link>
          </div>
          <div className="footer__gs1">
            <div className="footer__contact">
              <div className="pt-2">
                <a href="https://www.linkedin.com/company/gs1-polska" className="mr-3" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="34.166" height="34.166" viewBox="0 0 35.5 35.5">
                    <defs>
                      <clipPath id="a">
                        <path
                          d="M204.083,10.166A17.083,17.083,0,0,0,221.166-6.917,17.083,17.083,0,0,0,204.083-24,17.083,17.083,0,0,0,187-6.917,17.083,17.083,0,0,0,204.083,10.166Z"
                          transform="translate(-187 24)"
                          fill="#002c6c"
                          clip-rule="evenodd"></path>
                      </clipPath>
                      <clipPath id="d">
                        <path
                          d="M211.876-7.654V-.678h-4.128V-7.227c0-1.708-.569-2.847-2.135-2.847a2.245,2.245,0,0,0-2.135,1.424,2.11,2.11,0,0,0-.142,1V-.82h-4.128V-13.063h4.128v1.708h0a4.119,4.119,0,0,1,3.7-1.993c2.847.142,4.84,1.851,4.84,5.694ZM195.078-18.9a2.059,2.059,0,0,0-2.278,2.135,2.134,2.134,0,0,0,2.278,2.135h0a2.134,2.134,0,0,0,2.278-2.135,2.134,2.134,0,0,0-2.278-2.135ZM193.085-.678h4.128V-12.921h-4.128Z"
                          transform="translate(-192.8 18.9)"
                          fill="none"></path>
                      </clipPath>
                    </defs>
                    <g clip-path="url(#a)">
                      <path d="M182-29h48.4V19.4H182Z" transform="translate(-189.118 21.882)" fill="#002c6c"></path>
                    </g>
                    <g transform="translate(8.257 7.26)" clip-path="url(#d)">
                      <path
                        d="M187.8-23.9h33.312V8.558H187.8Z"
                        transform="translate(-194.918 16.782)"
                        fill="#f0f1f1"></path>
                    </g>
                  </svg>
                </a>
                <a href="https://www.facebook.com/GS1Polska" className="mr-3" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="34.166" height="34.165" viewBox="0 0 34.166 34.165">
                    <defs>
                      <clipPath id="b">
                        <path
                          d="M296.166-6.917A17.133,17.133,0,0,0,279.083-24,17.133,17.133,0,0,0,262-6.917a17.16,17.16,0,0,0,14.378,16.941V-1.934h-4.271V-6.917h4.271v-3.7c0-4.271,2.562-6.691,6.406-6.691a31.581,31.581,0,0,1,3.844.285v4.271h-2.135a2.508,2.508,0,0,0-2.847,2.7v3.132h4.7l-.712,4.983h-3.986V10.024A17.155,17.155,0,0,0,296.166-6.917Z"
                          transform="translate(-262 24)"
                          fill="#002c6c"
                          clip-rule="evenodd"></path>
                      </clipPath>
                      <clipPath id="e">
                        <path
                          d="M282.724-3.883l.712-4.983h-4.7V-12.14a2.57,2.57,0,0,1,2.847-2.7h2.135v-4.271a31.581,31.581,0,0,0-3.844-.285c-3.844,0-6.406,2.42-6.406,6.691v3.844H269.2v4.983h4.271V8.075a14.638,14.638,0,0,0,2.562.142,17.228,17.228,0,0,0,2.7-.142V-3.883Z"
                          transform="translate(-269.2 19.4)"
                          fill="none"></path>
                      </clipPath>
                    </defs>
                    <g clip-path="url(#b)">
                      <path d="M257-29h48.4V19.259H257Z" transform="translate(-264.118 21.882)" fill="#002c6c"></path>
                    </g>
                    <g transform="translate(10.25 6.548)" clip-path="url(#e)">
                      <path
                        d="M264.2-24.4h28.756V17.453H264.2Z"
                        transform="translate(-271.318 17.282)"
                        fill="#fff"></path>
                    </g>
                  </svg>
                </a>
                <a href="https://www.youtube.com/channel/UCPGTAFOoqAGAz9QEcdDS9PQ" className="mr-3" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="34.166" height="34.166" viewBox="0 0 34.166 34.166">
                    <defs>
                      <clipPath id="c">
                        <path
                          d="M129.083-24A17.133,17.133,0,0,1,146.166-6.917a17.133,17.133,0,0,1-17.083,17.083A17.133,17.133,0,0,1,112-6.917,17.133,17.133,0,0,1,129.083-24Zm.274,9.681h-.548a61.773,61.773,0,0,0-8.125.426,2.688,2.688,0,0,0-1.851,1.851,27.748,27.748,0,0,0-.426,5v.257a27.748,27.748,0,0,0,.426,5A2.986,2.986,0,0,0,120.684.059a61.772,61.772,0,0,0,8.125.426h.548a61.772,61.772,0,0,0,8.125-.426,2.986,2.986,0,0,0,1.851-1.851,27.749,27.749,0,0,0,.426-5V-6.9h0v-.143a27.749,27.749,0,0,0-.426-5,2.986,2.986,0,0,0-1.851-1.851A61.773,61.773,0,0,0,129.357-14.319Zm-2.41,4.27L132.5-6.917l-5.552,3.132Z"
                          transform="translate(-112 24)"
                          fill="#002c6c"
                          clip-rule="evenodd"></path>
                      </clipPath>
                    </defs>
                    <g clip-path="url(#c)">
                      <path d="M107-29h48.4V19.4H107Z" transform="translate(-114.118 21.882)" fill="#002c6c"></path>
                    </g>
                  </svg>
                </a>
                <a href="https://www.instagram.com/gs1_polska/" className="mr-3" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="34.166" height="34.166" viewBox="0 0 34.17 34.17">
                    <defs>
                      <clipPath id="g" transform="translate(0 0)">
                        <path
                          d="M17.083,20.388a3.314,3.314,0,0,1-3.321-3.307h0a3.314,3.314,0,0,1,3.32-3.308h0a3.308,3.308,0,1,1,0,6.616m0-8.4a5.106,5.106,0,0,0-5.116,5.1v0a5.106,5.106,0,0,0,5.112,5.1h0a5.106,5.106,0,0,0,5.116-5.1v0a5.106,5.106,0,0,0-5.112-5.1h0"
                          fill="none"></path>
                      </clipPath>
                      <clipPath id="h" transform="translate(0 0)">
                        <path
                          d="M23.6,11.786a1.193,1.193,0,0,1-1.2,1.191H22.4a1.193,1.193,0,0,1-1.2-1.186v-.005a1.193,1.193,0,0,1,1.2-1.191h0a1.193,1.193,0,0,1,1.2,1.186v0"
                          fill="none"></path>
                      </clipPath>
                      <clipPath id="i" transform="translate(0 0)">
                        <rect x="7.119" y="7.119" width="19.933" height="19.933" fill="none"></rect>
                      </clipPath>
                      <clipPath id="j" transform="translate(0 0)">
                        <path
                          d="M17.082,7.158c-2.706,0-3.045.011-4.108.06a7.32,7.32,0,0,0-2.418.461,5.084,5.084,0,0,0-2.914,2.9,7.27,7.27,0,0,0-.463,2.409c-.049,1.058-.06,1.4-.06,4.092s.011,3.033.06,4.092a7.27,7.27,0,0,0,.463,2.409,5.068,5.068,0,0,0,2.914,2.9,7.32,7.32,0,0,0,2.418.461c1.063.048,1.4.06,4.108.06s3.045-.011,4.108-.06a7.32,7.32,0,0,0,2.418-.461,5.07,5.07,0,0,0,2.911-2.9,7.27,7.27,0,0,0,.463-2.409c.048-1.058.06-1.4.06-4.092s-.012-3.033-.06-4.092a7.27,7.27,0,0,0-.463-2.409,5.084,5.084,0,0,0-2.914-2.9,7.32,7.32,0,0,0-2.418-.461c-1.063-.048-1.4-.06-4.108-.06m0,1.788c2.66,0,2.975.01,4.026.058a5.539,5.539,0,0,1,1.85.342,3.289,3.289,0,0,1,1.891,1.883,5.5,5.5,0,0,1,.343,1.843c.048,1.046.058,1.36.058,4.01s-.01,2.964-.058,4.01a5.479,5.479,0,0,1-.34,1.84,3.289,3.289,0,0,1-1.891,1.883,5.539,5.539,0,0,1-1.85.342c-1.05.048-1.366.058-4.026.058s-2.976-.01-4.026-.058a5.539,5.539,0,0,1-1.85-.342A3.267,3.267,0,0,1,9.319,22.93a5.5,5.5,0,0,1-.343-1.843c-.048-1.046-.058-1.36-.058-4.01s.01-2.964.058-4.01a5.465,5.465,0,0,1,.343-1.837A3.289,3.289,0,0,1,11.21,9.347a5.539,5.539,0,0,1,1.85-.342c1.051-.048,1.366-.058,4.026-.058"
                          fill="none"></path>
                      </clipPath>
                      <clipPath id="k" transform="translate(0 0)">
                        <rect x="7.119" y="7.119" width="19.932" height="19.933" fill="none"></rect>
                      </clipPath>
                    </defs>
                    <path
                      d="M17.085,0h0A17.085,17.085,0,1,0,34.17,17.085,17.085,17.085,0,0,0,17.085,0Z"
                      transform="translate(0 0)"
                      fill="#002c6c"></path>
                    <path
                      d="M17.085,34.17A17.085,17.085,0,0,0,34.17,17.085h0A17.085,17.085,0,0,0,17.085,0h0a17.085,17.085,0,0,0,0,34.17Z"
                      transform="translate(0 0)"
                      fill="none"></path>
                    <g clip-path="url(#g)">
                      <path d="M4.843,4.867h24.47V29.3H4.843Z" transform="translate(0 0)" fill="#fff"></path>
                    </g>
                    <g clip-path="url(#h)">
                      <path d="M14.086,3.477H30.715V20.1H14.086Z" transform="translate(0 0)" fill="#fff"></path>
                    </g>
                    <g clip-path="url(#i)">
                      <g clip-path="url(#j)">
                        <g clip-path="url(#k)">
                          <path d="M0,.04H34.164V34.125H0Z" transform="translate(0 0)" fill="#fff"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                <a href="https://open.spotify.com/show/6Jyeq5djah4Cqq7Zwz67qt" className="mr-3" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="34.166" height="34.166" viewBox="0 0 496 512">
                    <path
                      d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm100.7 364.9c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4zm26.9-65.6c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm31-76.2c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3z"
                      fill="#002c6c"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
