import classNames from "classnames";
import React from "react";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";

interface InputType {
  name: string;
  error?: FieldError | FieldError[] | NestDataObject<any> | NestDataObject<any>[] | undefined;
  reference?: (ref: Element | null) => void;
  type?: string;
  labelText?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  flexReverse?: boolean;
  autocomplete?: string;
  handleChange?: (e: any) => void;
  tabIndex?: number;
  inputIcon?: any;
  errorTestId?: string;
  customLabelClassName?: string;
}

const Input: React.FC<InputType> = ({
  name = "",
  error = undefined,
  reference = (ref: Element | null) => {},
  type = "text",
  labelText = "",
  placeholder = "",
  value = "",
  children,
  disabled = false,
  flexReverse = false,
  autocomplete,
  handleChange = (e) => {},
  tabIndex,
  inputIcon,
  errorTestId = "",
  customLabelClassName,
}) => {
  return (
    <div className="form-group mb-0">
      {labelText && (
        <label
          className={classNames(
            "font-weight-bold font-size-medium",
            { "text-danger": error },
            customLabelClassName ? customLabelClassName : null,
          )}
          htmlFor={name}
        >
          {labelText}
        </label>
      )}
      <div
        className={classNames(
          "d-flex position-relative improved-input",
          { "flex-row-reverse": flexReverse },
          { error: error },
        )}
      >
        <input
          type={type}
          name={name}
          tabIndex={tabIndex}
          defaultValue={value}
          role="input"
          className={classNames("form-control rounded-0", {
            "border border-danger": error,
            "mr-2": children && !flexReverse,
          })}
          id={name}
          autoComplete={autocomplete}
          data-testid={name}
          onChange={handleChange}
          placeholder={placeholder || labelText}
          ref={reference}
          disabled={disabled}
        />
        <div className="input-icon position-absolute h-100 d-flex justify-content-end align-items-center pr-2">
          {inputIcon}
        </div>
        {children}
      </div>
      {error && (
        <p data-testid={errorTestId} className={classNames("input-error error", { "mb-0": error })}>
          {(error! as FieldError)!.message}
        </p>
      )}
    </div>
  );
};

export default Input;
