import React from "react";
import Input from "components/Inputs/Input";
import { PermissionGroup, Membership } from "interfaces/UserInterface";
import { useTranslation } from "react-i18next";
import MaskedInput from "components/Inputs/MaskedInput";
import PersonalDataLegals from "components/Register/PersonalData/PersonalDataLegals";
import { useForm, OnSubmit } from "react-hook-form";
import generatePersonalDataSchema from "components/Register/PersonalData/PersonalDataValidation";
import Agreements from "components/Agreements/Agreements";
import Checkbox from "components/Inputs/Checkbox";

interface UserDataFormProps {
  formData: Membership;
  onSubmit: OnSubmit<{ user: MembershipUserForm }>;
}

export interface MembershipUserForm {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateJoined: string;
  lastLogin: string | null;
  permissionGroup: PermissionGroup;
  confirmedStatute: boolean;
  isCompanyRepresentative: boolean;
  agreeToSellingEmails: string | boolean;
  agreeToSellingPhones: string | boolean;
  agreeToMarketingEmails: string | boolean;
  agreeToMarketingPhones: string | boolean;
  agreeToSharingData: string | boolean;
  memberships?: Membership[];
}

const UserDataForm: React.FC<UserDataFormProps> = ({ formData, onSubmit }) => {
  const { t } = useTranslation();
  const form = useForm<{ user: MembershipUserForm }>({
    defaultValues: { user: mapUserDataToForm(formData) },
    validationSchema: generatePersonalDataSchema(t),
    mode: "onChange",
  });
  const { handleSubmit, register, errors, triggerValidation, setValue, getValues } = form;

  /**
   * Maps user data to form
   * @param formData
   * @returns user data to form
   */
  function mapUserDataToForm(formData: Membership): MembershipUserForm {
    return {
      isCompanyRepresentative: formData.isCompanyRepresentative,
      id: formData.id,
      permissionGroup: formData.permissionGroup,
      firstName: formData.user.firstName,
      lastName: formData.user.lastName,
      email: formData.user.email,
      phoneNumber: formData.user.phoneNumber,
      dateJoined: formData.user.dateJoined,
      lastLogin: formData.user.lastLogin,
      confirmedStatute: formData.user.confirmedStatute,
      agreeToSellingEmails: formData.user.agreeToSellingEmails.toString(),
      agreeToSellingPhones: formData.user.agreeToSellingPhones.toString(),
      agreeToSharingData: formData.user.agreeToSharingData.toString(),
      agreeToMarketingEmails: formData.user.agreeToMarketingEmails.toString(),
      agreeToMarketingPhones: formData.user.agreeToMarketingPhones.toString(),
    };
  }

  /**
   * Maps form to user
   * @param formData
   * @returns form to user data
   */
  function mapFormToUser(formData: MembershipUserForm) {
    return {
      ...formData,
      confirmedStatute: formData.confirmedStatute.toString() === "true",
      agreeToSellingEmails: formData.agreeToSellingEmails.toString() === "true",
      agreeToSellingPhones: formData.agreeToSellingPhones.toString() === "true",
      agreeToSharingData: formData.agreeToSharingData.toString() === "true",
      agreeToMarketingEmails: formData.agreeToMarketingEmails.toString() === "true",
      agreeToMarketingPhones: formData.agreeToMarketingPhones.toString() === "true",
    };
  }

  /**
   *
   * @param { user }
   */
  function submit({ user }: { user: MembershipUserForm }) {
    const mappedData = mapFormToUser(user);
    onSubmit({ user: mappedData });
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="personal-data">
        <div className="col-12 mb-3">
          <h2>
            {t("registration.form.personal.h2")} {`${formData.user.firstName} ${formData.user.lastName}`}
          </h2>
        </div>
        <div className="col-md-6 mb-3 pr-3">
          <Input
            labelText={t("registration.form.personal.user.email")}
            name="user.email"
            tabIndex={1}
            disabled={true}
            placeholder=" "
            reference={register}
          />
          <Input
            labelText={t("registration.form.personal.user.firstName")}
            name="user.firstName"
            tabIndex={3}
            placeholder=" "
            handleChange={async () => {
              await triggerValidation("user.firstName", true);
            }}
            error={errors.user && errors.user.firstName}
            reference={register}
          />
        </div>
        <div className="col-md-6 mb-3 pl-3">
          <MaskedInput
            labelText={t("registration.form.personal.user.phoneNumber")}
            name="user.phoneNumber"
            error={errors.user && errors.user.phoneNumber}
            defaultValue={formData.user.phoneNumber || "+48"}
            mask="+00 000-000-000"
            formHooks={{ register, setValue }}
            tabIndex={2}
            reference={register}
          />
          <Input
            labelText={t("registration.form.personal.user.lastName")}
            name="user.lastName"
            error={errors.user && errors.user.lastName}
            tabIndex={4}
            placeholder=" "
            handleChange={async () => {
              await triggerValidation("user.lastName", true);
            }}
            reference={register}
          />
        </div>
        <div className="col-12">
          <h3 className="mb-1">{t("registration.form.companyData.userAgreements")}</h3>
          {formData.permissionGroup === "location_admin" && (
            <Checkbox
              name="user.isCompanyRepresentative"
              reference={form.register}
              checked={formData.isCompanyRepresentative}
              tabIndex={6}
              error={form.errors.user?.isCompanyRepresentative}
              labelText={t("registration.form.personal.confirm.isCompanyRepresentative")}
            />
          )}
          <Agreements form={form} isAdmin={false} startingTabIndex={7} />
          <PersonalDataLegals />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          data-testid="saveUserData"
          className={"btn primary margin-1-b float-right px-5"}
          tabIndex={13}
          type="submit"
        >
          {t("save")}
        </button>
      </div>
    </form>
  );
};

export default UserDataForm;
