import _ from "lodash";

import React, { useEffect } from "react";
import { useHistory, Route, Redirect, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { State, ThunkDispatch } from "interfaces/StoreInterface";
import * as AccountThunk from "store/thunk/AccountThunk";

import useMembershipCookies from "hooks/useMembershipCookies";
import { haveAccess } from "../../utils/permissions";
import { ScrollToTop } from "utils/ScrollUp";

interface Props {
  component: React.FC;
  path: string;
  exact: boolean;
  permission: string;
}

function PrivateRouteRenderer({ component: PageComponent, path, permission }: Props) {
  const dispatch = useDispatch<ThunkDispatch>();
  const account = useSelector((state: State) => state.account);
  const location = useLocation();
  const history = useHistory();
  const { user: currentUser, authenticated, activeMembership } = account;
  const { checkMembership } = useMembershipCookies();

  const fetchCurrentUser = async () => {
    if (authenticated && !currentUser) {
      try {
        const user = await dispatch(await AccountThunk.fetchCurrentUser());
        await checkMembership(user);
      } catch (error) {
        if (_.get(error, "response.status") === 401) {
          dispatch(AccountThunk.logout());
        }
      }
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    ScrollToTop("auto");
  }, [PageComponent]);

  if (!authenticated) {
    const redirectTo = {
      pathname: "/login",
      search: location.search, // Preserve query string
      state: { from: location }
    };
    return <Redirect to={redirectTo} />;
  }

  if (!currentUser) {
    // current user is being fetched...
    return <></>;
  }

  if (activeMembership && !haveAccess(activeMembership, permission)) {
    const redirectTo = {
      pathname: "/",
      search: location.search, // Preserve query string
      state: { from: location.pathname + location.search }
    };
    return <Redirect to={redirectTo} />;
  }
  return <PageComponent />;
}

export const PrivateRoute = ({ component, ...rest }: Props) => {
  return <Route {...rest} render={() => <PrivateRouteRenderer component={component} {...rest} />} />;
};

export default PrivateRoute;