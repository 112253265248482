import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import generateUploadDataSchema from "./UploadDataValidation";
import { useForm } from "react-hook-form";
import UploadDataForm from "components/UploadDataForm/UploadDataForm";
import { useDispatch, useSelector } from "react-redux";
import { sendDataToVerify } from "store/thunk/DataThunk";
import { State } from "../../interfaces/StoreInterface";
import { Link } from "react-router-dom";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";

const UploadDataPage: React.FC = () => {
  const { t } = useTranslation();
  const [uploadFile, setUploadFile] = useState();
  const [uploadFileName, setUploadFileName] = useState("");
  const { setMessage } = useMessage();
  const activeMembership = useSelector((state: State) => state.account.activeMembership);
  const dispatch = useDispatch();

  const { handleSubmit, ...form } = useForm({
    validationSchema: generateUploadDataSchema(t),
    defaultValues: {
      file: "",
      description: "",
    },
  });

  /**
   * Determines whether submit on
   * @param data
   */
  async function onSubmit(data: Record<string, any>) {
    try {
      if (activeMembership) {
        await dispatch(sendDataToVerify(activeMembership, uploadFile, data.description, activeMembership.id));
        setMessage(NPWSStatus.FILE_UPLOADED);
        setUploadFileName("");
        setUploadFile(undefined);
      } else {
        setMessage(NPWSStatus.ONLY_FOR_ACTIVE_ACCOUNTS_ERROR);
      }
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }

  return (
    <div className="row d-flex login-page mt-5">
      <div className="col">
        <h2>{t("upload.data.h2")}</h2>
        <p>{t("upload.data.dearUser")}</p>
        <p>
          {t("upload.data.description")}{" "}
          <Link className="link" to={""}>
            {t("upload.data.here")}
          </Link>
        </p>
        <UploadDataForm
          form={form}
          setUploadFile={setUploadFile}
          uploadFileName={uploadFileName}
          setUploadFileName={setUploadFileName}
          onSubmit={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};

export default UploadDataPage;
