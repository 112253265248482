import React, { useState } from "react";
import { WithWizard, Wizard } from "react-albus";
import { CompanyData, RegistrationUserData, resetForm } from "store/actions/RegistrationActions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import * as RegistrationThunk from "store/thunk/RegistrationThunk";
import { postRegistrationFormAttachment } from "store/thunk/RegistrationThunk";
import { useHistory } from "react-router-dom";
import { companyProfileOptions, registrationPurposeOptions } from "../CompanyData/CompanyData";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";
import { getValidUrl } from "utils";

interface PersonalDataProps {
  summaryData: {
    company: CompanyData;
    personal: RegistrationUserData;
  };
  prevStep: () => void;
}

const SummaryData: React.FC<PersonalDataProps> = ({ summaryData: { company, personal }, prevStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setMessage } = useMessage();
  const history = useHistory();
  const { disablePersonalForm } = useSelector((state: any) => state.registrationForm);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  /**
   * Posts registration form data to the server
   */
  async function postRegistrationFormData() {
    setButtonDisabled(true);

    const { file, user, ...rest } = personal;
    try {
      const res: any = await dispatch(RegistrationThunk.postRegistrationFormData({ company, user, ...rest }));
      if (file) {
        await dispatch(postRegistrationFormAttachment(file, res.data.membershipId));
      }
      setMessage(NPWSStatus.COMPANY_CREATED);
      dispatch(resetForm());
      history.push("/");
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    } finally {
      setTimeout(() => setButtonDisabled(false), 3000); // Re-enable the button after 3 seconds
    }
  }

  /**
   * Gets company profile
   * @param choosenProfile
   * @returns
   */
  function getCompanyProfile(choosenProfile: string) {
    const profile = companyProfileOptions.find(
      (profile: { value: string; option: string }) => profile.value === choosenProfile,
    );
    return profile?.option;
  }

  type RegistrationPurpose = {
    value: string;
    option: string;
  };

  const generateRegistrationPurposeString = (
    options: RegistrationPurpose[],
    selectedOptions: { [key: string]: any },
  ): string => {
    return options
      .filter((option) => selectedOptions[`registrationPurpose_${option.value}`])
      .map((option) => option.option)
      .join(", ");
  };

  /**
   * Gets user full name
   * @returns
   */
  function getUserFullName() {
    if (!personal?.user?.firstName) return "-";
    if (disablePersonalForm) {
      return `${personal?.user?.firstName?.replace(/./g, "*")}  ${personal?.user?.lastName?.replace(/./g, "*")}`;
    } else {
      return `${personal?.user?.firstName} ${personal?.user?.lastName}`;
    }
  }

  /**
   * Gets user phone number
   * @returns
   */
  function getUserPhoneNumber() {
    if (disablePersonalForm) {
      return `${personal?.user?.phoneNumber?.replace(/./g, "*")}`;
    }
    return `${personal.user.phoneNumber}` || "-";
  }

  return (
    <Wizard>
      <WithWizard
        render={() => (
          <div className="summary">
            <div className="col-12 mb-3">
              <h2>{t("form.steps.summary")}</h2>
            </div>
            <div className="summary__page">
              <div className="row w-100 d-flex justify-content-between">
                <div className="col-6 mb-4">
                  <h3 className="pb-2">{t("form.steps.companyData")}</h3>
                  <p className="summary__detail">
                    <span>{t("registration.form.companyData.regon")}:</span>
                    {company.regon || "-"}
                  </p>
                  <p className="summary__detail">
                    <span>{t("registration.form.companyData.nip")}:</span>
                    {company.nip || "-"}
                  </p>
                  <p className="summary__detail">
                    <span>{t("registration.form.companyData.name")}:</span>
                    {company.name || "-"}
                  </p>
                  <p className="summary__detail">
                    <span>{t("registration.form.companyData.webPage")}:</span>
                    {company.webPage ? (
                      <a href={getValidUrl(company.webPage)} target="_blank" className="link">
                        {company.webPage}
                      </a>
                    ) : (
                      "-"
                    )}
                  </p>
                  <p className="summary__detail">
                    <span>{t("registration.form.companyData.companyProfile")}:</span>
                    {company.companyProfile ? getCompanyProfile(company.companyProfile) : "-"}
                  </p>
                  <p className="summary__detail">
                    <span>Cel rejestracji:</span>
                    {company ? generateRegistrationPurposeString(registrationPurposeOptions, company) : "-"}
                  </p>
                </div>
                <div className="col-6 mb-4">
                  <h3 className="pb-2">{t("address")}</h3>
                  <p className="summary__detail">
                    <span>{t("registration.form.companyData.street")}:</span>
                    {company.street || "-"}
                  </p>
                  <p className="summary__detail">
                    <span>{t("registration.form.companyData.postalCode")}:</span>
                    {company.postalCode || "-"}
                  </p>
                  <p className="summary__detail">
                    <span>{t("registration.form.companyData.city")}:</span>
                    {company.city || "-"}
                  </p>

                  <p className="summary__detail">
                    <span>{t("registration.form.companyData.district")}:</span>
                    {company.district || "-"}
                  </p>
                  <p className="summary__detail">
                    <span>{t("registration.form.companyData.country")}:</span>
                    {company.country || "-"}
                  </p>
                </div>
                <div className="col-12 mb-4">
                  <h3 className="pb-2">{t("user")}</h3>
                  <p className="summary__detail">
                    <span>{t("registration.form.personal.user.email")}:</span>
                    {personal.user.email || "-"}
                  </p>
                  <p className="summary__detail">
                    <span>
                      {t("registration.form.personal.user.firstName")} {t("and")}{" "}
                      {t("registration.form.personal.user.lastName").toLowerCase()}:
                    </span>
                    {getUserFullName()}
                  </p>
                  <p className="summary__detail">
                    <span>{t("registration.form.personal.user.phoneNumber")}:</span>
                    {getUserPhoneNumber()}
                  </p>
                </div>
                <div className="col-12 mb-4">
                  <h3 className="pb-2">{t("registration.userAgreements")}</h3>
                  <div className="row summary__detail">
                    <div className="col-10">
                      <span>{t("form.steps.agree.regulations")}:</span>
                    </div>
                    <div className="col-2">{personal.user.confirmedStatute ? t("yes") : t("no") || t("no")}</div>
                  </div>
                  <hr />
                  <div className="row summary__detail">
                    <div className="col-10">
                      <span>{t("form.steps.agree.representative")}:</span>
                    </div>
                    <div className="col-2">{personal.isCompanyRepresentative ? t("yes") : t("no") || t("no")}</div>
                  </div>
                  <hr />
                  <div className="row summary__detail">
                    <div className="col-10">
                      <span>{t("form.steps.agree.agreeToSellingEmails")}:</span>
                    </div>
                    <div className="col-2">
                      {personal.user.agreeToSellingEmails === "true" ? t("yes") : t("no") || t("no")}
                    </div>
                  </div>
                  <hr />
                  <div className="row summary__detail">
                    <div className="col-10">
                      <span>{t("form.steps.agree.agreeToSellingPhones")}:</span>
                    </div>
                    <div className="col-2">
                      {personal.user.agreeToSellingPhones === "true" ? t("yes") : t("no") || t("no")}
                    </div>
                  </div>
                  <hr />
                  <div className="row summary__detail">
                    <div className="col-10">
                      <span>{t("form.steps.agree.agreeToSharingData")}:</span>
                    </div>
                    <div className="col-2">
                      {personal.user.agreeToSharingData === "true" ? t("yes") : t("no") || t("no")}
                    </div>
                  </div>
                  <hr />
                  <div className="row summary__detail">
                    <div className="col-10">
                      <span>{t("form.steps.agree.agreeToMarketingEmails")}:</span>
                    </div>
                    <div className="col-2">
                      {personal.user.agreeToMarketingEmails === "true" ? t("yes") : t("no") || t("no")}
                    </div>
                  </div>
                  <hr />
                  <div className="row summary__detail">
                    <div className="col-10">
                      <span>{t("form.steps.agree.agreeToMarketingPhones")}:</span>
                    </div>
                    <div className="col-2">
                      {personal.user.agreeToMarketingPhones === "true" ? t("yes") : t("no") || t("no")}
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
            <div className="summary__buttons">
              <button className="btn secondary mr-2 " type="button" onClick={prevStep}>
                {t("back")}
              </button>
              <button disabled={isButtonDisabled} onClick={postRegistrationFormData} className="btn primary mr-3">
                {t("registration.form.companyData.register")}
              </button>
            </div>
            <div className="summary__messages"></div>
          </div>
        )}
      />
    </Wizard>
  );
};

export default SummaryData;
