import { combineReducers } from "redux";

import accountReducer, { AccountState } from "./AccountReducer";
import activationReducer, { ActivationState } from "./ActivationReducer";
import registrationReducer, { RegistrationState } from "./RegistrationReducer";
import uiReducer, { UiState } from "./UiReducer";

export interface AppState {
  registrationForm: RegistrationState;
  activationForm: ActivationState;
  account: AccountState;
  uiState: UiState;
}

const rootReducer = combineReducers<AppState | any>({
  account: accountReducer,
  registrationForm: registrationReducer,
  activationForm: activationReducer,
  ui: uiReducer,
});

export default rootReducer;
