import * as yup from "yup";
import i18next from "i18next";
import { EmailValidator } from "../../utils";

const generateInviteNewUserSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .test("Valid email", t("form.error.email.format"), (value) => EmailValidator(value))
      .email(t("form.error.email.format"))
      .required(t("form.error.required")),
    permission: yup.string().required(t("form.error.required")),
  });

export default generateInviteNewUserSchema;
