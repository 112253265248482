import i18next from "i18next";
import * as yup from "yup";
import { validateLength } from "pages/CatalogPage/CatalogPageValidation";
import { validateChecksum } from "pages/CatalogPage/CatalogPageValidation";
import moment from "moment";

const getDate = (value: string) => {
  return moment(value, "YYMMDD", true);
};

const isValidDate2 = (value: string) => {
  if (!value) return true;
  return getDate(value).isValid();
};

const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

const generateGenerator2DSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    n01: yup
      .string()
      .matches(/^(\d{0,14}|^$)$/, "Wartość musi składać się z maksymalnie 14 cyfr")
      .test({
        test: async function(value) {
          if (!value) {
            return this.createError({
              message: t("form.error.required"),
              path: "n01",
            });
          }
          if (value) {
            if (!validateLength(value)) {
              console.log("Not proper length");
              return this.createError({
                message: t("catalogPage.search.gtinLengthInvalid"),
                path: "n01",
              });
            }
            if (!validateChecksum(value)) {
              console.log("No valid checksum");
              return this.createError({
                message: t("catalogPage.search.gtinChecksumInvalid"),
                path: "n01",
              });
            }
            return true;
          } else {
            return true;
          }
        },
      }),

    ngs1Resolver: yup
      .string()
      .nullable(true)
      .matches(urlRegex, { message: "Wartość musi być prawidłowym adresem URL", excludeEmptyString: true }),

    n10: yup
      .string()
      .nullable(true)
      .max(20, "Wartość może mieć maksymalnie 20 znaków")
      .matches(/^[A-Za-z0-9]*$/, "Niepoprawny format danych"),

    n21: yup
      .string()
      .nullable(true)
      .max(20, "Wartość może mieć maksymalnie 20 znaków")
      .matches(/^[A-Za-z0-9]*$/, "Niepoprawny format danych"),

    n22: yup
      .string()
      .nullable(true)
      .max(20, "Wartość może mieć maksymalnie 20 znaków")
      .matches(/^[A-Za-z0-9]*$/, "Niepoprawny format danych"),

    n11: yup
      .string()
      .nullable(true)
      .matches(/^[0-9]*$/, "Wartość musi składać się tylko z cyfr")
      .matches(/(^\d{6}$|^$)/, "Wartość musi mieć dokładnie 6 cyfr")
      .test("isValidDate", "Nieprawidłowa data", isValidDate2),

    n13: yup
      .string()
      .nullable(true)
      .matches(/^[0-9]*$/, "Wartość musi składać się tylko z cyfr")
      .matches(/(^\d{6}$|^$)/, "Wartość musi mieć dokładnie 6 cyfr")
      .test("isValidDate", "Nieprawidłowa data", isValidDate2),

    n15: yup
      .string()
      .nullable(true)
      .matches(/^[0-9]*$/, "Wartość musi składać się tylko z cyfr")
      .matches(/(^\d{6}$|^$)/, "Wartość musi mieć dokładnie 6 cyfr")
      .test("isValidDate", "Nieprawidłowa data", isValidDate2),

    n17: yup
      .string()
      .nullable(true)
      .matches(/^[0-9]*$/, "Wartość musi składać się tylko z cyfr")
      .matches(/(^\d{6}$|^$)/, "Wartość musi mieć dokładnie 6 cyfr")
      .test("isValidDate", "Nieprawidłowa data", isValidDate2),

    n3103: yup
      .string()
      .nullable(true)
      .matches(/^[0-9]*$/, "Wartość musi składać się tylko z cyfr")
      .matches(/(^\d{6}$|^$)/, "Wartość musi mieć dokładnie 6 cyfr"),

    n422: yup
      .string()
      .nullable(true)
      .matches(/^[0-9]*$/, "Wartość musi składać się tylko z cyfr")
      .matches(/(^\d{3}$|^$)/, "Wartość musi mieć dokładnie 3 cyfry"),

    n7008: yup.string().max(3, "Wartość może mieć maksymalnie 3 znaki"),

    n7009: yup.string().max(10, "Wartość może mieć maksymalnie 10 znaków"),

    n7007: yup
      .string()
      .nullable(true)
      .matches(/^(\d{6}|\d{12})$/, "Wartość musi mieć dokładnie 6 lub 12 cyfr")
      .test({
        name: "fishing-date-test-6",
        message: "Nieprawidłowa data",
        test: function(value) {
          // Check if the value is undefined, null, or empty
          if (!value) return true;

          if (value.length === 6) {
            return isValidDate2(value);
          }

          return true;
        },
      })
      .test({
        name: "fishing-date-test-12",
        message:
          "Data połowu musi być w formacie RRMMDD lub RRMMDDRRMMDD i data zakończenia musi być późniejsza niż data rozpoczęcia",
        test: function(value) {
          // Check if the value is undefined, null, or empty
          if (!value) return true;

          if (value.length === 12) {
            const startDate = getDate(value.substring(0, 6));
            const endDate = getDate(value.substring(6));

            if (!startDate.isValid()) {
              return this.createError({
                message: "Nieprawidłowa data RRMMDD",
                path: "n7007",
              });
            }

            if (!endDate.isValid()) {
              return this.createError({
                message: "Nieprawidłowa data rrmmdd",
                path: "n7007",
              });
            }

            if (!startDate.isBefore(endDate)) {
              return this.createError({
                message: "rrmmdd musi być datą późniejszą od RRMMDD",
                path: "n7007",
              });
            }
          }

          return true;
        },
      }),

    n7005: yup.string().max(12, "Wartość może mieć maksymalnie 12 znaków"),

    n7006: yup
      .string()
      .nullable(true)
      .matches(/^[0-9]*$/, "Wartość musi składać się tylko z cyfr")
      .matches(/(^\d{6}$|^$)/, "Wartość musi mieć dokładnie 6 cyfr")
      .test("isValidDate", "Nieprawidłowa data", isValidDate2),
  });
export default generateGenerator2DSchema;
