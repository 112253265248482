import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useMessage from "hooks/useMessageStatus";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import NPWSStatus from "utils/statuses";
import useAuthentication from "../../hooks/useAuthentication";
import * as CatalogThunk from "../../store/thunk/CatalogThunk";

const CatalogDownloadPage: React.FC = () => {
  const { id = "" } = useParams();
  const dispatch = useDispatch();
  const { setMessage } = useMessage();
  const { t } = useTranslation();
  const { activeMembership } = useAuthentication();
  const [link, setLink] = useState<HTMLAnchorElement | undefined>(undefined);

  /**
   * Downloads file by id sent in URL params
   */
  async function checkFile() {
    try {
      const response: any = await dispatch(CatalogThunk.downloadCatalogFile(activeMembership!, id));
      const link = document.createElement("a");
      const fileName =
        response?.headers["content-disposition"]?.match(/filename=.+/)[0].replace("filename=", "") ||
        `${moment().format("DD-MM-YYYY")}_database.zip`;
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/zip" }));
      link.href = url;
      link.setAttribute("download", fileName);
      setLink(link);
      document.body.appendChild(link);
    } catch (err) {
      setMessage(NPWSStatus.LINK_EXPIRED);
    }
  }

  async function downloadFile() {
    link && link.click();
  }

  useEffect(() => {
    checkFile();
  }, []);

  return (
    <div className="row d-flex justify-content-center align-items-center login-page mt-5">
      <div className="col-12 d-flex justify-content-center">
        {/* Move to separate component when toasters will be ready */}
        {link && (
          <button className="btn primary" type="button" onClick={downloadFile}>
            {t("database.downloadPage.download")}
          </button>
        )}
      </div>
    </div>
  );
};

export default CatalogDownloadPage;
