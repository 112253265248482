import React from "react";
import { Trans, useTranslation } from "react-i18next";

const TermsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="terms-page">
      <h2 className="uppercase">{t("terms.page.title")}</h2>
      <section>
        <h4>{t("terms.paragraph.definitions.title")}</h4>
        <p>{t("terms.paragraph.definitions.intro")}</p>
        <ol className="parent-number-paranthesis">
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.data.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.data.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.dataProvider.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.dataProvider.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.dataReceiver.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.dataReceiver.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.catalog.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.catalog.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.account.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.account.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.gs1organization.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.gs1organization.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.eProdukctyPlatform.term")}</span>
            <span>{" " + t("terms.paragraph.definitions.eProdukctyPlatform.conjuction") + " "}</span>
            <span className="font-weight-bold">
              {t("terms.paragraph.definitions.eProdukctyPlatform.Platform.term")}
            </span>
            <span>
              {" - "}
              <Trans i18nKey="terms.paragraph.definitions.eProdukctyPlatform.explanation">
                terms.paragraph.definitions.eProdukctyPlatform.explanation[0]
                <a className="link" href={`https://${t("contact.www")}`} target="_blank">
                  {t("contact.www")}
                </a>
              </Trans>
            </span>
          </li>
          <li>
            <span className="font-weight-bold">Generator kodów 2D lub generator kodów dwuwymiarowych –</span>
            <span> narzędzie demonstracyjne umożliwiające wygenerowanie dwuwymiarowych kodów GS1 DataMatrix lub GS1 QR oraz linku GS1 Digital Link URI</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.terms.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.terms.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.registration.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.registration.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.gs1foundation.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.gs1foundation.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.contract.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.contract.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.recipent.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.recipent.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.serviceProvider.term")}</span>
            <span>{" " + t("terms.paragraph.definitions.serviceProvider.conjuction") + " "}</span>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.serviceProvider.gs1poland.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.serviceProvider.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.definedUser.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.definedUser.explanation")}</span>
          </li>
          <li>
            <span className="font-weight-bold">{t("terms.paragraph.definitions.anonymousUser.term")}</span>
            <span>{" - " + t("terms.paragraph.definitions.anonymousUser.explanation")}</span>
          </li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.generalProvisions.title")}</h4>
        <ol className="parent-number-paranthesis">
          <li>{t("terms.paragraph.generalProvisions.listItemOne")}</li>
          <li>{t("terms.paragraph.generalProvisions.listItemTwo")}</li>
          <li>
            <Trans i18nKey="terms.paragraph.generalProvisions.listItemThree">
              terms.paragraph.generalProvisions.listItemThree[0]
              <a className="link" href={`https://${t("contact.www")}`} target="_blank">
                {t("contact.www")}
              </a>
            </Trans>
          </li>
          <li>{t("terms.paragraph.generalProvisions.listItemFour")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.commonAccess.title")}</h4>
        <ol className="number-parenthesis">
          <li>
            <Trans i18nKey="terms.paragraph.commonAccess.listItemOne">
              terms.paragraph.commonAccess.listItemOne[0]
              <a className="link" href={`https://${t("contact.www")}`} target="_blank">
                {t("contact.www")}
              </a>
              terms.paragraph.commonAccess.listItemOne[2]
            </Trans>
          </li>
          <li>{t("terms.paragraph.commonAccess.listItemTwo")}</li>
          <li>{t("terms.paragraph.commonAccess.listItemThree")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.recipentRegistration.title")}</h4>
        <ol className="parent-number-paranthesis">
          <li>{t("terms.paragraph.recipentRegistration.listItemOne")}</li>
          <li>{t("terms.paragraph.recipentRegistration.listItemTwo")}</li>
          <li>{t("terms.paragraph.recipentRegistration.listItemThree")}</li>
          <li>{t("terms.paragraph.recipentRegistration.listItemFour")}</li>
          <li>{t("terms.paragraph.recipentRegistration.listItemFive")}</li>
          <li>{t("terms.paragraph.recipentRegistration.listItemSix")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.services.title")}</h4>
        <ol className="parent-number-paranthesis">
          <li>{t("terms.paragraph.services.listItemOne")}</li>
          <ol className="number-parenthesis">
            <li>{t("terms.paragraph.services.listItemOne.itemOne")}</li>
            <ol className="lower-alpha-parenthesis">
              <li>{t("terms.paragraph.services.listItemOne.itemOne.subitemOne")}</li>
              <li>{t("terms.paragraph.services.listItemOne.itemOne.subitemTwo")}</li>
              <li>{t("terms.paragraph.services.listItemOne.itemOne.subitemThree")}</li>
              <li>{t("terms.paragraph.services.listItemOne.itemOne.subitemFour")}</li>
              <li>{t("terms.paragraph.services.listItemOne.itemOne.subitemFive")}</li>
              <li>{t("terms.paragraph.services.listItemOne.itemOne.subitemSix")}</li>
            </ol>
            <li>{t("terms.paragraph.services.listItemOne.itemTwo")}</li>
            <li>{t("terms.paragraph.services.listItemOne.itemThree")}</li>
            <ol type="a" className="lower-alpha-parenthesis">
              <li>{t("terms.paragraph.services.listItemOne.itemThree.subitemOne")}</li>
              <li>{t("terms.paragraph.services.listItemOne.itemThree.subitemTwo")}</li>
            </ol>
            <li>Usługa generowania kodów 2D (dwuwymiarowych kodów): GS1 DataMatrix lub GS1 QR oraz linku GS1 Digital Link URI w wersji demonstracyjnej.</li>
          </ol>
          <li>{t("terms.paragraph.services.listItemTwo")}</li>
          <li>{t("terms.paragraph.services.listItemThree")}</li>
          <li>{t("terms.paragraph.services.listItemFour")}</li>
          <li>{t("terms.paragraph.services.listItemFive")}</li>
          <li>Usługa, o której mowa w ust. 1 pkt. 4) jest usługą bezpłatną, dostępną dla użytkowników zarejestrowanych i zalogowanych na platformie eProdukty. 
            Generowanie kodów GS1 DataMatrix, GS1 QR oraz linku GS1 Digital Link URI jest możliwe dla numerów GTIN nadanych przez dowolną Organizację Krajową GS1, ale tylko tych, które:</li>
            <ol type="a" className="lower-alpha-parenthesis">
              <li>są objęte aktywną licencją w Systemie GS1</li>
              <li>mają uzupełnione dane produktowe w Rejestrze GS1</li>
            </ol>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.sharedData.title")}</h4>
        <ol>
          <li>{t("terms.paragraph.sharedData.listItemOne")}</li>
          <li>{t("terms.paragraph.sharedData.listItemTwo.partOne")}</li>
          <ol type="a">
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemOne")}</li>
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemTwo")}</li>
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemThree")}</li>
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemFour")}</li>
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemFive")}</li>
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemSix")}</li>
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemSeven")}</li>
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemEight")}</li>
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemNine")}</li>
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemTen")}</li>
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemEleven")}</li>
            <li>{t("terms.paragraph.sharedData.listItemTwo.itemTwelve")}</li>
          </ol>
          <p>{t("terms.paragraph.sharedData.listItemTwo.partTwo")}</p>
          <li>{t("terms.paragraph.sharedData.listItemThree")}</li>
          <li>{t("terms.paragraph.sharedData.listItemFour")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.technicalConditions.title")}</h4>
        <ol>
          <li>{t("terms.paragraph.technicalConditions.listItemOne")}</li>
          <ol type="a">
            <li>{t("terms.paragraph.technicalConditions.listItemOne.itemOne")}</li>
            <p>{t("terms.paragraph.technicalConditions.listItemOne.itemOne.browsers")}</p>
            <li>{t("terms.paragraph.technicalConditions.listItemOne.itemTwo")}</li>
            <p>{t("terms.paragraph.technicalConditions.listItemOne.itemTwo.browsers")}</p>
          </ol>
          <li>{t("terms.paragraph.technicalConditions.listItemTwo")}</li>
          <ol type="a">
            <li>{t("terms.paragraph.technicalConditions.listItemTwo.itemOne")}</li>
            <li>{t("terms.paragraph.technicalConditions.listItemTwo.itemTwo")}</li>
          </ol>
          <li>{t("terms.paragraph.technicalConditions.listItemThree")}</li>
          <ol type="a">
            <li>{t("terms.paragraph.technicalConditions.listItemThree.itemOne")}</li>
            <li>{t("terms.paragraph.technicalConditions.listItemThree.itemTwo")}</li>
            <li>{t("terms.paragraph.technicalConditions.listItemThree.itemThree")}</li>
          </ol>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.receiverResponsibilities.title")}</h4>
        <ol className="parent-number-paranthesis">
          <li>{t("terms.paragraph.receiverResponsibilities.listItemOne")}</li>
          <li>{t("terms.paragraph.receiverResponsibilities.listItemTwo")}</li>
          <ol className="number-parenthesis">
            <li>{t("terms.paragraph.receiverResponsibilities.listItemTwo.itemOne")}</li>
            <li>{t("terms.paragraph.receiverResponsibilities.listItemTwo.itemTwo")}</li>
            <li>{t("terms.paragraph.receiverResponsibilities.listItemTwo.itemThree")}</li>
            <li>{t("terms.paragraph.receiverResponsibilities.listItemTwo.itemFour")}</li>
            <li>{t("terms.paragraph.receiverResponsibilities.listItemTwo.itemFive")}</li>
          </ol>
          <li>{t("terms.paragraph.receiverResponsibilities.listItemThree")}</li>
          <ol className="number-parenthesis">
            <li>{t("terms.paragraph.receiverResponsibilities.listItemThree.itemOne")}</li>
            <li>{t("terms.paragraph.receiverResponsibilities.listItemThree.itemTwo")}</li>
          </ol>
          <li>{t("terms.paragraph.receiverResponsibilities.listItemFour")}</li>
          <li>{t("terms.paragraph.receiverResponsibilities.listItemFive.intro")}</li>
          <ol className="number-parenthesis">
            <li>{t("terms.paragraph.receiverResponsibilities.listItemFive.subitemOne")}</li>
            <li>{t("terms.paragraph.receiverResponsibilities.listItemFive.subitemTwo")}</li>
          </ol>
          <li>{t("terms.paragraph.receiverResponsibilities.listItemSix")}</li>
          <li>{t("terms.paragraph.receiverResponsibilities.listItemSeven")}</li>
          <li>{t("terms.paragraph.receiverResponsibilities.listItemEight")}</li>
          <li>{t("terms.paragraph.receiverResponsibilities.listItemNine")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.payments.title")}</h4>
        <ol className="parent-number-paranthesis">
          <li>{t("terms.paragraph.payments.listItemOne")}</li>
          <li>{t("terms.paragraph.payments.listItemTwo")}</li>
          <li>{t("terms.paragraph.payments.listItemThree")}</li>
          <li>
            <Trans i18nKey="terms.paragraph.payments.listItemFour">
              terms.paragraph.commonAccess.listItemOne[0]
              <a className="link" href={`mailto:kontakt@eprodukty.gs1.pl`}>
                {t("contact.www")}
              </a>
              terms.paragraph.commonAccess.listItemOne[2]
            </Trans>
          </li>
          <li>{t("terms.paragraph.payments.listItemFive")}</li>
          <li>{t("terms.paragraph.payments.listItemSix")}</li>
          <li>{t("terms.paragraph.payments.listItemSeven")}</li>
          <li>{t("terms.paragraph.payments.listItemEight")}</li>
          <li>{t("terms.paragraph.payments.listItemNine")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.warranty.title")}</h4>
        <ol className="parent-number-paranthesis">
          <li>{t("terms.paragraph.warranty.listItemOne")}</li>
          <ol className="number-parenthesis">
            <li>{t("terms.paragraph.warranty.listItemOne.itemOne")}</li>
            <li>{t("terms.paragraph.warranty.listItemOne.itemTwo")}</li>
          </ol>
          <li>{t("terms.paragraph.warranty.listItemTwo")}</li>
          <li>Generator kodów 2D jest narzędziem demonstracyjnym. dedykowanym głównie dla firm, które planują przetestować kody dwuwymiarowe, przed wdrożeniem w przyszłości ich stosowania w miejsce funkcjonującego kodu EAN. 
            Z zastrzeżeniem przypadków, w których bezwzględnie obowiązujące przepisy wykluczają możliwość ograniczenia odpowiedzialności, niniejszym wyłącza się w najszerszym możliwym zakresie odpowiedzialność GS1 Polska, innych Organizacji Krajowych i Stowarzyszenia GS1 z tytułu wszelkich szkód związanych z korzystaniem z Generatora Kodów 2D oraz stosowaniem wygenerowanych za jego pomocą kodów. 
            GS1 Polska, inne Organizacje Krajowe ani Stowarzyszenie GS1 nie udzielają jakiejkolwiek gwarancji, ani nie składają jakichkolwiek zapewnień wyraźnych lub dorozumianych, dotyczących działania Generatora Kodów 2D ani poprawności Kodów 2D wygenerowanych za jego pomocą.
            Użytkownicy i Usługobiorcy, korzystający z Generatora Kodów 2D przyjmują do wiadomości i akceptują fakt, że narzędzie to może zawierać błędy a kody wygenerowane za jego pomocą mogą być niepoprawne.
            GS1 Polska zastrzega sobie ponadto prawo wprowadzania zmian w funkcjonowaniu Generatora Kodów 2D jak i prawo wycofania dostępności tego narzędzia w dowolnym czasie.
            Wprowadzenie kodów na produkty fizyczne powinno odbywać się zgodnie z wytycznymi ujętymi w Specyfikacjach Ogólnych GS1.</li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.complaint.title")}</h4>
        <ol className="parent-number-paranthesis">
          <li>
            <Trans i18nKey="terms.paragraph.complaint.listItemOne">
              terms.paragraph.complaint.listItemOne[0]
              <a className="link" href={`mailto:${t("contact.emailAddress")}`}>
                {t("contact.emailAddress")}
              </a>
            </Trans>
          </li>
          <li>{t("terms.paragraph.complaint.listItemTwo")}</li>
          <li>{t("terms.paragraph.complaint.listItemThree")}</li>
          <li>{t("terms.paragraph.complaint.listItemFour")}</li>
          <li>{t("terms.paragraph.complaint.listItemFive")}</li>
          <li>{t("terms.paragraph.complaint.listItemSix")}</li>
          <li>{t("terms.paragraph.complaint.listItemSeven")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.dataProtection.title")}</h4>
        <ol className="parent-number-paranthesis">
          <li>{t("terms.paragraph.dataProtection.listItemOne")}</li>
          <li>
            <Trans i18nKey="terms.paragraph.dataProtection.listItemTwo">
              terms.paragraph.dataProtection.listItemTwo[0]
              <a className="link" href={`https://${t("contact.www")}`} target="_blank">
                {t("contact.www")}
              </a>
            </Trans>
          </li>
          <li>
            <Trans i18nKey="terms.paragraph.dataProtection.listItemThree">
              terms.paragraph.dataProtection.listItemThree[0]
              <a className="link" href={`https://${t("contact.www")}`} target="_blank">
                {t("contact.www")}
              </a>
              terms.paragraph.dataProtection.listItemThree[2]
            </Trans>
          </li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.exclusiveRights.title")}</h4>
        <ol className="parent-number-paranthesis">
          <li>{t("terms.paragraph.exclusiveRights.listItemOne")}</li>
          <li>{t("terms.paragraph.exclusiveRights.listItemTwo")}</li>
          <li>{t("terms.paragraph.exclusiveRights.listItemThree")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.contractTermination.title")}</h4>
        <ol className="parent-number-paranthesis">
          <li>{t("terms.paragraph.contractTermination.listItemOne")}</li>
          <li>{t("terms.paragraph.contractTermination.listItemTwo")}</li>
          <li>{t("terms.paragraph.contractTermination.listItemThree")}</li>
          <li>{t("terms.paragraph.contractTermination.listItemFour")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("terms.paragraph.finalProvisions.title")}</h4>
        <ol className="parent-number-paranthesis">
          <li>{t("terms.paragraph.finalProvisions.listItemOne")}</li>
          <li>{t("terms.paragraph.finalProvisions.listItemTwo")}</li>
          <ol className="number-parenthesis">
            <li>
              <Trans i18nKey="terms.paragraph.finalProvisions.listItemTwo.itemOne">
                terms.paragraph.finalProvisions.listItemTwo.itemOne[0]
                <a className="link" href={`mailto:${t("contact.emailAddress")}`}>
                  {t("contact.emailAddress")}
                </a>
              </Trans>
            </li>
            <li>{t("terms.paragraph.finalProvisions.listItemTwo.itemTwo")}</li>
          </ol>
          <li>{t("terms.paragraph.finalProvisions.listItemThree")}</li>
          <li>{t("terms.paragraph.finalProvisions.listItemFour")}</li>
          <li>{t("terms.paragraph.finalProvisions.listItemFive")}</li>
          <li>{t("terms.paragraph.finalProvisions.listItemSix")}</li>
        </ol>
      </section>
      <section className={"mt-5"}>
        <p>{t("terms.attachment")}</p>
        <p>{t("terms.attachment.subtitle")}</p>
        <ol className="parent-number-paranthesis">
          <li>{t("terms.attachment.firstItem")}</li>
          <li>
            {t("terms.attachment.secondItem")}
            <ol className="number-parenthesis">
              <li className={"font-weight-bold"}>
                {t("terms.attachment.sub.first")}
                <ol className={"no-style-list"}>
                  <li className={"m-0"}>{t("terms.attachment.sub.first.firstItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.first.secondItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.first.thirdItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.first.fourthItem")}</li>
                </ol>
              </li>
              <li className={"font-weight-bold"}>
                {t("terms.attachment.sub.second")}
                <ol className={"no-style-list"}>
                  <li className={"m-0"}>{t("terms.attachment.sub.second.firstItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.second.secondItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.second.thirdItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.second.fourthItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.second.fifthItem")}</li>
                </ol>
              </li>
              <li className={"font-weight-bold"}>
                {t("terms.attachment.sub.third")}
                <ol className={"no-style-list"}>
                  <li className={"m-0"}>{t("terms.attachment.sub.third.firstItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.third.secondItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.third.thirdItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.third.fourthItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.third.fifthItem")}</li>
                </ol>
              </li>
              <li className={"font-weight-bold"}>
                {t("terms.attachment.sub.fourth")}
                <ol className={"no-style-list"}>
                  <li className={"m-0"}>{t("terms.attachment.sub.fourth.firstItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.fourth.secondItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.fourth.thirdItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.fourth.fourthItem")}</li>
                  <li className={"m-0"}>{t("terms.attachment.sub.fourth.fifthItem")}</li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <p>{t("terms.attachment.disclaimer")}</p>
      </section>
    </div>
  );
};

export default TermsPage;
