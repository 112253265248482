import i18next from "i18next";
import * as yup from "yup";
import { RegonValidator } from "utils";

export const generateRegonSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    regon: yup
      .string()
      .test("Check if regon is valid", t("registration.form.companyData.regon.invalidFormat"), (regon) =>
        RegonValidator().validate(regon),
      )
      .required(t("form.error.required")),
  });
