import React from "react";
import TooltipWrapper from "components/Tooltip/Tooltip";

interface InputIconProps {
  src: string;
  show: boolean;
  tooltipText?: any;
  className?: string;
}

const InputIcon: React.FC<InputIconProps> = ({ src, show, tooltipText = "", className = "" }) => {
  return show ? (
    <TooltipWrapper tooltipText={tooltipText}>
      <img className={"blue-tooltip " + className} src={src} />
    </TooltipWrapper>
  ) : (
    <div></div>
  );
};

export default InputIcon;
