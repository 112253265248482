import { MessageType } from "components/StatusMesssage/StatusMessage";

export const SET_PENDING_STATUS = "ui.pending";
export const SET_MESSAGE = "ui.setMessage";
export const REMOVE_MESSAGE = "ui.removeMessage";

export interface UiAction {
  isPending: boolean;
}

export interface SetMessageAction {
  type: MessageType;
  message: string;
  duration?: number;
}

export interface SetPendingStatus {
  type: typeof SET_PENDING_STATUS;
  payload: UiAction;
}

export interface SetMessage {
  type: typeof SET_MESSAGE;
  payload: SetMessageAction;
}

export interface RemoveMessage {
  type: typeof REMOVE_MESSAGE;
}

/**
 * Sets pending status for the app (loading circle)
 * @param status
 * @returns
 */
export function setPendingStatus(status: boolean) {
  return {
    type: SET_PENDING_STATUS,
    payload: status,
  };
}

/**
 * Sets message
 * @param type
 * @param message
 * @param [duration]
 * @returns
 */
export function setMessage(type: MessageType, message: string, duration?: number) {
  return {
    type: SET_MESSAGE,
    payload: {
      type,
      message,
      duration,
    },
  };
}

/**
 * Removes last system message
 * @returns
 */
export function removeMessage() {
  return {
    type: SET_MESSAGE,
  };
}

export type UiActions = SetPendingStatus | SetMessage | RemoveMessage;
