import React, { useState } from "react";
import LoginForm from "components/LoginForm/LoginForm";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import generateLoginScheam from "components/LoginForm/LoginFormValidation";
import { login } from "store/thunk/AccountThunk";
import { useTranslation } from "react-i18next";
import useMembershipCookies from "hooks/useMembershipCookies";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";

import { Link, useLocation } from "react-router-dom";

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setMessage } = useMessage();
  const dispatch = useDispatch();
  const { handleSubmit, ...loginForm } = useForm({
    validationSchema: generateLoginScheam(t),
  });
  const location = useLocation();
  //@ts-ignore
  let { from } = location.state || { from: { pathname: "/" } };
  const { checkMembership } = useMembershipCookies();

  const [invalidAttempt, setInvalidAttempt] = useState<boolean>(false);

  const onSubmit = async (data: Record<string, any>) => {
    const { email, password, rememberMe } = data;
    try {
      const user = await dispatch(login(email, password, rememberMe));
      await checkMembership(user);
      history.push(from.pathname + from.search);
      setInvalidAttempt(false);
    } catch (err) {
      setMessage(NPWSStatus.LOGIN_FAILED);
      setInvalidAttempt(true);
    }
  };

  return (
    <div className="login-page">
      <div className="col-5">
        <div className="component-container">
          <LoginForm invalidLoginAttempt={invalidAttempt} onSubmit={handleSubmit(onSubmit)} loginForm={loginForm} />
        </div>
        <div className="mt-3">
          <Link className="d-flex justify-content-center mb-4 link" to="/registration">
            {t("form.login.registration")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
