import React from "react";
import { useTranslation } from "react-i18next";
import ActivateAccountFormProps from "interfaces/ActivationInterface";
import PasswordInputForm from "../PasswordInputForm/PasswordInputForm";

const PasswordOnlyActivateAccountForm: React.FC<ActivateAccountFormProps> = ({ formSubmit, form, activationState }) => {
  const { t } = useTranslation();
  const { email } = activationState;

  return (
    <form onSubmit={formSubmit}>
      <div className="container px-0">
        <div className="row">
          <div className="col-6 mx-auto">
            <PasswordInputForm form={form} email={email} />
            <div className={`form-group d-flex justify-content-end`}>
              <button data-testid="activeSubmit" className="btn primary" type="submit">
                {t("activate")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default PasswordOnlyActivateAccountForm;
