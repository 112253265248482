let timeouts: { [key: string]: any } = {};
/**
 * Our own debounce
 * @param key
 * @param func
 * @param wait
 * @param immediate
 */
export function debounce(key: string, func: () => void, wait: number, immediate?: boolean) {
  return function () {
    //@ts-ignore
    let context: any = this;
    let args: any = arguments;
    let later = function () {
      timeouts[key] = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeouts[key];
    clearTimeout(timeouts[key]);
    timeouts[key] = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
