export const UPDATE_REGISTRATION_COMPANY_DATA = "registration.updateRegistrationCompanyData";
export const UPDATE_REGISTRATION_USER_DATA = "registration.updateRegistrationUserData";
export const GET_COMPANY_DETAILS_BY_REGON = "registration.getCompanyDetailsByRegon";
export const DISABLE_COMPANY_FORM = "registration.disableCompanyDataForm";
export const DISABLE_PERSONAL_FORM = "registration.disablePersonalForm";
export const REGON_CHECKED = "registration.regonChecked";
export const EMAIL_CHECKED = "registration.emailChecked";
export const RESET_FORM = "registration.resetForm";
export const NIP_TAKEN = "registration.nipStatus";

export interface LimitLeftData {
  limitApiCsv?: number | boolean;
  limitverify?: number | boolean;
  freeLimit?: number | boolean;
}
export interface LimitsData {
  verify?: number | string;
  api?: number | string;
}

export interface CompanyData {
  createdAt?: string;
  registrationDate?: string;
  id?: string;
  regon?: string;
  nip?: string;
  name?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  district?: string;
  country?: string;
  webPage?: string;
  companyProfile?: string;
  isAuthorized?: boolean;
  accessToDownload?: boolean;
  apiKey?: string;
  subscription?: string;
  counterCsv?: number;
  counterVerify?: number;
  counterAPI?: number;
  counterZippedProductsDownloads?: number;
  counterZippedProductsSum?: number;
  limitsLeft?: LimitLeftData;
  limits?: LimitsData;
  monetizationSwitch?: boolean;

  registrationPurpose_checkSingleGtin?: boolean;
  registrationPurpose_checkMultipleGtins?: boolean;
  registrationPurpose_useSearch?: boolean;
  registrationPurpose_downloadDatabase?: boolean;
  registrationPurpose_checkMyGtins?: boolean;
  registrationPurpose_generateCodeQR?: boolean;
}

export interface RegistrationUserData {
  user: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    confirmedStatute?: boolean;
    agreeToSellingEmails?: string;
    agreeToSellingPhones?: string;
    agreeToSharingData?: string;
    agreeToMarketingEmails?: string;
    agreeToMarketingPhones?: string;
  };
  isCompanyRepresentative?: boolean;
  file: any;
}

export interface UpdateRegistrationCompanyData {
  type: typeof UPDATE_REGISTRATION_COMPANY_DATA;
  payload: CompanyData;
}

export interface UpdateRegistrationUserData {
  type: typeof UPDATE_REGISTRATION_USER_DATA;
  payload: RegistrationUserData;
}

export interface GetCompanyDetailsByRegon {
  type: typeof GET_COMPANY_DETAILS_BY_REGON;
  payload: string;
}

export interface RegonChecked {
  type: typeof REGON_CHECKED;
  payload: boolean;
}

export interface EmailChecked {
  type: typeof EMAIL_CHECKED;
  payload: boolean;
}

export interface DisableCompanyDataForm {
  type: typeof DISABLE_COMPANY_FORM;
  payload: boolean;
}

export interface DisablePersonalDataForm {
  type: typeof DISABLE_PERSONAL_FORM;
  payload: boolean;
}

export interface ResetForm {
  type: typeof RESET_FORM;
}

export interface NipTaken {
  type: typeof NIP_TAKEN;
  payload: boolean;
}

/**
 * Updates registration company data
 * @param company
 * @returns
 */
export function updateRegistrationCompanyData(company: CompanyData) {
  return {
    type: UPDATE_REGISTRATION_COMPANY_DATA,
    payload: company,
  };
}

/**
 * Updates registration user data
 * @param userData
 * @returns
 */
export function updateRegistrationUserData(userData: RegistrationUserData) {
  return {
    type: UPDATE_REGISTRATION_USER_DATA,
    payload: userData,
  };
}

/**
 * Gets company details by regon
 * @param regon
 * @returns
 */
export function getCompanyDetailsByRegon(regon: string) {
  return {
    type: GET_COMPANY_DETAILS_BY_REGON,
    payload: regon,
  };
}

/**
 * Sets regon checked
 * @param isChecked
 * @returns
 */
export function setRegonChecked(isChecked: boolean) {
  return {
    type: REGON_CHECKED,
    payload: isChecked,
  };
}

/**
 * Sets email checked
 * @param checked
 * @returns
 */
export function setEmailChecked(checked: boolean) {
  return {
    type: EMAIL_CHECKED,
    payload: checked,
  };
}

/**
 * Disables company data form
 * @param shouldDesable
 * @returns
 */
export function disableCompanyDataForm(shouldDesable: boolean) {
  return {
    type: DISABLE_COMPANY_FORM,
    payload: shouldDesable,
  };
}

/**
 * Disables personal data form
 * @param shouldDesable
 * @returns
 */
export function disablePersonalDataForm(shouldDesable: boolean) {
  return {
    type: DISABLE_PERSONAL_FORM,
    payload: shouldDesable,
  };
}

/**
 * Resets form
 * @returns
 */
export function resetForm() {
  return {
    type: RESET_FORM,
  };
}

/**
 * Determines whether nip taken is
 * @param isTaken
 * @returns
 */
export function isNipTaken(isTaken: boolean) {
  return {
    type: NIP_TAKEN,
    payload: isTaken,
  };
}

export type RegistrationAction =
  | UpdateRegistrationCompanyData
  | GetCompanyDetailsByRegon
  | DisableCompanyDataForm
  | RegonChecked
  | UpdateRegistrationUserData
  | EmailChecked
  | DisablePersonalDataForm
  | ResetForm
  | NipTaken;
