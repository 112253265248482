import GS1APIClient from "clients/GS1APIClient";
import { RegistrationState } from "store/reducers/RegistrationReducer";
import { ActivationUserData } from "store/actions/ActivationActions";

/**
 * Gets company by regon
 * @param regon
 * @returns
 */
function getCompanyByRegon(regon: string) {
  return GS1APIClient.request<any>({
    url: `/companies/get_by_regon/?regon=${regon}`,
    method: "GET",
  });
}

/**
 * Gets company by nip
 * @param nip
 * @returns
 */
function getCompanyByNip(nip: string) {
  return GS1APIClient.request<any>({
    url: `/companies/get_by_nip/?nip=${nip}`,
    method: "GET",
  });
}

/**
 * Gets user by email
 * @param email
 * @returns
 */
function getUserByEmail(email: string) {
  return GS1APIClient.request<ActivationUserData["user"]>({
    url: `/accounts/by_email/${email}/`,
    method: "GET",
  });
}

/**
 * Companys have user registered
 * @param regon
 * @returns
 */
function companyHaveUserRegistered(regon: string) {
  return GS1APIClient.request<any>({
    url: `/companies/have_user_registered/?regon=${regon}`,
    method: "GET",
  });
}

/**
 * Posts registration form data
 * @param formData
 * @returns
 */
function postRegistrationFormData(formData: RegistrationState) {
  return GS1APIClient.request<any>({
    url: "/accounts/register/",
    method: "POST",
    data: formData,
  });
}

/**
 * Checks is user is assigned to company
 * @param regon
 * @param email
 * @returns
 */
function checkIsUserIsAssignedToCompany(regon: string, email: string) {
  return GS1APIClient.request<any>({
    url: `/accounts/assign_to_company/?regon=${regon}&email=${email}`,
    method: "GET",
  });
}

/**
 * Attachs register file
 * @param file
 * @param membership
 * @returns
 */
function attachRegisterFile(file: any, membership: string) {
  var formData = new FormData();
  formData.append("file", file);
  return GS1APIClient.request({
    url: "/accounts/register/" + membership + "/" + file.name + "/",
    method: "PUT",
    data: formData,
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  });
}

const RegistrationService = {
  getCompanyByRegon,
  postRegistrationFormData,
  getUserByEmail,
  checkIsUserIsAssignedToCompany,
  attachRegisterFile,
  companyHaveUserRegistered,
  getCompanyByNip,
};

export default RegistrationService;
