import { Membership } from "../interfaces/UserInterface";

export const haveAccess = (activeMembership: Membership, permission: string) => {
  return activeMembership && activeMembership.permissions.indexOf(permission) !== -1;
};

export const hasLimitLeft = (activeMembership: Membership) => {
  const limitApiCsv = activeMembership?.company?.limitsLeft?.limitApiCsv;
  return !((!limitApiCsv || limitApiCsv < 0) && activeMembership?.company?.monetizationSwitch);
};
