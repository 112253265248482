import React, { useState, useEffect } from "react";
import queryString from "query-string";
import _ from "lodash";
import ReactTable from "react-table";

import { useTranslation } from "react-i18next";
import TablePagination from "components/tablePagination/TablePagination";
import { useHistory } from "react-router-dom";
import { searchCompanies } from "store/thunk/CompanyThunk";

import SearchInput from "components/SearchInput/SearchInput";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Membership } from "interfaces/UserInterface";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";
import classNames from "classnames";
import useAuthentication from "../../hooks/useAuthentication";

interface SearchParams {
  limit?: string | number;
  offset?: string | number;
  search_multi_match?: string;
}

interface SortBy {
  fieldName: string;
  ordering: string;
}

const CompaniesListPage: React.FC = () => {
  const defaultQueryProps = {
    limit: 10,
    offset: 0,
  };
  const { activeMembership } = useAuthentication();
  const { t } = useTranslation();
  const { setMessage } = useMessage();
  const [sortBy, setSortBy] = useState<SortBy | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const searchProps: { [key: string]: any } = queryString.parse(history.location.search);
  const [queryProps, setQueryProps] = useState(searchProps);
  const [companiesSearch, setCompaniesSearch] = useState("");
  const [allCompanies, setAllCompanies] = useState(0);
  const [pageSize, setPageSize] = useState();

  const ThComponent: React.FC<{ name: string }> = ({ name }) => {
    return (
      <div className="row d-flex align-items-center h-100">
        <div className="col-9">{t(`companies.table.headerName.${name}`)}</div>{" "}
        <div className="col-3 p-0">
          <Arrow fieldName={name} />
        </div>
      </div>
    );
  };

  const Arrow: React.FC<{ fieldName: string }> = ({ fieldName }) => {
    return sortBy?.fieldName === fieldName ? (
      <i className={classNames("arrow ml-2", sortBy.ordering === "ASC" ? "up" : "down")} />
    ) : (
      <i />
    );
  };

  const columns = [
    {
      id: "name",
      accessor: (data: any) => data.name,
      headerClassName: "text-wrap box-shadow-none",
      Header: (data: any) => <ThComponent name="name" />,
    },
    {
      id: "nip",
      accessor: (data: any) => data.nip,
      headerClassName: "text-wrap box-shadow-none",
      Header: (data: any) => <ThComponent name="nip" />,
    },
    {
      id: "registration_date",
      accessor: (data: any) => (data.registrationDate ? moment(data.registrationDate).format("DD-MM-YYYY") : "-"),
      headerClassName: "text-wrap box-shadow-none",
      Header: (data: any) => <ThComponent name="registration_date" />,
    },
    {
      id: "is_authorized",
      accessor: (data: any) => (data.isAuthorized ? t("authorized") : t("notAuthorized")),
      headerClassName: "text-wrap box-shadow-none",
      Header: (data: any) => <ThComponent name="is_authorized" />,
    },
    {
      id: "access_to_download",
      accessor: (data: any) => (data.accessToDownload ? t("yes") : t("no")),
      headerClassName: "text-wrap box-shadow-none",
      Header: () => <ThComponent name="access_to_download" />,
    },
    {
      id: "access_to_verify",
      headerClassName: "text-wrap box-shadow-none",
      Header: () => <ThComponent name="access_to_verify" />,
      accessor: (data: any) => (data.accessToVerify ? t("yes") : t("no")),
    },
  ];

  /**
   * callback to searching with extra steps
   */
  function onSearch() {
    const search: SearchParams = {
      ...defaultQueryProps,
    };
    if (companiesSearch.trim()) {
      search.search_multi_match = companiesSearch;
    } else {
      delete search.search_multi_match;
    }
    setQueryProps(search);
  }

  /**
   * Allows user to search based on queryProps (taken from the URL)
   *
   */
  async function search() {
    try {
      const searchParams = {
        ...defaultQueryProps,
        ...queryProps,
      };
      const { data }: any = await dispatch(searchCompanies(activeMembership!, queryString.stringify(searchParams)));
      setCompanies(data.results);
      setAllCompanies(data.count);
      setPageSize(data.results.length);
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }

  /**
   * Updates page state
   * @param page
   */
  async function updatePageState(page: number) {
    const offset = parseInt(queryProps.limit) * page;
    await setQueryProps({ ...queryProps, offset });
  }

  /**
   * Updates search query params
   */
  function updateSearchQueryParams() {
    if (queryProps.search) setCompaniesSearch(queryProps.search);
    if (!_.isEmpty(queryProps)) {
      if (!queryProps.ordering) setSortBy(null);
      search();
      history.replace({
        pathname: "/companies",
        search: queryString.stringify(queryProps),
      });
    } else {
      search();
      setQueryProps(defaultQueryProps);
      history.replace({
        pathname: "/companies",
        search: queryString.stringify(defaultQueryProps),
      });
    }
  }

  useEffect(() => {
    updateSearchQueryParams();
  }, [queryProps]);

  useEffect(() => {
    updateSearchQueryParams();
  }, [activeMembership]);

  useEffect(() => {
    updateSearchQueryParams();
    search();
  }, []);

  return (
    <div className="companies-list">
      <SearchInput setSearchValue={setCompaniesSearch} inputValue={companiesSearch} onSearch={onSearch} />
      {companies.length ? (
        <div data-testid="companies-list">
          <ReactTable
            manual={true}
            columns={columns}
            PaginationComponent={TablePagination}
            data={companies}
            sortable={true}
            defaultPageSize={pageSize}
            pageSize={pageSize}
            pages={_.ceil(allCompanies / queryProps.limit)}
            onSortedChange={(data) => {
              const { id, desc } = data[0];
              setSortBy({ fieldName: id, ordering: desc ? "DESC" : "ASC" });
              setQueryProps({ ...queryProps, ordering: `${desc ? "-" : ""}${id}` });
            }}
            page={parseInt(queryProps.offset) / parseInt(queryProps.limit) || 0}
            showPagination={true}
            onPageChange={updatePageState}
            getTrProps={(state: any, rowInfo: any) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: (e: any) => {
                    history.push(`/companies/${rowInfo.original.id}`);
                  },
                };
              } else {
                return {};
              }
            }}
          />
        </div>
      ) : (
        <h2 data-testid="noResults">{t("companies.table.noResults")}</h2>
      )}
    </div>
  );
};

export default CompaniesListPage;
