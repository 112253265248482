import React, { FormEvent } from "react";
import Input from "components/Inputs/Input";
import InputIcon from "components/Inputs/InputIcon";
import NPWSTooltips from "components/Tooltip/TooltipValues";
import { useTranslation } from "react-i18next";

interface ChangePasswordProps {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  form: Record<string, any>;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ onSubmit, form: { errors, register } }) => {
  const { t } = useTranslation();

  return (
    <form className="set-password-form" onSubmit={onSubmit}>
      <Input
        labelText={t("changePassword.actualPassword")}
        name="oldPassword"
        type="password"
        data-testid="oldPassword"
        placeholder=" "
        error={errors.oldPassword}
        reference={register}
        autocomplete="old-password"
      />
      <Input
        labelText={t("changePassword.newPassword")}
        name="newPassword"
        type="password"
        data-testid="newPassword"
        placeholder=" "
        error={errors.newPassword}
        reference={register}
        autocomplete="new-password"
        inputIcon={
          <InputIcon tooltipText={NPWSTooltips.PasswordRequirments} show={true} src="/icons/icon-help-enabled.svg" />
        }
      />
      <Input
        labelText={t("changePassword.newPassword.repeat")}
        name="repeatNewPassword"
        placeholder=" "
        type="password"
        data-testid="repeatNewPassword"
        error={errors.repeatNewPassword}
        reference={register}
        autocomplete="new-password"
      />
      <div className="set-actions mt-2">
        <button data-testid="resetPasswordButton" className="btn primary w-100" type="submit">
          {t("set.password.newPassword.change")}
        </button>
      </div>
    </form>
  );
};

export default ChangePassword;
