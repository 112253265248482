import React from "react";
import { useTranslation } from "react-i18next";
import PricingComponent from "../../components/PricingComponent/PricingComponent";
import "./SubscriptionsPage.scss";

const SubscriptionsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="position-relative page-banner">
        <div className="w-100 position-absolute img-hover">
          <div className="container">
            <h1 className="first">Cennik</h1>
          </div>
        </div>
        <div className="position-relative">
          <img className="w-100" src="assets/v3/banners/pricing.png"></img>
          <div className="overlay"></div>
        </div>
      </div>

      <div className="container px-0" style={{ marginTop: "-1.5rem" }}>
        <div className="row">
          <div className="col-12">
            <p className="">{t("subscription.preTitle")}</p>
            <img width="426" height="278" src="/cennik-grafika.png" style={{ float: "right" }}></img>
            <ul>
              <li className="">{t("subscription.preTitle.list.first")}</li>
              <li className="">{t("subscription.preTitle.list.second")}</li>
            </ul>
            <p className="">{t("subscription.preTitle.under")}</p>
            <h5 className="txt-color-marine-blue">{t("subscription.title")}</h5>
            <h6 className="txt-color-marine-blue"> {`1. ${t("subscription.paragraph1.title")}`}</h6>
            <p> {t("subscription.paragraph1.text")} </p>
            <p>{t("subscription.header.first")}</p>
            <ul>
              <li className="">
                {" "}
                {t("subscription.listItem.first.p1")}
                <a href="/catalog">{t("subscription.listItem.first.p2")}</a>
              </li>
              <li>
                {" "}
                {t("subscription.listItem.second.p1")}
                <a href="/verify_data">{t("subscription.listItem.second.p2")}</a> {t("subscription.listItem.second.p3")}
              </li>
              <li> {t("subscription.listItem.third")}</li>
            </ul>
            <br />
            <h6 className="txt-color-marine-blue"> {`2. ${t("subscription.paragraph2.title")}`}</h6>
            <p>{t("subscription.header.second")}</p>
            <ul>
              <li>
                {" "}
                {t("subscription.listItem.fourth.p1")}
                <a href="/catalog">{t("subscription.listItem.fourth.p2")}</a>
                {t("subscription.listItem.fourth.p3")}{" "}
              </li>
              <li> {t("subscription.listItem.fifth")}</li>
            </ul>
            <p>
              {t("subscription.warning")} <br />
              {t("subscription.warning.firstLine")} {t("subscription.warning.secondLine")}
            </p>
            <br style={{ lineHeight: 3 }} />
          </div>
        </div>

        <PricingComponent />
      </div>
    </>
  );
};

export default SubscriptionsPage;
