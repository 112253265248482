import React, { useEffect } from "react";
import { Wizard, Steps, Step, WithWizard } from "react-albus";
import CompanyData from "components/Register/CompanyData/CompanyData";
import { useSelector, useDispatch } from "react-redux";
import PersonalData from "components/Register/PersonalData/PersonalData";
import SummaryData from "components/Register/SummaryData/SummaryData";
import * as RegistrationActions from "store/actions/RegistrationActions";
import RegisterNavigation from "components/Register/RegisterNavigation/RegisterNavigation";
import RegonData from "components/Register/RegonData/RegonData";
import { resetForm } from "store/actions/RegistrationActions";

import { ScrollToTop } from "utils/ScrollUp";

const stepsNames = {
  regon: "regon",
  companyData: "companyData",
  personalData: "personalData",
  summary: "summary",
};

const RegistrationPage: React.FC = () => {
  const dispatch = useDispatch();
  const companyFormData: RegistrationActions.CompanyData = useSelector((state: any) => state.registrationForm.company);
  const personalFormData: RegistrationActions.RegistrationUserData = useSelector(
    (state: any) => state.registrationForm.personal,
  );

  /**
   * Sets step in registration process
   * @param stepFunc
   */
  function setStep(stepFunc: () => void) {
    ScrollToTop();
    stepFunc();
  }

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  return (
    <div className="row d-flex justify-content-center align-items-center login-page my-5">
      <Wizard>
        <WithWizard
          render={({ next, previous, step, steps }) => (
            <>
              <div className="col-12 mb-3">
                <RegisterNavigation steps={steps} step={step} />
              </div>
              <div className="form-container">
                <Steps>
                  <Step
                    id={stepsNames.regon}
                    render={() => <RegonData formData={companyFormData} nextStep={() => setStep(next)} />}
                  />
                  <Step
                    id={stepsNames.companyData}
                    render={() => (
                      <CompanyData
                        prevStep={() => setStep(previous)}
                        formData={companyFormData}
                        nextStep={() => setStep(next)}
                      />
                    )}
                  />
                  <Step
                    id={stepsNames.personalData}
                    render={() => (
                      <PersonalData
                        formData={personalFormData}
                        prevStep={() => setStep(previous)}
                        nextStep={() => setStep(next)}
                      />
                    )}
                  />
                  <Step
                    id={stepsNames.summary}
                    render={() => (
                      <SummaryData
                        summaryData={{ company: companyFormData, personal: personalFormData }}
                        prevStep={() => setStep(previous)}
                      />
                    )}
                  />
                </Steps>
              </div>
            </>
          )}
        />
      </Wizard>
    </div>
  );
};

export default RegistrationPage;
