import React from "react";
import { useTranslation } from "react-i18next";

const CatalogBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="position-relative page-banner">
      <div className="w-100 position-absolute img-hover">
        <div className="container">
          <h1>{t("catalog.banner")}</h1>
        </div>
      </div>
      <div className="position-relative">
        <img className="w-100" src="assets/v3/banners/catalog.png"></img>
        <div className="overlay"></div>
      </div>
    </div>
  );
};

export default CatalogBanner;
