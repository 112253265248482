import React from "react";
import ProductCounter from "./ProductCounter";
import { FullSearchParams } from "./interfaces";
import { useTranslation } from "react-i18next";

interface CatalogPlaceholderProps {
  productsAmount: number;
  requestSent: boolean;
  allProducts: undefined | number;
  inputParams: FullSearchParams;
  isLastPage: boolean;
}

const CatalogPlaceholder: React.FC<CatalogPlaceholderProps> = ({
  productsAmount,
  requestSent,
  allProducts,
  inputParams,
  isLastPage = false,
}) => {
  const { t } = useTranslation();

  /**
   * Shows message
   * @returns
   */
  function showMessage() {
    if (requestSent && isLastPage) {
      return <h2 className="mt-4 mx-auto font-size-base">{t("catalogPage.search.toMuchResults")}</h2>;
    } else if (requestSent) {
      return (
        <h2 className="mt-4 mx-auto font-size-base">
          {inputParams.gtin_number ? t("catalogPage.search.noResults.forGtin") : t("catalogPage.search.noResults")}
        </h2>
      );
    } else {
      return (
        <>
          <img width="140" height="140" src="/assets/search-laptop-catalog.svg" className="my-3 ml-auto mr-auto" />
          <p className="text-justify font-size-base">Katalog pozwala wyszukać produkty oznaczone numerami GTIN (kodami EAN) nadanymi przez GS1. <a href="registration">Zarejestruj się</a>, aby skorzystać z możliwości wyszukiwania po dodatkowych parametrach tj. kategoria produktu, marka, nazwa produktu.
</p>
          <p className="text-justify mx-auto my-3 font-size-base">Wyniki wyszukiwania możesz przeglądać w formie kart produktowych w Katalogu. Większą ilość produktów możesz pobrać w formie bazy danych, eksportując ją do pliku. Baza zostanie przygotowana w formacie csv, którą zaimportujesz do systemu informatycznego lub przekonwertujesz do innego formatu.</p>
          <p className="text-justify mx-auto my-3 font-size-base">
            Jeśli chcesz połączyć się z bazą produktową za pomocą API, zarejestruj się na platformie i pobierz specyfikację techniczną w panelu użytkownika "aktywny pakiet usług". <br />
            Pobieranie bazy do pliku (eksport bazy) oraz integracja API są usługami płatnymi. Przejdź do <a href="/pricing">Cennika</a>,  aby zamówić odpowiedni pakiet usług.
          </p>
          <p>
            Oferujemy także możliwość pozyskania globalnej bazy GS1 za jednym razem. Przeczytaj o usłudze <a href="/how_it_works#:~:text=5.%20Pobranie%20pe%C5%82nej%20globalnej%20bazy%20produktowej%20GS1%20%E2%80%93%20ponad%20500mln%20produkt%C3%B3w">"Get All GTINs"</a>.
          </p>
          <span><hr /></span>
          <p>
          Jeśli jesteś właścicielem marki/producentem i chcesz pozyskać pulę kodów, aby oznakować swoje produkty, przejdź do strony głównej GS1 Polska i wypełnij formularz <a href="https://bit.ly/4bX3Bs1">"Zamów kod kreskowy"</a>
          </p>
        </>
      );
    }
  }

  return (
    <>
      {productsAmount! !== 0 ? <ProductCounter allProducts={allProducts} /> : null}
      <div>
        <div className="row my-2 ">
          <div className="col d-flex flex-column justify-content-center bg-color-white">{showMessage()}</div>
        </div>
      </div>
    </>
  );
};

export default CatalogPlaceholder;
