import React, { FormEvent, useState, useCallback } from "react";
import Input from "components/Inputs/Input";
import { useTranslation } from "react-i18next";

interface ResetFormProps {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  form: Record<string, any>;
  success: boolean;
}

const ResetPasswordForm: React.FC<ResetFormProps> = ({ form: { errors, register, setValue }, onSubmit, success }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit}>
      <Input
        labelText="E-mail"
        disabled={success}
        placeholder=" "
        name="email"
        error={errors.email}
        reference={register}
        handleChange={(e) => setValue("email", e.target.value.toLowerCase())}
      />
      <div className="reset-actions">
        <button data-testid="resetPasswordButton" className="btn primary w-100" disabled={success} type="submit">
          {success ? t("reset.password.sent") : t("reset.password")}
        </button>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
