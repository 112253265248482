import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import useAuthentication from "hooks/useAuthentication";
import { useDispatch } from "react-redux";
import { getProductsCounter } from "store/thunk/DataThunk";
import { CmsService } from "services";
import { CmsInterface } from "../../interfaces/CmsInterface";
import { Markup } from "interweave";

export const HowItWorks: React.FC<CmsInterface> = ({ preview, cmsData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [counters, setCounters] = useState({ productCount: "", productCountFrom7Days: "" });
  const { activeMembership } = useAuthentication();

  const [cmsDataState, setCmsDataState] = useState(cmsData);

  async function getCounters() {
    const {
      data: { productCount, productCountFrom7Days },
    }: any = await dispatch(getProductsCounter());
    setCounters({ productCount, productCountFrom7Days });
  }

  async function getPageContent() {
    const response = await CmsService.getPageContent("how-it-works", activeMembership?.id);
    setCmsDataState(response.data);
  }

  useEffect(() => {
    setCmsDataState(cmsData);

    const tryRefreshFsLightbox = () => {
      if (typeof window.refreshFsLightbox === "function") {
        window.refreshFsLightbox();
      } else {
        setTimeout(tryRefreshFsLightbox, 1000);
      }
    };

    setTimeout(tryRefreshFsLightbox, 500);

  }, [cmsData]);

  useEffect(() => {
    if (!cmsDataState) {
      getPageContent();
    }
  }, [activeMembership]);

  return (
    <>
      <div className="position-relative page-banner">
        <div className="w-100 position-absolute img-hover">
          <div className="container">
            <h1 className="first">Jak to działa - dane produktowe <br />i funkcjonalności platformy</h1>
          </div>
        </div>
        <div className="position-relative">
          <img className="w-100" src="assets/v3/banners/how-it-works.png"></img>
          <div className="overlay"></div>
        </div>
      </div>

      <div className="container px-0">
        <div className="second-chapter cms-content mt-4 ck-content">
          {cmsDataState ? <Markup content={cmsDataState && cmsDataState.content} /> : <></>}
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
