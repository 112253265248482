import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Input from "../Inputs/Input";
import { useForm } from "react-hook-form";
import Radio from "../Inputs/Radio";
import generateInviteNewUserSchema from "./InviteNewUserModalValidation";

interface ModalProps {
  show: boolean;
  handleClose: (email: string, permission: string, save: boolean) => void;
}

const InviteNewUserModal: React.FC<ModalProps> = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const { handleSubmit, setValue, register, errors } = useForm({
    validationSchema: generateInviteNewUserSchema(t),
  });
  const permissionOptions = [
    { value: "location_admin", labelValue: t("permission.location_admin") },
    { value: "location_user", labelValue: t("permission.location_user") },
  ];

  /**
   * Onlys close modal, do not save any changes
   */
  function onlyClose() {
    handleClose("", "", false);
  }

  /**
   * Validates and saves data from modal
   * @param data
   */
  function validateAndSave(data: Record<string, any>) {
    const { email, permission } = data;
    handleClose(email, permission, true);
  }

  return (
    <Modal
      restoreFocus={false}
      enforceFocus={false}
      autoFocus={false}
      show={show}
      className="invitation-modal"
      animation={false}
      onHide={onlyClose}
      size="sm"
      centered
      style={{ overflow: "hidden" }}
    >
      <Modal.Header className={"border-0"} closeButton>
        <Modal.Title className="txt-marine-blue font-size-base font-weight-bold">{t("users.invite")}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(validateAndSave)}>
        <Modal.Body className="border-0" style={{ overflow: "hidden" }}>
          <hr className="mt-0 mb-3 w-100" />
          <div className="d-flex justify-content-center">
            <div className="justify-content-start w-100">
              <Input
                labelText={t("emailAddress")}
                name="email"
                data-testid={"email"}
                placeholder=" "
                error={errors.email}
                reference={register}
                handleChange={(e) => setValue("email", e.target.value.toLowerCase())}
              />
              <Radio
                name="permission"
                setValue={setValue}
                error={errors.permission}
                reference={register}
                options={permissionOptions}
                defaultCheckedValue={"location_user"}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button className="btn primary ml-2" data-testid="submitInvite" type="submit">
            {t("users.invite.save")}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default InviteNewUserModal;
