import * as yup from "yup";
import i18next from "i18next";

const UPLOAD_DATA_VALID_FORMATS = [
  "text/csv",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const ONE_MB_IN_B = 1000000;
const MAX_FILE_SIZE = 50 * ONE_MB_IN_B;

/**
 * Determines whether file size valid is
 * @param fileSize
 * @param [maxFileSize]
 * @returns boolean
 */
export function isFileSizeValid(fileSize: number, maxFileSize: number = MAX_FILE_SIZE) {
  return fileSize <= maxFileSize;
}

/**
 * Determines whether file format valid is
 * @param fileFormat
 * @param [validFileFormats]
 * @returns boolean
 */
export function isFileFormatValid(fileFormat: string, validFileFormats: string[] = UPLOAD_DATA_VALID_FORMATS) {
  return validFileFormats.includes(fileFormat);
}

const generateUploadDataSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    file: yup.string().required(t("form.error.required")),
  });

export default generateUploadDataSchema;
