import React from "react";
import ChangePassword from "components/ChangePassword/ChangePassword";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import generateChangePasswordSchema from "./ChangePasswordSchema";
import useMessage from "hooks/useMessageStatus";
import { useDispatch } from "react-redux";
import * as AccountThunk from "store/thunk/AccountThunk";
import useAuthentication from "hooks/useAuthentication";
import { useParams, useHistory } from "react-router-dom";
import { Membership } from "interfaces/UserInterface";
import NPWSStatus from "utils/statuses";

export interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const { setMessage } = useMessage();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { activeMembership } = useAuthentication();

  const { handleSubmit, ...form } = useForm<ChangePasswordForm>({
    validationSchema: generateChangePasswordSchema(t),
  });

  /**
   * Changes user password
   * @param activeMembership
   * @param id
   * @param changePasswordForm
   */
  async function changeUserPassword(activeMembership: Membership, id: string, changePasswordForm: ChangePasswordForm) {
    try {
      await dispatch(await AccountThunk.changeUserPassword(activeMembership, id, changePasswordForm));
      history.push("/");
      await setMessage(NPWSStatus.PASSWORD_CHANGED);
    } catch (err) {
      if (err.response.status === 400) {
        setMessage(NPWSStatus.WRONG_OLD_PASSWORD);
      } else {
        setMessage(NPWSStatus.GENERIC_ERROR);
      }
    }
  }

  /**
   * onSubmit callback function that validates data and if the user is still logged in
   * @param form
   */
  async function onSubmit(form: ChangePasswordForm) {
    if (activeMembership && id) {
      changeUserPassword(activeMembership, id, form);
    }
  }

  return (
    <div className="change-password">
      <div className="component-container col-5">
        <h2>{t("changePassword.header")}</h2>
        <ChangePassword onSubmit={handleSubmit(onSubmit)} form={form}></ChangePassword>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
