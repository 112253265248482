import i18next from "i18next";
import { Dispatch } from "react";
import { matchExact } from "utils";
import { regexps } from "utils/constants";
import * as yup from "yup";
import { GCPData } from "../../interfaces/GCPData";
import { Membership } from "../../interfaces/UserInterface";
import GCPValidationService from "../../services/GCPValidationService";
import handleReCaptchaVerify from "./recaptha";

/**
 * Validates length
 * @param val
 * @returns boolean
 */
export function validateLength(val: string): boolean {
  return !val || [8, 12, 13, 14].includes(val.length);
}

/**
 * Normalizes gtin
 * @param gtin
 * @returns gtin
 */
export function normalizeGTIN(gtin: string): string {
  return gtin.length === 14 ? gtin.slice(1) : gtin;
}

/**
 * Validates checksum
 * @param gtin
 * @returns true if checksum
 */
export function validateChecksum(gtin: string): boolean {
  if (!gtin) {
    return true;
  }
  const checkDigit = +gtin.slice(-1);
  const calculatedChecksum = gtin
    .split("")
    .slice(0, -1)
    .reverse()
    .map((n) => +n)
    .reduce((sum: number, n, index) => (index % 2 !== 1 ? sum + n * 3 : sum + n), 0);
  const calculatedCheckDigit = Math.ceil(calculatedChecksum / 10) * 10 - calculatedChecksum;
  return calculatedCheckDigit === checkDigit;
}

/**
 * Validates country code
 * @param gtin
 * @returns true if country code
 */
export function validateCountryCode(gtin: string): boolean {
  if (!gtin) {
    return true;
  }
  const normalizedGTIN = normalizeGTIN(gtin);
  return normalizedGTIN.startsWith("590") || normalizedGTIN.startsWith("00000590");
}

/**
 * Validates gcp
 * @param gtin
 * @param setGcpData
 * @returns
 */
async function validateGCP(gtin: string, setGcpData: Dispatch<GCPData | null | string>) {
  if (!gtin) {
    setGcpData(null);
    return true;
  }

  const data = await GCPValidationService.searchForGCP(gtin.padStart(14, "0"));
  if (!data) {
    return false;
  }

  setGcpData(data);
  return true;
}

function hasErrorsProperty(obj: any): obj is { errors: any[] } {
  return obj && typeof obj === 'object' && 'errors' in obj;
}

export const generateCatalogSchema = (
  t: i18next.TFunction,
  setCompanyData: Dispatch<GCPData | null | string>,
  executeRecaptcha: any,
  activeMembership: Membership | null = null,
) =>
  yup.object().shape({
    company_nip: yup
      .string()
      .test("Valid nip", t("catalogPage.filters.nip.invalid"), (value) =>
        !value ? true : matchExact(regexps.NIP_REGEX, value),
      ),
    ...(!activeMembership
      ? {
          acceptTerms: yup
            .bool()
            .required(t("errors.acceptTerms.required"))
            .oneOf([true], t("errors.acceptTerms.invalid")),
        }
      : {}),
    gtin_number: yup.string().test({
      name: "Gtin Validator",
      // eslint-disable-next-line object-shorthand
      test: async function(value) {
        if (value) {
          if (!validateLength(value)) {
            console.log("Not proper length");
            return this.createError({
              message: t("catalogPage.search.gtinLengthInvalid"),
              path: "gtin_number",
            });
          }
          if (!validateChecksum(value)) {
            console.log("No valid checksum");
            return this.createError({
              message: t("catalogPage.search.gtinChecksumInvalid"),
              path: "gtin_number",
            });
          }
          await handleReCaptchaVerify(executeRecaptcha);
          const data = await GCPValidationService.searchForGCP(value.padStart(14, "0"), activeMembership);
          if (data === "ReCAPTCHA not verified") {
            return this.createError({
              message: t("catalogPage.search.ReCAPTCHA"),
              path: "gtin_number",
            });
          } else if (data === "User not logged") {
            console.log("User not logged");
            return this.createError({
              message: t("catalogPage.search.globalGtinNotAccess"),
              path: "gtin_number",
            });
          } else if (data === "Not found" || data === "not_found") {
            console.log("Not found");
            return this.createError({
              message: t("catalogPage.search.gtinNotFound"),
              path: "gtin_number",
            });
          } else if (data === "too_many_requests") {
            return this.createError({
              message: "Zbyt dużo zapytań",
              path: "gtin_number",
            });
          } else if (!data) {
            console.log("Not found");
            return this.createError({
              message: t("catalogPage.search.gtinInvalid"),
              path: "gtin_number",
            });
          } else if (typeof data === 'string') {
            return this.createError({
              message: typeof data === 'string' ? data : JSON.stringify(data),
              path: "gtin_number",
            });
          }
          setCompanyData(data);
          return true;
        } else {
          return true;
        }
      },
    }),
  });
