import React from "react";
import { useSelector } from "react-redux";
import CatalogTable from "components/Table/CatalogTable";
import ProductCounter from "./ProductCounter";
import { FullSearchParams } from "./interfaces";
import CatalogPlaceholder from "./CatalogPlaceholder";

interface TableOrPlaceholderProps {
  productsAmount: number;
  products: any[];
  allProducts: undefined | number;
  inputParams: FullSearchParams;
  setInputParams: React.Dispatch<React.SetStateAction<FullSearchParams>>;
  requestSent: boolean;
  page: number;
  getDatabase: () => void;
}
const TableOrPlaceholder: React.FC<TableOrPlaceholderProps> = ({
  productsAmount,
  products,
  allProducts,
  inputParams,
  setInputParams,
  requestSent,
  page,
  getDatabase,
}) => {
  const { isPending } = useSelector((store: any) => store.ui);
  const MAX_PAGES: number = 3;

  return !isPending ? (
    productsAmount > 0 && page < MAX_PAGES - 1 ? (
      <>
        {productsAmount! !== 0 ? <ProductCounter allProducts={allProducts} /> : null}
        <CatalogTable
          data-testId="catalog-table"
          maxPages={MAX_PAGES}
          data={products}
          numAllRecords={allProducts!}
          inputParams={inputParams}
          setInputParams={setInputParams}
          currentPage={page}
          getDatabase={getDatabase}
        />
      </>
    ) : (
      <CatalogPlaceholder
        productsAmount={productsAmount}
        requestSent={requestSent}
        isLastPage={page >= MAX_PAGES - 1}
        allProducts={allProducts}
        inputParams={inputParams}
      />
    )
  ) : null;
};

export default TableOrPlaceholder;
