import * as yup from "yup";
import i18next from "i18next";

const generateLoginScheam = (t: i18next.TFunction) =>
  yup.object().shape({
    email: yup.string().email(t("form.error.email.format")).required(t("form.error.required")),
    password: yup.string().required(t("form.error.required")),
  });

export default generateLoginScheam;
