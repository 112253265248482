import UserDataForm, { MembershipUserForm } from "components/UserDataForm/UserDataForm";
import useAuthentication from "hooks/useAuthentication";
import useMessage from "hooks/useMessageStatus";
import { ThunkDispatch } from "interfaces/StoreInterface";
import { Membership } from "interfaces/UserInterface";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ScrollToTop } from "utils/ScrollUp";
import NPWSStatus from "utils/statuses";
import * as AccountThunk from "../../store/thunk/AccountThunk";

const UserData: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch>();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { activeMembership } = useAuthentication();
  const [userData, setUserData] = useState<Membership | undefined>(undefined);
  const { setMessage } = useMessage();

  /**
   * Determines whether submit on
   * @param { user }
   */
  async function onSubmit({ user }: { user: MembershipUserForm }) {
    saveUserDataById(id, user);
  }

  /**
   * Gets user by id
   * @param id
   */
  async function getUserById(id: string) {
    try {
      if (activeMembership) {
        const user = await dispatch(await AccountThunk.getUserById(activeMembership, id));
        setUserData(user);
      }
    } catch (err) {
      history.push("/");
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }

  /**
   * Saves user data by id
   * @param id
   * @param data
   */
  async function saveUserDataById(id: string, data: MembershipUserForm) {
    try {
      if (activeMembership) {
        await dispatch(await AccountThunk.updateUserById(activeMembership, id, data));
        setMessage(NPWSStatus.UPDATE_USER_DATA_SUCCESS);
        ScrollToTop();
      }
    } catch (err) {
      if (err.response.status === 400) {
        setMessage({ ...NPWSStatus.REMOVE_LAST_COMPANY_REPRESENTATIVE, duration: 5000 });
      } else {
        setMessage(NPWSStatus.GENERIC_ERROR);
      }
    }
  }

  useEffect(() => {
    if (id) {
      getUserById(id);
    }
  }, [activeMembership]);

  return (
    <div className="row d-flex justify-content-center align-items-center login-page my-5">
      {userData && (
        <div className="form-container">{userData && <UserDataForm formData={userData} onSubmit={onSubmit} />}</div>
      )}
    </div>
  );
};

export default UserData;
