import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { State, ThunkDispatch } from "interfaces/StoreInterface";
import * as AccountThunk from "store/thunk/AccountThunk";

import useMembershipCookies from "hooks/useMembershipCookies";
import useAuthentication from "../../hooks/useAuthentication";
import { ScrollToTop } from "utils/ScrollUp";

interface Props {
  component: React.FC;
  path: string;
  exact: boolean;
}

function PublicRouteRenderer({ component: PageComponent, path }: Props) {
  const dispatch = useDispatch<ThunkDispatch>();
  const account = useSelector((state: State) => state.account);
  const { user: currentUser, authenticated } = account;
  const { checkMembership } = useMembershipCookies();

  const fetchCurrentUser = async () => {
    if (authenticated && !currentUser) {
      try {
        const user = await dispatch(await AccountThunk.fetchCurrentUser());
        await checkMembership(user);
      } catch (error) {
        // expected that we don't do anything  with an error
      }
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    ScrollToTop("auto");
  }, [PageComponent]);

  return <PageComponent />;
}

export const PublicRoute = ({ component, ...rest }: Props) => {
  const { authenticated } = useAuthentication();

  if (authenticated && rest.path === "/login") {
    return <Redirect to={"/"} />;
  } else {
    return <Route {...rest} render={() => <PublicRouteRenderer component={component} {...rest} />} />;
  }
};

export default PublicRoute;
