import * as RegistrationAction from "store/actions/RegistrationActions";

type RegistrationActions = RegistrationAction.RegistrationAction;

export interface RegistrationState {
  regonChecked: boolean;
  disableCompanyDataForm: boolean;
  emailChecked: boolean;
  disablePersonalForm: boolean;
  company: RegistrationAction.CompanyData;
  personal: RegistrationAction.RegistrationUserData;
  nipInUse: boolean;
}

export const initialCompanyData = {
  regon: "",
  nip: "",
  name: "",
  street: "",
  city: "",
  postalCode: "__-___",
  district: "",
  country: "Polska",
  webPage: "",
  companyProfile: "",

  registrationPurpose_checkSingleGtin: false,
  registrationPurpose_checkMultipleGtins: false,
  registrationPurpose_useSearch: false,
  registrationPurpose_downloadDatabase: false,
  registrationPurpose_checkMyGtins: false,
  registrationPurpose_generateCodeQR: false,
};


export const initialPersonalData = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "+48 ___-___-___",
    confirmedStatute: false,
    agreeToSellingEmails: "",
    agreeToSellingPhones: "",
    agreeToSharingData: "",
    agreeToMarketingEmails: "",
    agreeToMarketingPhones: "",
  },
  isCompanyRepresentative: false,
  file: null,
};

export const initialRegistrationState: RegistrationState = {
  regonChecked: false,
  disableCompanyDataForm: true,
  emailChecked: false,
  disablePersonalForm: true,
  company: initialCompanyData,
  personal: initialPersonalData,
  nipInUse: true,
};

/**
 * Registrations reducer
 * @param [state]
 * @param action
 * @returns
 */
export default function registrationReducer(
  state: RegistrationState = initialRegistrationState,
  action: RegistrationActions,
) {
  switch (action.type) {
    case RegistrationAction.UPDATE_REGISTRATION_COMPANY_DATA: {
      return {
        ...state,
        company: { ...state.company, ...action.payload },
      };
    }
    case RegistrationAction.UPDATE_REGISTRATION_USER_DATA: {
      return {
        ...state,
        personal: {
          ...state.personal,
          ...action.payload,
          user: {
            ...state.personal.user,
            ...action.payload.user,
          },
        },
      };
    }
    case RegistrationAction.REGON_CHECKED: {
      return {
        ...state,
        regonChecked: action.payload,
      };
    }
    case RegistrationAction.EMAIL_CHECKED: {
      return {
        ...state,
        emailChecked: action.payload,
      };
    }
    case RegistrationAction.DISABLE_COMPANY_FORM: {
      return {
        ...state,
        disableCompanyDataForm: action.payload,
      };
    }
    case RegistrationAction.DISABLE_PERSONAL_FORM: {
      return {
        ...state,
        personal: {
          ...state.personal,
          user: {
            ...state.personal.user,
            firstName: action.payload ? "xxxxxxxx" : "",
            lastName: action.payload ? "xxxxxxxx" : "",
            phoneNumber: action.payload ? "+99 999-999-999" : "+48",
          },
        },
        disablePersonalForm: action.payload,
      };
    }
    case RegistrationAction.RESET_FORM: {
      return {
        ...initialRegistrationState,
      };
    }
    case RegistrationAction.NIP_TAKEN: {
      return {
        ...state,
        nipInUse: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
