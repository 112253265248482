import React, { useState, useEffect } from "react";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";

import { useTranslation } from "react-i18next";
import { TreeNodeProps } from "rc-tree/lib/TreeNode";
import { mapCategories } from "utils/categories";
import { SelectedCategory, TableCategory } from "interfaces/CategoryInterface";
import { GPC_SELECT_SEGMENTS_LIMIT } from "utils/constants";

interface CategoriesTreeProps {
  selectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
  setSelectedCategories: (ids: SelectedCategory[]) => void;
  searchedKeyword?: string;
  categories: TableCategory[];
  setSegmentsLimitReached: (isLimitReached: boolean) => void;
}

const CategoriesTree: React.FC<CategoriesTreeProps> = ({
  selectedKeys,
  setSelectedKeys,
  setSelectedCategories,
  searchedKeyword = "",
  categories,
  setSegmentsLimitReached,
}) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(["0"]);
  const [filteredNodes, setFilteredNodes] = useState<TableCategory[]>([]);
  const [debounce, setDebounce] = useState<ReturnType<typeof setTimeout> | null>(null);
  let selectedSegments = new Set();

  /**
   * Gets selected keys
   * @param tree
   * @param selectedKeys
   * @returns selected keys in categories tree
   */
  function getSelectedKeys(tree: TableCategory[], selectedKeys: string[]) {
    return (tree.map(mapCategories).flat(Infinity) as TableCategory[])
      .filter((category) => selectedKeys.includes(category.key) && category.isLeaf)
      .map((category) => category.key);
  }

  /**
   * Gets selected categories
   * @param tree
   * @param selectedKeys
   * @param result
   * @returns selected categories from tree
   */
  function getSelectedCategories(tree: TableCategory[], selectedKeys: string[], result: SelectedCategory[]) {
    for (let child of tree) {
      const isSelected = selectedKeys.includes(child.key);
      if (isSelected) {
        result.push({ id: child.code, key: child.key });
      } else {
        getSelectedCategories(child.children, selectedKeys, result);
      }
    }
    return result;
  }

  /**
   * Determines whether check on
   * @param selectedKeys
   */

  function onCheck(selectedKeys: any) {
    const keys = getSelectedKeys(categories, selectedKeys);
    const selectedCategories = getSelectedCategories(categories, selectedKeys, []);
    setSelectedCategories(selectedCategories.filter((el) => el.id !== "0" && el.key !== "0"));
    setSelectedKeys(keys.filter((el) => el !== "0"));
  }

  /**
   * Gets filtered ids
   * @returns  filterd category ids
   */
  function getFilteredIds() {
    return [
      ...new Set(
        (filteredNodes.map(mapCategories).flat(Infinity) as TableCategory[]).filter((c) => !c.isLeaf).map((c) => c.key),
      ),
    ];
  }

  /**
   * Sets keyword
   */
  function setKeyword() {
    if (debounce) {
      clearTimeout(debounce);
    }
    setDebounce(
      setTimeout(() => {
        filterNodes();
        setExpanded(["0", ...getFilteredIds()]);
      }, 100),
    );
  }

  /**
   * Keywords filter
   * @param category
   * @returns
   */
  function keywordFilter(category: TableCategory) {
    const matchesKeyword = (n: TableCategory) => n.title.toLowerCase().includes(searchedKeyword.toLowerCase());

    const mappedCategories = category.children.map(mapCategories).flat(Infinity) as TableCategory[];
    const childrenMatch = mappedCategories.some(matchesKeyword);

    return matchesKeyword(category) || childrenMatch;
  }

  /**
   * Filters nodes
   */
  function filterNodes() {
    setFilteredNodes([
      ...new Set((categories.map(mapCategories).flat(Infinity) as TableCategory[]).filter(keywordFilter)),
    ]);
  }

  /**
   * Handles number of selected segments
   * @param node
   */
  function setSelectedSegments({ props }: React.Component<TreeNodeProps>) {
    if (filteredNodes.length === 0) return;

    categories.forEach(({ title, key }) => {
      if (title !== props.title) return;
      if (props.checked || props.halfChecked) {
        selectedSegments.add(key);
      } else {
        selectedSegments.delete(key);
      }
    });
  }

  /**
   * Filters tree node
   * @param node
   * @returns
   */
  function filterTreeNode(node: React.Component<TreeNodeProps>) {
    setSelectedSegments(node);
    return filteredNodes.length === 0 || !!filteredNodes.find((category) => category.title === node.props.title);
  }

  useEffect(() => {
    setKeyword();
  }, [searchedKeyword]);

  useEffect(() => {
    if (selectedSegments.size > GPC_SELECT_SEGMENTS_LIMIT) {
      setSegmentsLimitReached(true);
    } else {
      setSegmentsLimitReached(false);
    }
  }, [selectedSegments]);

  return (
    // @ts-ignore it's because this version of tree require all params (event this default)
    <Tree
      showLine
      checkable
      showIcon={false}
      selectable={false}
      checkedKeys={selectedKeys}
      onExpand={(val: any) => setExpanded(val)}
      expandedKeys={expanded}
      multiple
      defaultExpandedKeys={["0"]}
      onCheck={onCheck}
      treeData={[{ key: "0", title: t("allCategories"), checkable: false, children: categories } as any]}
      filterTreeNode={filterTreeNode}
    />
  );
};

export default CategoriesTree;
