import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import * as CompanyThunk from "store/thunk/CompanyThunk";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";
import { CompanyData } from "store/actions/RegistrationActions";
import _ from "lodash";
import { getMembershipsFromCompany } from "store/thunk/AccountThunk";
import moment from "moment";
import ReactTable, { SortingRule } from "react-table";
import TablePagination from "components/tablePagination/TablePagination";
import * as AccountThunk from "store/thunk/AccountThunk";
import { State } from "interfaces/StoreInterface";
import ThComponent from "components/ThComponent/ThComponent";
import SwitchInput from "../../components/Inputs/SwitchInput";
import CompanyDetailsLabel from "../../components/CompanyDetailsLabel/CompanyDetailLabel";

interface SortBy {
  sort_column: string;
  direction: string;
}

const CompanyPage: React.FC = () => {
  const { t } = useTranslation();
  const { id = "" } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { setMessage } = useMessage();
  const [users, setUsers] = useState([]);
  const [sortBy, setSortBy] = useState<SortBy | null>(null);
  const [company, setCompany] = useState<CompanyData | undefined>(undefined);
  const [pageSize, setPageSize] = useState();
  const [allUsers, setAllUsers] = useState(0);
  const [accessToDatabase, setAccessToDatabase] = useState(company?.accessToDownload || false);
  const searchProps: { [key: string]: any } = queryString.parse(history.location.search);
  const [queryProps, setQueryProps] = useState(searchProps);
  const { activeMembership, user } = useSelector((state: State) => state.account);

  const defaultQueryProps = {
    page_size: 10,
    page: 0,
  };

  const CreateThComponent: React.FC<{ name: string }> = ({ name }) => {
    const showSortArrow = sortBy?.sort_column === name;
    const direction = sortBy?.direction === "ASC" ? "up" : "down";
    return (
      <ThComponent
        headerText={t(`company.users.table.header.${name}`)}
        name={name}
        sortProps={{ direction, showSortArrow }}
      />
    );
  };

  /**
   * Gets permission group
   * @param permissionGroup
   * @returns infomration about permissions
   */
  function getPermissionGroup(permissionGroup: "location_user" | "location_admin") {
    const permissions = {
      location_user: t("permission.location_user"),
      location_admin: t("permission.location_admin"),
    };
    return permissions[permissionGroup];
  }

  const deleteUser = async (membershipId: string) => {
    try {
      if (activeMembership && activeMembership.company.id) {
        await dispatch(AccountThunk.deleteMembershipById(activeMembership.id, membershipId));
        await getMembershipsByCompanyId(id);
      }
      setMessage(NPWSStatus.MEMBERSHIP_DELETED);
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  };

  /**
   * Gets full name
   * @param { firstName, lastName }
   * @returns
   */
  function getFullName({ firstName, lastName }: { firstName: string; lastName: string }) {
    return !firstName && !lastName ? "-" : `${firstName} ${lastName}`;
  }

  const columns = [
    {
      id: "user__first_name",
      Cell: (data: any) => {
        return (
          <>
            <span>{data.original.user.firstName}</span>
            <br />
            <span>{data.original.user.lastName}</span>
          </>
        );
      },
      headerClassName: "text-wrap box-shadow-none",
      Header: () => <CreateThComponent name="user__first_name" />,
      width: 180,
    },
    {
      id: "user__email",
      accessor: (data: any) => data.user.email || "-",
      headerClassName: "text-wrap box-shadow-none",
      Header: () => <CreateThComponent name="user__email" />,
    },
    {
      id: "user__date_joined",

      accessor: (data: any) => (data.activationDate ? moment(data.activationDate).format("DD-MM-YYYY") : "-"),
      headerClassName: "text-wrap box-shadow-none",
      Header: () => <CreateThComponent name="user__date_joined" />,
      width: 115,
    },
    {
      id: "user__last_login",
      accessor: (data: any) => (data.user.lastLogin ? moment(data.user.lastLogin).format("DD-MM-YYYY") : "-"),
      headerClassName: "text-wrap box-shadow-none",
      Header: () => <CreateThComponent name="user__last_login" />,
      width: 115,
    },
    {
      id: "permission_group",
      accessor: (data: any) => {
        let authStatus = data.isCompanyRepresentative ? t("companies.user.field.authorized") : "";
        return { permission: getPermissionGroup(data.permissionGroup), authStatus };
      },
      Cell: (data: any) => {
        return (
          <>
            <small>{data.value.permission}</small>
            <br />
            <small>{data.value.authStatus}</small>
          </>
        );
      },
      headerClassName: "text-wrap box-shadow-none",
      Header: () => <CreateThComponent name="permission_group" />,
      width: 175,
    },
    {
      id: "actions",
      accessor: (data: any) => data,
      sortable: false,
      Cell: (data: any) => {
        const isLoggedUser = data.value.user.email !== user?.email;
        return isLoggedUser ? (
          <button
            data-testid={`${data.value.user.email}-deleteButton`}
            className="btn btn-outline-secondary rounded-0 capitalized"
            onClick={() => deleteUser(data.value.id)}
          >
            {t("table.users.delete")}
          </button>
        ) : (
          <div></div>
        );
      },
      className: "button--delete",
      width: 145,
    },
  ];

  /**
   * Gets company by id
   * @param id
   */
  async function getCompanyById(id: string) {
    try {
      const { data }: any = await dispatch(CompanyThunk.getCompanyById(id));
      setCompany(data.data);
      setAccessToDatabase(data.data.accessToDownload);
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }

  /**
   * Gets memberships by company id
   * @param id
   */
  async function getMembershipsByCompanyId(id: string) {
    try {
      const searchParams = {
        ...defaultQueryProps,
        ...queryProps,
      };
      if (activeMembership) {
        const { data }: any = await dispatch(
          getMembershipsFromCompany(activeMembership, id, queryString.stringify(searchParams)),
        );
        if (!data.records.length && queryProps.page > 0) setQueryProps({ ...queryProps, page: 0 });
        setPageSize(data.records.length);
        setAllUsers(data.numAllRecords);
        setUsers(data.records);
      }
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }

  /**
   * Determines whether sort change on
   * @param data
   */
  function onSortChange(data: SortingRule[]) {
    let { id, desc } = data[0];
    setSortBy({ sort_column: id, direction: desc ? "DESC" : "ASC" });
    setQueryProps({ ...queryProps, sort_column: id, direction: desc ? "DESC" : "ASC" });
  }

  /**
   * Updates page state
   * @param page
   */
  async function updatePageState(page: number) {
    await setQueryProps({ ...queryProps, page });
  }

  /**
   * Updates search query params
   */
  function updateSearchQueryParams() {
    if (!_.isEmpty(queryProps)) {
      getMembershipsByCompanyId(id);
      if (queryProps.sort_column && queryProps.direction)
        setSortBy({ sort_column: queryProps.sort_column, direction: queryProps.direction });
      history.replace({
        pathname: `/companies/${id}`,
        search: queryString.stringify(queryProps),
      });
    } else {
      getMembershipsByCompanyId(id);
      setQueryProps(defaultQueryProps);
      history.replace({
        pathname: `/companies/${id}`,
        search: queryString.stringify(defaultQueryProps),
      });
    }
  }

  /**
   * Updates access to database
   * @param e
   */
  async function updateAccessToDatabase(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    await dispatch(CompanyThunk.updateCompanyAccessToDownload(company?.id, accessToDatabase));
    getCompanyById(id);
    setMessage(NPWSStatus.UPDATE_DATABASE_ACCESS_SUCCESS);
  }

  useEffect(() => {
    getCompanyById(id);
    updateSearchQueryParams();
  }, []);

  useEffect(() => {
    updateSearchQueryParams();
  }, [queryProps]);

  return company ? (
    <div className="">
      <div className="company-page bg-white">
        <CompanyDetailsLabel company={company} />
        <hr />
        <div className="row company-page__buttons">
          <form className="input-group mb-3" onSubmit={updateAccessToDatabase}>
            <div className={"col-6"}>
              <SwitchInput
                value={accessToDatabase}
                labelKey={"companies.table.headerName.access_to_download"}
                setValue={setAccessToDatabase}
              />
            </div>
            <div className="col-6 d-flex justify-content-end align-items-end">
              <button disabled={accessToDatabase === company?.accessToDownload} className="btn primary" type="submit">
                {t("save")}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="company-page__container mt-5">
        <div className="company-page users-table">
          <div className="row w-100 m-0">
            {users.length ? (
              <ReactTable
                manual={true}
                columns={columns}
                className="w-100"
                PaginationComponent={TablePagination}
                data={users}
                sortable={true}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                pages={_.ceil(allUsers / queryProps.page_size)}
                onSortedChange={onSortChange}
                page={parseInt(queryProps.page)}
                showPagination={true}
                onPageChange={updatePageState}
              />
            ) : (
              <h2 data-testid="noResults">{t("companies.users.table.noResults")}</h2>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default CompanyPage;
