import React, { useState, useEffect } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface AutocompleteSelectProps {
  options: string[];
  name: string;
  onSearch: (inputValue: string) => void;
  onChange: (value: string[]) => void;
  onInputChange?: (input: string, e: Event) => void;
  scrollLoad?: () => void;
  placeholder?: string;
  disabled?: boolean;
  maxResults?: number;
  labelText?: string;
  defVal?: string;
  customLabelClassName?: string;
}

const AutocompleteSelect: React.FC<AutocompleteSelectProps> = ({
  disabled = false,
  maxResults = 10,
  name,
  options,
  placeholder,
  onSearch,
  onChange,
  labelText,
  defVal,
  customLabelClassName,
  onInputChange = () => {},
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Fetchs data
   * @param inputValue
   */
  function fetchData(inputValue: string) {
    setIsLoading(true);
    onSearch(inputValue);
    setIsLoading(false);
  }

  return (
    <>
      <label
        className={classNames("font-weight-bold font-size-medium", customLabelClassName ? customLabelClassName : null)}>
        {labelText}
      </label>
      <AsyncTypeahead
        id={name}
        delay={0}
        useCache={false}
        options={options || []}
        emptyLabel={t("catalogPage.autocomplete.noResults")}
        searchText={t("catalogPage.autocomplete.search")}
        maxResults={maxResults}
        disabled={disabled}
        isLoading={isLoading}
        defaultInputValue={defVal}
        paginationText={t("catalogPage.search.moreResults")}
        placeholder={placeholder}
        onSearch={fetchData}
        onChange={onChange}
        onInputChange={onInputChange}
      />
    </>
  );
};

export default AutocompleteSelect;
