import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import useAuthentication from "hooks/useAuthentication";
import { Link, useHistory } from "react-router-dom";
import * as AccountThunk from "store/thunk/AccountThunk";
import { useDispatch } from "react-redux";
import { Dropdown, Modal } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import { Membership } from "interfaces/UserInterface";
import { setActiveMembership } from "store/actions/AccountActions";
import GS1APIClient from "clients/GS1APIClient";
import { haveAccess } from "utils/permissions";
import {
  REPOSITORY_ACCESS,
  REGISTERED_COMPANIES_ACCESS,
  CMS_ACCESS,
  USERS_MANAGE_ACCESS,
  USER_DATA,
} from "utils/constants";

const LENGTH_FOR_NAME: number = 65;
const LENGTH_FOR_HEADER: number = 30;

const Header: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { user, authenticated, activeMembership } = useAuthentication();

  /**
   * Redirects to
   * @param path
   */
  function redirectTo(path: string) {
    history.push(path);
  }

  /**
   * Hides menu
   */
  function hideMenu() {
    document.dispatchEvent(new MouseEvent("click"));
  }

  /**
   * Selects company
   * @param membership
   */
  function selectCompany(membership: Membership) {
    setShowModal(false);
    dispatch(setActiveMembership(membership));
    GS1APIClient.setActiveMembership(membership, user!.email);
    localStorage.setItem("activeId", membership.id);
    hideMenu();
  }

  function logout() {
    dispatch(AccountThunk.logout());
  }

  /**
   * Creates company name from partial data
   * @param name
   * @param limit
   * @returns company name
   */
  function companyName(name: string, limit: number) {
    if (name.length > limit) {
      return name.slice(0, limit) + "...";
    } else {
      return name;
    }
  }

  const companySwitch = () => {
    const activatedMemberships = user?.memberships.filter((membership) => {
      if (membership.activated) {
        return membership;
      }
    });

    let shouldShow = user && activatedMemberships!.length > 1;

    return (
      shouldShow && (
        <p className="txt-color-bluish-grey py-1 px-3 company">
          {activeMembership?.company?.name && `${companyName(activeMembership!.company!.name!, LENGTH_FOR_HEADER)} `}
          <span
            className="link"
            onClick={() => {
              hideMenu();
              setShowModal(true);
            }}>
            ({t("header.menu.switch")})
          </span>
        </p>
      )
    );
  };

  return (
    <div className="header__background">
      <div className="headerBox justify-content-end" style={{ backgroundColor: "#f0f5fa", padding: "0.5em" }}>
        <div className="align-items-center">
          <div className="container d-flex justify-content-end font-size-small" style={{ padding: "0" }}>
            <a className="link" href="https://bit.ly/4c2z2Ry">
              Zamów kod kreskowy EAN (numer GTIN)
            </a>
          </div>
        </div>
      </div>
      <header className="container header">
        <div className="row align-items-center">
          <div className="header__branding">
            <a title="eProdukty - Katalog produktów" href="/">
              <svg xmlns="http://www.w3.org/2000/svg" width="115.28" height="70" viewBox="0 0 115.28 70">
                <g transform="translate(2.5 1.301)">
                  <g transform="translate(68.296 22.727)">
                    <path
                      d="M73.222,40.008V37.791h-4.1V20.939H67.126l-5.432,2.217.553,2.439,4.214-1.663V37.791H61.8v2.217Z"
                      transform="translate(-61.694 -20.939)"
                      fill="#223368"></path>
                  </g>
                  <path
                    d="M68.035,33.839a32.929,32.929,0,1,1-32.928-32.7A32.813,32.813,0,0,1,68.035,33.839Z"
                    transform="translate(-2.179 -1.134)"
                    fill="#223368"></path>
                  <g transform="translate(31.153 22.84)">
                    <path
                      d="M37.865,21.037c-4.877,0-8.538,3.991-8.538,9.424v.221c0,5.654,3.439,9.424,8.426,9.424A10.29,10.29,0,0,0,43.962,38V29.905h-6.32v2h4.1v4.767l-.11.112a6.793,6.793,0,0,1-3.658,1.107c-3.66,0-5.988-2.883-5.988-7.316v-.11c0-4.1,2.44-7.207,5.877-7.207a5.868,5.868,0,0,1,4.213,1.551l1.442-1.883A8.7,8.7,0,0,0,37.865,21.037Z"
                      transform="translate(-29.327 -21.037)"
                      fill="#fff"></path>
                  </g>
                  <g transform="translate(49.336 22.728)">
                    <path
                      d="M45.172,37.569a9.535,9.535,0,0,0,6.43,2.439c3.548,0,5.874-2.107,5.874-5.211,0-2.549-1.329-3.991-4.987-5.32-3.326-1.331-4.213-2.106-4.213-3.658s1.331-2.661,3.215-2.661A7.043,7.043,0,0,1,55.7,24.82l1.444-1.883a8.313,8.313,0,0,0-5.545-2c-3.437,0-5.764,2.106-5.764,4.988,0,2.994,1.884,4.214,5.323,5.543,2.99,1.11,3.99,1.887,3.99,3.441,0,1.66-1.441,2.88-3.436,2.88a7.445,7.445,0,0,1-4.99-2.107Z"
                      transform="translate(-45.172 -20.94)"
                      fill="#fff"></path>
                  </g>
                  <g transform="translate(58.097 2.44)">
                    <path
                      d="M52.806,6.475h0a3.214,3.214,0,1,1,6.429,0h0a3.214,3.214,0,0,1-6.429,0Zm5.763,0h0a2.55,2.55,0,1,0-5.1,0h0a2.55,2.55,0,1,0,5.1,0ZM54.8,4.922h1.441c.666,0,1.219.333,1.219,1a1.064,1.064,0,0,1-.663,1l.773,1.109h-.885l-.663-1h-.444v1H54.8Zm1.441,1.442a.507.507,0,0,0,.555-.444.507.507,0,0,0-.555-.444h-.663v.887h.663Z"
                      transform="translate(-52.806 -3.26)"
                      fill="#223368"></path>
                  </g>
                  <g transform="translate(4.324 4.213)">
                    <g transform="translate(0)">
                      <path
                        d="M8.386,33.3A28.515,28.515,0,0,1,34.663,4.916V4.8h0a28.494,28.494,0,1,0-.111,56.987h0v-.11A28.587,28.587,0,0,1,8.386,33.3Z"
                        transform="translate(-5.947 -4.805)"
                        fill="#fff"></path>
                      <path
                        d="M13.961,32.741A24.191,24.191,0,0,1,35.8,8.684l-.11-.111a24.169,24.169,0,0,0,0,48.337l.11-.11A24.271,24.271,0,0,1,13.961,32.741Z"
                        transform="translate(-5.092 -4.249)"
                        fill="#fff"></path>
                      <path
                        d="M19.232,32.2A20.108,20.108,0,0,1,36.971,12.356l-.111-.111a19.961,19.961,0,0,0,0,39.911l.111-.112A20.107,20.107,0,0,1,19.232,32.2Z"
                        transform="translate(-4.265 -3.707)"
                        fill="#fff"></path>
                      <path
                        d="M24.3,31.644A15.6,15.6,0,0,1,38.154,16.124v-.111a15.637,15.637,0,0,0,0,31.266v-.114A15.78,15.78,0,0,1,24.3,31.644Z"
                        transform="translate(-3.452 -3.151)"
                        fill="#fff"></path>
                    </g>
                  </g>
                </g>
                <g transform="translate(70.817 56.402)">
                  <path
                    d="M61.712,49.515H65.7c2.326,0,3.734,1.378,3.734,3.373v.026c0,2.257-1.755,3.429-3.945,3.429H63.859V59.27H61.712Zm3.849,4.92a1.51,1.51,0,0,0,1.7-1.477v-.029c0-.962-.668-1.477-1.742-1.477H63.859v2.984h1.7Z"
                    transform="translate(-61.712 -49.096)"
                    fill="#f26433"></path>
                  <path
                    d="M69.134,55.3v-.029a3.924,3.924,0,0,1,4.057-3.889,3.883,3.883,0,0,1,4.028,3.861v.026a4.046,4.046,0,0,1-8.084.03Zm6,0v-.029a1.985,1.985,0,0,0-1.966-2.062,1.914,1.914,0,0,0-1.938,2.035v.026a1.986,1.986,0,0,0,1.966,2.064A1.914,1.914,0,0,0,75.13,55.3Z"
                    transform="translate(-60.617 -48.82)"
                    fill="#f26433"></path>
                  <path
                    d="M77.482,49.15H79.6V59.325H77.482Z"
                    transform="translate(-59.385 -49.15)"
                    fill="#f26433"></path>
                  <path
                    d="M80.433,58l.905-1.394a4.208,4.208,0,0,0,2.356.892c.614,0,.892-.221.892-.555v-.029c0-.459-.724-.612-1.547-.863-1.047-.308-2.23-.8-2.23-2.246V53.78a2.441,2.441,0,0,1,2.732-2.369,5.167,5.167,0,0,1,2.786.863l-.807,1.463a4.449,4.449,0,0,0-2.022-.7c-.515,0-.78.224-.78.515v.029c0,.417.71.614,1.519.892,1.044.348,2.257.849,2.257,2.217v.026c0,1.658-1.239,2.412-2.857,2.412A5.248,5.248,0,0,1,80.433,58Z"
                    transform="translate(-58.95 -48.816)"
                    fill="#f26433"></path>
                  <path
                    d="M86.919,49.15h2.118v5.422l2.481-2.716h2.537L91.213,54.8l2.94,4.529H91.7l-1.91-3.066-.753.793v2.272H86.918V49.15Z"
                    transform="translate(-57.993 -49.15)"
                    fill="#f26433"></path>
                  <path
                    d="M93.48,56.841v-.026c0-1.632,1.242-2.385,3.01-2.385a5.28,5.28,0,0,1,1.827.308v-.125c0-.879-.543-1.364-1.6-1.364a5.476,5.476,0,0,0-2.062.4l-.531-1.616a6.593,6.593,0,0,1,2.9-.6,3.447,3.447,0,0,1,2.521.837,3.258,3.258,0,0,1,.809,2.385v4.334H98.3v-.809a2.871,2.871,0,0,1-2.26.948A2.315,2.315,0,0,1,93.48,56.841Zm4.863-.489v-.374a3.274,3.274,0,0,0-1.352-.28c-.905,0-1.463.363-1.463,1.03v.029c0,.571.475.905,1.157.905C97.675,57.664,98.343,57.119,98.343,56.352Z"
                    transform="translate(-57.025 -48.813)"
                    fill="#f26433"></path>
                </g>
              </svg>
            </a>
            <div className="header__namespace">
              <div className="d-flex">
                <p className="header__title">{t("header.title.products_in_web")}</p>
              </div>
              <span className="header__subtitle">{t("header.title.catalog")}</span>
            </div>
          </div>
          <div className="col-6 container d-flex justify-content-end">
            {authenticated && user ? (
              <Dropdown>
                <DropdownToggle
                  id="dropdown-basic"
                  className="bg-transparent text-right border-0 px-3 py-2 txt-color-link my-2 my-md-0 btn btn-link p-0">
                  {`${user.firstName} ${user.lastName}`}
                  <p className="mb-0 font-size-small txt-color-gray font-weight-light">
                    {activeMembership?.company?.name &&
                      `${companyName(activeMembership!.company!.name!, LENGTH_FOR_HEADER)}`}
                  </p>
                </DropdownToggle>
                <DropdownMenu alignRight rootCloseEvent="click" className="p-1">
                  {haveAccess(activeMembership!, USER_DATA) && (
                    <Link
                      to={`/user_data/${user.id}`}
                      onClick={hideMenu}
                      className="txt-color-bluish-grey d-flex py-1 px-3">
                      {t("header.menu.myAccount")}
                    </Link>
                  )}
                  <Link
                    to={`/user_data/${user.id}/change_password`}
                    onClick={hideMenu}
                    className="txt-color-bluish-grey d-flex py-1 px-3">
                    {t("userData.changePassword")}
                  </Link>
                  {haveAccess(activeMembership!, USERS_MANAGE_ACCESS) && (
                    <Link to="/services" onClick={hideMenu} className="txt-color-bluish-grey d-flex py-1 px-3">
                      {t("header.menu.active_service")}
                    </Link>
                  )}
                  {haveAccess(activeMembership!, USERS_MANAGE_ACCESS) && (
                    <Link to="/users" onClick={hideMenu} className="txt-color-bluish-grey d-flex py-1 px-3">
                      {t("header.menu.usersList")}
                    </Link>
                  )}

                  {companySwitch()}

                  {haveAccess(activeMembership!, REPOSITORY_ACCESS) && (
                    <Link to="/repository" onClick={hideMenu} className="txt-color-bluish-grey d-flex py-1 px-3">
                      {t("header.menu.repository")}
                    </Link>
                  )}
                  {haveAccess(activeMembership!, REGISTERED_COMPANIES_ACCESS) && (
                    <Link to="/companies" onClick={hideMenu} className="txt-color-bluish-grey d-flex py-1 px-3">
                      {t("header.menu.administration")}
                    </Link>
                  )}
                  {/* {haveAccess(activeMembership!, CMS_ACCESS) && (
                    <Link to={"/cms"} onClick={hideMenu} className="txt-color-bluish-grey d-flex py-1 px-3">
                      {t("header.menu.cms")}
                    </Link>
                  )} */}
                  <hr className="mx-0" />
                  <Link to={"/"} onClick={() => logout()} className="txt-color-bluish-grey d-flex py-1 px-3">
                    {t("header.title.logout")}
                  </Link>
                </DropdownMenu>
              </Dropdown>
            ) : !authenticated ? (
              <div className="row justify-content-end">
                <button onClick={() => redirectTo("/login")} className="button__login">
                  {t("header.title.button.login")}
                </button>
                <button onClick={() => redirectTo("/registration")} className="button__registration">
                  {t("header.title.button.register")}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </header>
      <Modal
        restoreFocus={false}
        enforceFocus={false}
        autoFocus={false}
        show={showModal}
        animation={false}
        onHide={() => setShowModal(false)}
        size="lg"
        className="choose-company__modal"
        backdrop={true}
        backdropClassName="modal__background-white"
        centered
        style={{ overflow: "hidden" }}>
        <Modal.Header className={"border-0"} closeButton>
          <Modal.Title className="txt-marine-blue font-size-base font-weight-bold">
            {t("header.modal.chooseCompany")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <hr className="mt-0 mb-3 w-100" />
          {user && user.memberships.length > 1 && (
            <>
              {user.memberships.map(
                (membership: any) =>
                  membership.activated && (
                    <Link
                      to={"#"}
                      key={membership.id}
                      onClick={() => selectCompany(membership)}
                      className="txt-color-bluish-grey d-flex py-1 px-3 choose-company__link">
                      {companyName(membership.company.name, LENGTH_FOR_NAME)}
                    </Link>
                  ),
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;
