import React from "react";
import { useTranslation } from "react-i18next";

const ContactPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h5 className="mt-5 mb-4 font-weight-bold">{t("contact")}</h5>
      <span className="d-block">
        <span className="fas fa-envelope mr-3" aria-hidden="true" title={t("contact.email")} />
        <span className="sr-only"> {t("contact.email")}</span>
        <a className="txt-color-primary" href={`mailto:${t("contact.emailAddress")}`}>
          {t("contact.emailAddress")}
        </a>
      </span>
      <div className="mt-4">
        <span className="d-block">{t("gs1.poland")}</span>
        <span className="d-block">ul. abpa Antoniego Baraniaka 88B, budynek C</span>
        <span className="d-block">61-131 Poznań</span>
        <span className="d-block">{t("contact.country")}</span>
      </div>
      <h5 className="mt-5 mb-4 font-weight-bold">{t("contact.gdpr")}</h5>
      <span className="d-block">
        <span className="fas fa-envelope mr-3" aria-hidden="true" title={t("contact.gdpr.emailAddress")} />
        <span className="sr-only"> {t("contact.gdpr.emailAddress")}</span>
        <a className="txt-color-primary" href={`mailto:${t("contact.gdpr.emailAddress")}`}>
          {t("contact.gdpr.emailAddress")}
        </a>
      </span>
    </>
  );
};

export default ContactPage;
