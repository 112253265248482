import { useDispatch, useSelector } from "react-redux";
import * as UiActions from "store/actions/UiActions";
import { NPWSStatus } from "utils/statuses";

const useMessage = () => {
  const dispatch = useDispatch();
  const { statusMessage } = useSelector((state: any) => state.ui);

  /**
   * Shows spinner
   * @param shouldShowSpinner
   */
  function showSpinner(shouldShowSpinner: boolean) {
    dispatch(UiActions.setPendingStatus(shouldShowSpinner));
  }

  /**
   * Sets message
   * @param { type, message, duration }
   */
  function setMessage({ type, message, duration }: NPWSStatus) {
    dispatch(UiActions.setMessage(type, message, duration));
  }

  /**
   * Removes message
   */
  function removeMessage() {
    dispatch(UiActions.removeMessage());
  }

  /**
   * return message data if any
   * @returns
   */
  function messageData() {
    return statusMessage;
  }

  return { setMessage, removeMessage, messageData, showSpinner };
};

export default useMessage;
