import i18next from "i18next";
import { regexps } from "utils/constants";
import * as yup from "yup";

/**
 * Equals to
 * @param ref
 * @param msg
 * @returns
 */
function equalTo(ref: any, msg: any) {
  return yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg || "${path} must be the same as ${reference}",
    params: {
      reference: ref.path,
    },
    test: function (value: any) {
      return value === this.resolve(ref);
    },
  });
}
yup.addMethod(yup.string, "equalTo", equalTo);

const generateSetPasswordPageSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    password: yup
      .string()
      .required(t("form.error.required"))
      .matches(regexps.PASSWORD_REGEX, {
        excludeEmptyString: true,
        message: `${t("reset.password.wrongPassword")}`,
      }),
    passwordRepeat: yup
      .string()
      .required(t("form.error.required"))
      .matches(regexps.PASSWORD_REGEX, { excludeEmptyString: true, message: t("form.login.error.passwordFormat") })
      .oneOf([yup.ref("password"), null], t("set.password.diff.passwords")),
  });

export default generateSetPasswordPageSchema;
