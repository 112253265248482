export const UPDATE_ACTIVATION_USER_DATA = "activation.updateActivationUserData";
export const EMAIL_CHECKED = "activation.emailChecked";
export const RESET_FORM = "activation.resetActivationForm";
export const DISABLE_PERSONAL_FORM = "activation.disablePersonalForm";
export const UPDATE_HAS_USER_ACTIVATED_MEMBERSHIP = "activation.updateHasUserActivatedMembership";

export interface ActivationUserData {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    isAdmin: boolean;
    confirmedStatute: boolean;
    agreeToSellingEmails: string;
    agreeToSellingPhones: string;
    agreeToSharingData: string;
    agreeToMarketingEmails: string;
    agreeToMarketingPhones: string;
  };
  isCompanyRepresentative: boolean;
  file: any | null;
}

export interface UpdateActivationUserData {
  type: typeof UPDATE_ACTIVATION_USER_DATA;
  payload: ActivationUserData;
}

export interface EmailChecked {
  type: typeof EMAIL_CHECKED;
}

export interface ResetForm {
  type: typeof RESET_FORM;
}

export interface DisablePersonalDataForm {
  type: typeof DISABLE_PERSONAL_FORM;
  payload: boolean;
}

export interface UpdateHasUserActivatedMembership {
  type: typeof UPDATE_HAS_USER_ACTIVATED_MEMBERSHIP;
  payload: boolean;
}

/**
 * Updates activation user data
 * @param userData
 * @returns
 */
export function updateActivationUserData(userData: ActivationUserData) {
  return {
    type: UPDATE_ACTIVATION_USER_DATA,
    payload: userData,
  };
}

/**
 * Sets email checked
 * @returns
 */
export function setEmailChecked() {
  return {
    type: EMAIL_CHECKED,
  };
}

/**
 * Resets form
 * @returns
 */
export function resetForm() {
  return {
    type: RESET_FORM,
  };
}

/**
 * Disables personal data form
 * @param shouldDisable
 * @returns
 */
export function disablePersonalDataForm(shouldDisable: boolean) {
  return {
    type: DISABLE_PERSONAL_FORM,
    payload: shouldDisable,
  };
}

/**
 * Updates has user activated membership
 * @param hasUserActivatedMembership
 * @returns
 */
export function updateHasUserActivatedMembership(hasUserActivatedMembership: boolean) {
  return {
    type: UPDATE_HAS_USER_ACTIVATED_MEMBERSHIP,
    payload: hasUserActivatedMembership,
  };
}

export type ActivationAction =
  | UpdateActivationUserData
  | EmailChecked
  | ResetForm
  | DisablePersonalDataForm
  | UpdateHasUserActivatedMembership;
