import React, { useEffect } from "react";
import Input from "components/Inputs/Input";
import MaskedInput from "components/Inputs/MaskedInput";
import { useTranslation } from "react-i18next";
import PersonalDataLegals from "components/Register/PersonalData/PersonalDataLegals";
import Agreements from "components/Agreements/Agreements";
import { useSelector } from "react-redux";
import { AppState } from "store/reducers/RootReducer";
import ActivateAccountFormProps from "interfaces/ActivationInterface";
import InputIcon from "../../Inputs/InputIcon";
import NPWSTooltips from "../../Tooltip/TooltipValues";
import classNames from "classnames";

const FullActivateAccountForm: React.FC<ActivateAccountFormProps> = ({ formSubmit, form, activationState }) => {
  const { t } = useTranslation();
  const { personal, disablePersonalForm, hasUserActivatedMembership } = useSelector(
    (state: AppState) => state.activationForm,
  );

  const { register, setValue } = form;

  useEffect(() => {
    Object.entries(activationState).map(([key, value]) => {
      const agreementKeys = [
        "agreeToSellingEmails",
        "agreeToSellingPhones",
        "agreeToSharingData",
        "agreeToMarketingEmails",
        "agreeToMarketingPhones",
      ];
      form.setValue(`user.${key}`, agreementKeys.includes(key) ? `${value}` : value);
    });
  }, [activationState]);

  form.setValue("user.confirmedStatute", false);

  return (
    <form
      id="fullActivationForm"
      onSubmit={(e) => {
        e.preventDefault();
        formSubmit(e);
      }}>
      <div className="form-row d-flex justify-content-between">
        <div className="col-6 pr-3">
          <Input
            labelText={t("registration.form.personal.user.email")}
            name="user.email"
            tabIndex={1}
            error={form.errors.user && form.errors.user.email}
            value={activationState.email}
            reference={form.register}
            autocomplete="username"
            disabled={true}
          />{" "}
          {/* This input is here only to show the user email regarding which activation takes place */}
          <div className={classNames({ "secure-field": disablePersonalForm })}>
            <Input
              labelText={t("registration.form.personal.user.firstName")}
              name="user.firstName"
              disabled={disablePersonalForm}
              tabIndex={3}
              placeholder=" "
              error={form.errors.user && form.errors.user.firstName}
              reference={form.register}
              value={personal.user.firstName}
            />
          </div>
          {hasUserActivatedMembership ? null : (
            <Input
              labelText={t("password")}
              name="password"
              type="password"
              tabIndex={5}
              data-testid="password"
              error={form.errors.password}
              reference={form.register}
              autocomplete="new-password"
              inputIcon={
                <InputIcon
                  tooltipText={NPWSTooltips.PasswordRequirments}
                  show={true}
                  src="/icons/icon-help-enabled.svg"
                />
              }
            />
          )}
        </div>
        <div className="col-6 pl-3">
          <div className={classNames({ "secure-field": disablePersonalForm })}>
            <MaskedInput
              labelText={t("registration.form.personal.user.phoneNumber")}
              name="user.phoneNumber"
              disabled={disablePersonalForm}
              error={form.errors.user && form.errors.user.phoneNumber}
              mask="+00 000-000-000"
              formHooks={{ register, setValue }}
              tabIndex={2}
              defaultValue={"+48"}
              reference={form.register}
            />
          </div>
          <div className={classNames({ "secure-field": disablePersonalForm })}>
            <Input
              labelText={t("registration.form.personal.user.lastName")}
              name="user.lastName"
              error={form.errors.user && form.errors.user.lastName}
              tabIndex={4}
              placeholder=" "
              disabled={disablePersonalForm}
              reference={form.register}
              value={personal.user.lastName}
            />
          </div>

          {hasUserActivatedMembership ? null : (
            <Input
              labelText={t("password.repeat")}
              name="passwordRepeat"
              type="password"
              tabIndex={6}
              data-testid="passwordRepeat"
              error={form.errors.passwordRepeat}
              reference={form.register}
              autocomplete="new-password"
            />
          )}
        </div>
        <div className="col-12">
          <Agreements form={form} isAdmin={personal.user.isAdmin} startingTabIndex={7} changePermissionsInfo />
          <PersonalDataLegals />
          <div className={`form-group d-flex justify-content-end`}>
            <button form="fullActivationForm" data-testid="activeSubmit" className="btn primary" type="submit">
              {t("activate")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FullActivateAccountForm;
