import GS1APIClient from "clients/GS1APIClient";
import { GenerateDatabaseQueryParams } from "store/thunk/CatalogThunk";
import { Membership } from "../interfaces/UserInterface";

/**
 * Searchs product base
 * @param [id]
 * @returns
 */
function gtins_normalizer(gtin: string) {
  while (gtin.length < 14) {
    gtin = "0" + gtin;
  }
  return gtin;
}
function searchProductBase(id: string = "") {
  return GS1APIClient.request<any>({
    url: `/products/get_products?gtin_number=${gtins_normalizer(id)}`,
    method: "GET",
  });
}

/**
 * Searchs product advance
 * @param activeMembership
 * @param query
 * @returns
 */
function searchProductAdvance(activeMembership: Membership | null, query: string) {
  return GS1APIClient.privateRequest<any>({
    url: `/products/${activeMembership?.id}/get_products_advanced/?${query}`,
    method: "GET",
  });
}

/**
 * Searchs products autocomplete
 * @param activeMembership
 * @param query
 * @returns
 */
function searchProductsAutocomplete(activeMembership: Membership, query: string) {
  return GS1APIClient.privateRequest<any>({
    url: `/products/${activeMembership.id}/get_products_advanced/suggest/?${query}`,
    method: "GET",
  });
}

/**
 * Searchs companies autocomplete
 * @param activeMembership
 * @param query
 * @returns
 */
function searchCompaniesAutocomplete(activeMembership: Membership, query: string) {
  return GS1APIClient.privateRequest<any>({
    url: `/companies/${activeMembership.id}/get_companies/suggest/?${query}`,
    method: "GET",
  });
}

/**
 * Searchs products
 * @param [query]
 * @returns
 */
function searchProducts(query: string = "") {
  return GS1APIClient.request<any>({
    url: `/products/get_products/?${query ? query : ""}`,
    method: "GET",
  });
}

/**
 * Downloads catalog file
 * @param activeMembership
 * @param fileId
 * @returns
 */
function downloadCatalogFile(activeMembership: Membership, fileId: string) {
  return GS1APIClient.privateRequest<any>({
    url: `/products/${activeMembership.id}/database/${fileId}/`,
    responseType: "blob",
  });
}

/**
 * Gets product by id
 * @param id
 * @returns
 */
function getProductById(id: string) {
  return GS1APIClient.request<any>({
    url: `/products/get_products/${gtins_normalizer(id)}/`,
    method: "GET",
  });
}

/**
 * Generates database
 * @param activeMembership
 * @param { categories, company_name, brand, nip, product_name, gtin }
 * @returns
 */
function generateDatabase(
  activeMembership: Membership,
  { categories, company_name, brand, nip, product_name, gtin }: GenerateDatabaseQueryParams,
) {
  return GS1APIClient.privateRequest({
    url: `/products/${activeMembership.id}/database/`,
    method: "POST",
    data: {
      categories,
      gtin,
      productName: product_name,
      companyName: company_name,
      brand,
      nip,
    },
  });
}

/**
 * Downloads catalog file
 * @param activeMembership
 * @returns
 */
function downloadZippedCsvFile(activeMembership: Membership) {
  return GS1APIClient.privateRequest<any>({
    url: `/products/${activeMembership.id}/zip_database/`,
    method: "GET",
  });
}

const CatalogService = {
  searchProducts,
  downloadCatalogFile,
  getProductById,
  generateDatabase,
  searchProductBase,
  searchProductAdvance,
  searchProductsAutocomplete,
  searchCompaniesAutocomplete,
  downloadZippedCsvFile,
};

export default CatalogService;
