import React from "react";
import PrivateRoute from "./PrivateRoute";
import { Switch, Redirect } from "react-router-dom";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import CatalogPage from "pages/CatalogPage/CatalogPage";
import LoginPage from "pages/LoginPage/LoginPage";
import ResetPasswordPage from "pages/ResetPasswordPage/ResetPasswordPage";
import SetPasswordPage from "pages/SetPassword/SetPasswordPage";
import ActivateAccountPage from "pages/ActivateAccountPage/ActivateAccountPage";
import RegistrationPage from "pages/RegistrationPage/RegistrationPage";
import UsersListPage from "pages/UsersListPage/UsersListPage";
import SubscriptionsListPage from "pages/SubscriptionsListPage/SubscriptionsListPage";
import ProductPage from "pages/ProductPage/ProductPage";
import Navbar from "components/Navbar/Navbar";
import VerifyDataPage from "pages/VerifyDataPage/VerifyDataPage";
import AboutUs from "pages/AboutUs/AboutUs";
import Generator2d from "pages/Generator2d/Generator2d";
import MyProductsPage from "pages/MyProductsPage/MyProductsPage";
import UploadDataPage from "pages/UploadDataPage/UploadDataPage";
import CookiesPage from "pages/CookiesPage/CookiesPage";
import CatalogDownloadPage from "pages/DownloadPage/CatalogDownloadPage";

import HomePageContainer from "pages/HomePage/HomePage";
import HowItWorks from "pages/HomePage/HowItWorks";
import HomePageCMS from "cms/HomePageCMS/HomePageCMS";
import StatusMessage from "components/StatusMesssage/StatusMessage";
import useMessage from "hooks/useMessageStatus";

import HowItWorksCMS from "cms/HowItWorks/HowItWorksCMS";
import VerifyDataPageCMS from "cms/VerifyDataPageCMS/VerifyDataPageCMS";
import CompaniesListPage from "pages/CompaniesListPage/CompaniesListPage";
import CompanyPage from "pages/CompanyPage/CompanyPage";

import {
  CMS_ACCESS,
  DATABASE_DOWNLOAD_ACCESS,
  FILE_SENDING_TO_VERIFICATION_ACCESS,
  REGISTERED_COMPANIES_ACCESS,
  REPOSITORY_ACCESS,
  USERS_MANAGE_ACCESS,
  USER_DATA,
  CHANGE_PASSWORD,
} from "utils/constants";
import PublicRoute from "./PublicRoute";
import RepositoryPage from "pages/RepositoryPage/RepositoryPage";
import UserData from "pages/UserData/UserData";
import DownloadIssueFilePage from "../../pages/RepositoryPage/DownloadIssueFile";
import CMSPage from "pages/CMSPage/CMSPage";
import ChangePasswordPage from "pages/ChangePasswordPage/ChangePasswordPage";
import PrivacyPolicyPage from "../../pages/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsPage from "../../pages/TermsPage/TermsPage";
import ContactPage from "../../pages/ContactPage/ContactPage";
import SubscriptionsPage from "../../pages/SubscriptionsPage/SubscriptionsPage";

export const Routes: React.FC = () => {
  const { removeMessage, messageData } = useMessage();
  return (
    <>
      <Header />
      <Navbar />
      {!!messageData() && <StatusMessage {...messageData()} onClose={removeMessage} />}
      <div className="container main-view">
        <Switch>
          <PublicRoute exact={true} path="/catalog/:id" component={ProductPage} />
          <PublicRoute exact={true} path="/catalog" component={CatalogPage} />
          <PrivateRoute
            exact={true}
            path="/products/database/:id"
            component={CatalogDownloadPage}
            permission={DATABASE_DOWNLOAD_ACCESS}
          />
          <PublicRoute exact={true} path="/login" component={LoginPage} />
          {/*TODO: Fix the access to /login while logged in*/}
          <PublicRoute exact={true} path="/reset_password/:id" component={SetPasswordPage} />
          <PublicRoute exact={true} path="/reset_password" component={ResetPasswordPage} />
          <PublicRoute exact={true} path="/confirm/email/:activationId" component={ActivateAccountPage} />
          <PublicRoute exact={true} path="/registration" component={RegistrationPage} />
          <PublicRoute exact={true} path="/cookies" component={CookiesPage} />
          <PublicRoute exact={true} path="/terms" component={TermsPage} />
          <PublicRoute exact={true} path="/privacy" component={PrivacyPolicyPage} />
          <PublicRoute exact={true} path="/contact" component={ContactPage} />
          <PrivateRoute exact={true} path="/users" component={UsersListPage} permission={USERS_MANAGE_ACCESS} />
          <PrivateRoute
            exact={true}
            path="/services"
            component={SubscriptionsListPage}
            permission={USERS_MANAGE_ACCESS}
          />
          <PrivateRoute
            exact={true}
            path="/verify_data/upload"
            component={UploadDataPage}
            permission={FILE_SENDING_TO_VERIFICATION_ACCESS}
          />
          <PublicRoute exact={true} path="/verify_data" component={VerifyDataPage} />
          <PrivateRoute exact={true} path="/generator_2d" component={Generator2d} permission={FILE_SENDING_TO_VERIFICATION_ACCESS} />
          <PrivateRoute exact={true} path="/verify_data_cms" component={VerifyDataPageCMS} permission={CMS_ACCESS} />

          <PublicRoute exact={true} path="/how_it_works" component={HowItWorks} />
          <PrivateRoute exact={true} path="/how_it_works_cms" component={HowItWorksCMS} permission={CMS_ACCESS} />
          <PublicRoute exact={true} path="/about_us" component={AboutUs} />
          <PublicRoute exact={true} path="/pricing" component={SubscriptionsPage} />
          <PrivateRoute
            exact={true}
            path="/my_products"
            component={MyProductsPage}
            permission={FILE_SENDING_TO_VERIFICATION_ACCESS}
          />
          <PrivateRoute exact={true} path="/cms" component={CMSPage} permission={CMS_ACCESS} />
          <PrivateRoute exact={true} path="/homepage_cms" component={HomePageCMS} permission={CMS_ACCESS} />
          <PublicRoute exact={true} path="/" component={HomePageContainer} />
          <PrivateRoute
            exact={true}
            path="/companies/:id"
            component={CompanyPage}
            permission={REGISTERED_COMPANIES_ACCESS}
          />
          <PrivateRoute
            exact={true}
            path="/companies"
            component={CompaniesListPage}
            permission={REGISTERED_COMPANIES_ACCESS}
          />
          <PrivateRoute
            exact={true}
            path="/repository/issue_file/:id"
            component={DownloadIssueFilePage}
            permission={REPOSITORY_ACCESS}
          />
          <PrivateRoute exact={true} path="/repository" component={RepositoryPage} permission={REPOSITORY_ACCESS} />
          <PrivateRoute
            exact={true}
            path="/user_data/:id/change_password"
            component={ChangePasswordPage}
            permission={CHANGE_PASSWORD}
          />
          <PrivateRoute exact={true} path="/user_data/:id" component={UserData} permission={USER_DATA} />
          <Redirect to={"/"} />
        </Switch>
      </div>
      <Footer />
    </>
  );
};
export default Routes;
