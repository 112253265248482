import React from "react";
import Arrow from "components/Arrow/Arrow";

interface ThComponentProps {
  name: string;
  headerText: string;
  sortProps: {
    direction: "up" | "down" | undefined;
    showSortArrow: boolean;
  };
}

const ThComponent: React.FC<ThComponentProps> = ({ sortProps, headerText }) => {
  const { showSortArrow, direction } = sortProps;
  return (
    <div className="row d-flex align-items-center h-100">
      <div className="col-9">{headerText}</div>{" "}
      <div className="col-3 p-0">
        <Arrow direction={showSortArrow ? direction : undefined} />
      </div>
    </div>
  );
};

export default ThComponent;
