import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

interface InputIconProps {
  tooltipText?: any;
  placement?: "bottom" | "left" | "top" | "right";
}

/**
 * Renders tooltip
 * @param { tooltipText, ...props }
 * @returns
 */
function RenderTooltip({ tooltipText, ...props }: any) {
  return <Tooltip {...props}> {typeof tooltipText === "function" ? tooltipText() : tooltipText}</Tooltip>;
}

const TooltipWrapper: React.FC<InputIconProps> = ({ placement = "bottom", tooltipText = "", children }) => {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={(props: any) => RenderTooltip({ ...props, tooltipText })}
    >
      {children}
    </OverlayTrigger>
  );
};

export default TooltipWrapper;
