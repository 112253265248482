import React, { useEffect, useState } from "react";
import { WithWizard, Wizard } from "react-albus";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Input from "components/Inputs/Input";
import useMessage from "hooks/useMessageStatus";
import { checkCompanyHaveUserRegistered, getCompanyDetailsByRegon } from "store/thunk/RegistrationThunk";
import NPWSStatus from "utils/statuses";
import * as RegistrationActions from "../../../store/actions/RegistrationActions";
import { generateRegonSchema } from "./RegonDataValidation";

interface RegonDataProps {
  nextStep: () => void;
  formData: any;
}

const RegonData: React.FC<RegonDataProps> = ({ nextStep, formData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setMessage } = useMessage();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { regonChecked } = useSelector((state: any) => state.registrationForm);
  const { handleSubmit, register, errors, triggerValidation, getValues, setValue, watch } = useForm({
    validationSchema: generateRegonSchema(t),
    defaultValues: {
      regon: "",
    },
  });

  /**
   * onSubmit callback fn to save data to redux state
   * @param data
   */
  function onSubmit(data: any) {
    checkRegon();
    dispatch(RegistrationActions.updateRegistrationCompanyData(data));
    dispatch(RegistrationActions.disableCompanyDataForm(false));
  }

  /**
   * Checks if the company registering is ordinary
   * @param regon
   */
  async function ordinaryEntity(regon: string) {
    dispatch(RegistrationActions.disableCompanyDataForm(false));
    dispatch(RegistrationActions.updateRegistrationCompanyData({ regon }));
    await dispatch(RegistrationActions.setRegonChecked(true));
    nextStep();
  }

  /**
   * Checks if the company registering is priviledged (existed before)
   * @param data
   */
  async function authorizedEntity(data: any) {
    dispatch(RegistrationActions.disableCompanyDataForm(true));
    dispatch(RegistrationActions.updateRegistrationCompanyData(data));
    await dispatch(RegistrationActions.setRegonChecked(true));
    nextStep();
  }

  /**
   * Checks regon number for errors and validates it
   * @returns
   */
  async function checkRegon() {
    const isValid = await triggerValidation("regon", false);
    if (isValid) {
      const { regon }: any = getValues({ nest: true });
      try {
        const data: any = await dispatch(getCompanyDetailsByRegon(regon));
        if (!data.hasActivatedMembership) {
          setMessage(NPWSStatus.UNFINISHED_COMPANY_REGISTRATION);
          return;
        }
        try {
          await dispatch(checkCompanyHaveUserRegistered(regon));
          setMessage(NPWSStatus.REGISTRATION_COMPANY_EXISTS);
          setButtonDisabled(true);
        } catch (err) {
          authorizedEntity(data);
        }
      } catch (err) {
        ordinaryEntity(regon);
      }
    }
  }

  useEffect(() => {
    const { regon } = formData;
    setValue("regon", regon);
    if (regonChecked) setButtonDisabled(false);
  }, [formData]);

  return (
    <Wizard>
      <WithWizard
        render={() => (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="regon-form">
              <div className="col-12 mb-3">
                <h2>{t("form.steps.companyData")}</h2>
              </div>
              <div className="col-6 pr-3">
                <Input
                  labelText={t("registration.form.companyData.regon")}
                  name="regon"
                  error={errors.regon}
                  placeholder=" "
                  handleChange={async (e) => {
                    if (await triggerValidation("regon", false)) {
                      setButtonDisabled(false);
                    } else {
                      setButtonDisabled(true);
                    }
                  }}
                  reference={register}
                />
                <button
                  data-testid="next"
                  type="submit"
                  className={"btn primary margin-1-b mt-2 float-right px-5 capitalized"}>
                  {t("next")}
                </button>
              </div>
              <div className="col-6 pl-4 regon-form__description">
                <p>
                  UWAGA - jeśli potrzebujesz zamówić kody EAN przejdź bezpośrednio do formularza rejestracyjnego: <br />
                  <a href="https://bit.ly/4c2z2Ry">https://gs1pl.org/zamow-kod-kreskowy/</a>
                </p>
                
                <p className="m-0">Nie posiadasz numeru REGON?<br/>{t("registration.form.regon.contact")}</p>
                <p>
                  <a className="link" href={`mailto:${t("contact.emailAddress")}`}>
                    {t("contact.emailAddress")}
                  </a>
                </p>
              </div>
            </div>
          </form>
        )}></WithWizard>
    </Wizard>
  );
};

export default RegonData;
