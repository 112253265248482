import React, { ChangeEvent, Dispatch } from "react";
import { useTranslation } from "react-i18next";
interface SearchInputTypes {
  onSearch: () => void;
  inputValue: string;
  setSearchValue: Dispatch<any>;
}

const SearchInput: React.FC<SearchInputTypes> = ({ onSearch = () => {}, inputValue, setSearchValue }) => {
  const { t } = useTranslation();
  /**
   * Sets input value
   * @param { target: { value } }
   */
  function setInputValue({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    setSearchValue(value);
  }

  /**
   * onSubmit callback for search button
   * @param e
   */
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    onSearch();
  }
  return (
    <form className="input-group mb-3" onSubmit={onSubmit}>
      <input
        type="text"
        onChange={setInputValue}
        value={inputValue}
        className="form-control"
        data-testid="searchInput"
        placeholder={t("search")}
      />
      <div className="input-group-append">
        <button data-testid="searchButton" className="btn primary" type="submit">
          {t("search")}
        </button>
      </div>
    </form>
  );
};

export default SearchInput;
