import { ThunkResult } from "interfaces/StoreInterface";
import RegistrationService from "services/RegistrationService";

/**
 * Gets company details by regon
 * @param regon
 * @returns company details by regon
 */
export function getCompanyDetailsByRegon(regon: string): ThunkResult<Promise<any>> {
  return async (dispatch: any) => {
    try {
      const { data } = await RegistrationService.getCompanyByRegon(regon);
      return { ...data.data, ...data };
    } catch (err) {
      throw new Error(err);
    }
  };
}

/**
 * Determines whether nip is taken
 * @param nip
 * @returns nip taken
 */
export function isNipTaken(nip: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    try {
      const { data } = await RegistrationService.getCompanyByNip(nip);
      return true; // if fetched data it means the company is already in DB
    } catch (err) {
      return false; // we expect 404 if no company of certain nip found
    }
  };
}

/**
 * Gets user details by email
 * @param email
 * @returns user details by email
 */
export function getUserDetailsByEmail(email: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    try {
      const { data } = await RegistrationService.getUserByEmail(email);
      return data;
    } catch (err) {}
  };
}

/**
 * Posts registration form data
 * @param formData
 * @returns
 */
export function postRegistrationFormData(formData: any) {
  return async (dispatch: any) => {
    try {
      return await RegistrationService.postRegistrationFormData(formData);
    } catch (err) {
      const status = err.response.status;
      throw new Error(`error.${status}`);
    }
  };
}

/**
 * Posts registration form attachment
 * @param file
 * @param member_id
 * @returns
 */
export function postRegistrationFormAttachment(file: File, member_id: string) {
  return async (dispatch: any) => {
    try {
      await RegistrationService.attachRegisterFile(file, member_id);
    } catch (err) {
      throw new Error(err);
    }
  };
}

/**
 * Checks is user is assigned to company
 * @param regon
 * @param email
 * @returns
 */
export function checkIsUserIsAssignedToCompany(regon: string, email: string) {
  return async (dispatch: any) => {
    try {
      await RegistrationService.checkIsUserIsAssignedToCompany(regon, email);
    } catch (err) {
      throw new Error("registration.form.personal.error.userIsNotAssignToCompany");
    }
  };
}

/**
 * Checks company have user registered
 * @param regon
 * @returns
 */
export function checkCompanyHaveUserRegistered(regon: string) {
  return async (dispatch: any) => {
    try {
      await RegistrationService.companyHaveUserRegistered(regon);
    } catch (err) {
      throw Error(err);
    }
  };
}
