import React, { useState, useEffect } from "react";
import { FieldError, NestDataObject } from "react-hook-form";
import classNames from "classnames";

interface FileInputProps {
  changeHandler: (value: { [key: string]: any }) => void;
  name?: string;
  value: any;
  className?: string;
  accept?: string;
  initFileName?: string;
  fileName?: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  labelText: string;
  disabled?: boolean;
  error?: FieldError | FieldError[] | NestDataObject<any> | NestDataObject<any>[];
  reference?: (ref: Element | null) => void;
  tabIndex?: number;
  labelStyle?: object;
}

const FileInput: React.FC<FileInputProps> = ({
  name = "file",
  value = "",
  className = "",
  changeHandler,
  accept = "image/*, .pdf",
  labelText = "",
  fileName = "",
  labelStyle = {},
  setFileName,
  disabled = false,
  error = undefined,
  reference = undefined,
  tabIndex = 1,
}) => {
  const onChangeHandler = (e: any) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      setFileName(file.name);
      changeHandler({ [name]: file });
    } else {
      changeHandler({ [name]: {} });
      setFileName("");
    }
  };

  return (
    <>
      <div
        className={classNames("d-flex flex-column justify-content-end inputfile", { "text-danger": error }) + className}
      >
        <input
          type="file"
          id={name}
          name={name}
          tabIndex={tabIndex}
          disabled={disabled}
          value={value}
          className="text-center d-none"
          style={{ height: "73px" }}
          onChange={onChangeHandler}
          accept={accept}
        />
        <label
          data-testid="file"
          style={Object.assign({ border: "1px dotted rgb(178, 180, 180)" }, labelStyle)}
          className={classNames("d-flex justify-content-center font-size-regular p-3", {
            "border border-danger": error,
          })}
          htmlFor="file"
        >
          <img className="mx-1" height={20} width={20} src="/icons/Enable.svg" alt="upload file icon" />
          <span className="file-label">{fileName || labelText}</span>
        </label>
        <p className="text-right text-danger">
          {error && ((error! as FieldError)!.message || (error! as FieldError)!)}
        </p>
      </div>
    </>
  );
};

export default FileInput;
