import GS1APIClient from "../clients/GS1APIClient";
import { CmsDataInterface } from "../interfaces/CmsInterface";
import _ from "lodash";

/**
 * Gets page content
 * @param [slug]
 * @returns
 */
function getPageContent(slug: string = "", activeMembershipId: string | null | undefined = undefined) {
  let url = `/cms/page_content/${slug}/`;
  if (activeMembershipId) {
    url = `${url}?activeMembershipId=${activeMembershipId}`;
  }
  return GS1APIClient.request<any>({
    url: url,
    method: "GET",
  });
}

/**
 * Saves cms image
 * @param [slug]
 * @param cmsImage
 * @returns
 */
function saveCmsImage(slug: string = "", cmsImage: File) {
  let formData = new FormData();
  formData.append("image", cmsImage);
  return GS1APIClient.request<any>({
    url: `/cms/page_content_image/${slug}/`,
    method: "POST",
    data: formData,
    headers: {
      Accept: "application/json, text/plain, */*",
    },
  });
}

/**
 * Saves page content
 * @param [slug]
 * @param cmsData
 * @returns
 */
function savePageContent(slug: string = "", cmsData: CmsDataInterface) {
  let formData = new FormData();
  _.forEach(cmsData.newImages, (newImage) => {
    formData.append("file[]", newImage);
  });
  _.forEach(cmsData.images, (oldImage) => {
    formData.append("images[]", oldImage.id);
  });
  formData.append("content", cmsData.content || "");
  const accessToken = GS1APIClient._getAccessToken();
  return GS1APIClient.request<any>({
    url: `/cms/page_content/${slug}/`,
    method: "POST",
    data: formData,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `Bearer ${accessToken}`
    },
  });
}

const CmsService = {
  getPageContent,
  savePageContent,
  saveCmsImage,
};

export default CmsService;
