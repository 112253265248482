import React, { FormEvent } from "react";
import Input from "components/Inputs/Input";
import Checkbox from "components/Inputs/Checkbox";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface LoginFormTypes {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  loginForm: Record<string, any>;
  invalidLoginAttempt?: boolean;
}

const LoginForm: React.FC<LoginFormTypes> = ({
  onSubmit,
  loginForm: { errors, register, setValue },
  invalidLoginAttempt,
}) => {
  const { t } = useTranslation();
  return (
    <form onSubmit={onSubmit}>
      <h2>{t("logIn")}</h2>
      <Input
        labelText={t("emailAddress")}
        placeholder=" "
        name="email"
        error={errors.email || invalidLoginAttempt}
        reference={register}
        handleChange={(e) => setValue("email", e.target.value.toLowerCase())}
        autocomplete="username"
      />
      <Input
        labelText={t("password")}
        placeholder=" "
        type="password"
        name="password"
        autocomplete="current-password"
        error={errors.password || invalidLoginAttempt}
        reference={register}
      />
      <div className="row">
        <div className="col-6">
          <Checkbox name="rememberMe" reference={register} labelText={t("form.login.rememberMe")} />
        </div>
        <div className="col-6">
          <Link className="d-flex justify-content-end mb-2 font-size-medium link" to="/reset_password">
            {t("form.login.remindPassword")}
          </Link>
        </div>
      </div>
      <div className="form-group d-flex justify-content-end">
        <button data-testid="loginSubmit" className="btn primary w-100" type="submit">
          {t("logIn")}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
