import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import TooltipWrapper from "components/Tooltip/Tooltip";
import { VERIFY_BY_GS1_LINK } from "./consts";

type VerifyProps = {
  label?: string;
  rect?: boolean;
  placement?: "bottom" | "left" | "top" | "right";
};

const VerifyByGs1Icon = ({ label, rect = false, placement="bottom"}: VerifyProps) => {
  const { t } = useTranslation();
  const tooltip = 'Ikona "Verified by GS1" wskazuje, że numer jest prawidłowy i posiada aktywną licencję w GS1';
  const className = classNames("verfiy-icon", { "verfiy-icon--rect": rect });
  const src = rect ? "/is-verfiy-rect.png" : "/is-verfiy.png";
  return (
    <div className={className}>
      <a href={VERIFY_BY_GS1_LINK} target="_blank">
        <TooltipWrapper placement={placement} tooltipText={tooltip}>
          <img src={src} alt={tooltip} />
        </TooltipWrapper>
      </a>
      {label}
    </div>
  );
};

export default VerifyByGs1Icon;
