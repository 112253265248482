import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import useMessage from "hooks/useMessageStatus";
import htmlToDraft from "html-to-draftjs";
import { CmsDataInterface } from "interfaces/CmsInterface";
import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { CmsService } from "services";
import NPWSStatus from "utils/statuses";
import EditorInput from "../../components/Inputs/Editor";
import VerifyDataPage from "../../pages/VerifyDataPage/VerifyDataPage";

const VerifyDataPageCMS: React.FC = () => {
  const { setMessage } = useMessage();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  const { t } = useTranslation();
  const pageSlug = "verify-data";
  const [content, setContent] = useState<CmsDataInterface>({
    id: "",
    slug: "",
    images: undefined,
    newImages: undefined,
    content: "",
  });

  /**
   * Detects changes in editor and calls this function
   * @param editState
   * @param options
   */
  function onEditorStateChange(editState: EditorState, options: object) {
    setEditorState(editState);
    setTitle(draftToHtml(convertToRaw(editState.getCurrentContent())));
    setContent({ ...content, content: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
  }

  /**
   * Gets page content from server
   */
  async function getPageContent() {
    const response = await CmsService.getPageContent(pageSlug);
    const contentBlock = htmlToDraft(response.data.content);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
    setContent(response.data);
    // Old way
    // const blocksFromHTML = convertFromHTML(response.data.content);
    // const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    // setEditorState(EditorState.createWithContent(state));
    // setContent(response.data);
  }

  /**
   * Saves page content
   */
  function savePageContent() {
    try {
      CmsService.savePageContent(pageSlug, content);
      setMessage(NPWSStatus.CMS_SAVE_SUCCESS);
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }

  const editor = useRef(null);

  useEffect(() => {
    getPageContent();
  }, []);

  return (
    <div className="">
      <Tabs transition={false} defaultActiveKey="edit-mode" id="uncontrolled-tab-example">
        <Tab eventKey="edit-mode" title={t("cms.editmode")}>
          <div className="row">
            <EditorInput
              ref={editor}
              name="edit"
              onEditorStateChange={onEditorStateChange}
              editorState={editorState}
              pageSlug={pageSlug}
            />
          </div>
        </Tab>
        <Tab eventKey="preview-mode" title={t("cms.preview")}>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-end mb-5">
              <button className="btn primary" onClick={() => savePageContent()}>
                {t("cms.preview.save")}
              </button>
            </div>
            <VerifyDataPage preview={true} cmsData={content} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default VerifyDataPageCMS;
