import React from "react";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="database row d-flex justify-content-center align-items-center login-page mt-5">
      <div className="col-12">
        <div className="container">
          <div className="row">
            <div className="col-7">
              <h3 className="first-chapter__header"> {t("aboutus.objective")}</h3>
              <p className="first-chapter__paragraph"> {t("aboutus.paragraph.first")} </p>
              <br />
              <p className="first-chapter__paragraph">{t("aboutus.paragraph.second")}</p>
              <br />
              <h3 className="first-chapter__paragraph">{t("aboutus.header.first")}</h3>
              <ul>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.first")}</li>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.second")}</li>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.third")}</li>
              </ul>
              <br />
              <h3 className="first-chapter__paragraph"> {t("aboutus.header.second")}</h3>
              <ul>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.fourth")}</li>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.fifth")}</li>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.sixth")}</li>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.seventh")}</li>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.eighth")}</li>
              </ul>
              <br />
            </div>
            <div className="col-5">
              <img width="324" height="430" src="/gs1_global_tree_icon_graphic.png"></img>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h3 className="first-chapter__paragraph"> {t("aboutus.header.third")}</h3>
              <p className="first-chapter__paragraph"> {t("aboutus.paragraph.third")}</p>
              <br />
              <h3 className="first-chapter__paragraph"> {t("aboutus.header.fourth")}</h3>
              <p className="first-chapter__paragraph"> {t("aboutus.paragraph.fourth")}</p>
              <br />
              <h3 className="first-chapter__paragraph"> {t("aboutus.header.fifth")}</h3>
              <ul>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.nineth")}</li>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.tenth")}</li>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.eleventh")}</li>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.twelfth")}</li>
                <li className="first-chapter__paragraph"> {t("aboutus.listitem.thirteenth")}</li>
              </ul>
              <p className="first-chapter__paragraph">
                {" "}
                {t("aboutus.paragraph.fifth")}
                <span className="font-weight-bold">{t("aboutus.paragraph.fifth.bold")}</span>
                {t("aboutus.paragraph.fifth,parttwo")}
              </p>
              <br />
              <p className="first-chapter__paragraph">
                {" "}
                {t("aboutus.paragraph.sixth")}
                <a className="link medium" href={`https://${t("aboutus.www")}`} target="_blank">
                  {t("aboutus.www")}
                </a>
                {t("aboutus.paragraph.seventh")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
