import React from "react";
import { useTranslation } from "react-i18next";

interface LimitsLeftComponent {
  company: any;
}

const getVerboseSubscription = (subscription: string) => {
  const VERBOSES: { [key: string]: string } = {
    free: "Starter",
    monthly: "Basic (miesięczny limitowany)",
    yearly_limited: "Pro (roczny limitowany)",
    yearly_unlimited: "Unlimited (roczny nielimitowany)",
  };
  return VERBOSES[subscription];
};
const getCurrentYear = () => {
  return new Date().getFullYear();
};

const formatNumber = (number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

const LimitsLeftComponent: React.FC<LimitsLeftComponent> = ({ company }) => {
  const { t } = useTranslation();
  const limitVerify = company.counterVerified;
  const limitApiCsv = parseInt(company.counterApi) + parseInt(company.counterCsv);
  const isFreePackage = company.subscription === "free";
  const getActive = () => {
    const begin = new Date(company.beginSubscriptionDt);
    const end = new Date(company.endSubscriptionDt);
    return begin <= new Date() && new Date() <= end ? (
      <span className="main__detail font-size-medium" style={{ color: "green" }}>
        {t("company.limits.active")}
      </span>
    ) : (
      <span className="main__detail font-size-medium" style={{ color: "red" }}>
        {t("company.limits.noActive")}
      </span>
    );
  };
  return (
    <>
      <h4>
        <b>Aktywny pakiet usług:</b>
      </h4>
      <p className={"font-size-medium mb-0"}>
        <p>
          <span className="main__detail font-size-medium">{t("company.limits.starter.title")}</span>
          <span className="main__detail font-size-medium" style={{ color: "green" }}>
            {t("company.limits.active")}
          </span>
          <p className="font-size-medium">
            {t("company.limits.period")} {t("from")} {getCurrentYear()}-01-01 {t("to")} {getCurrentYear()}-12-31
          </p>
        </p>
        <p className={"font-size-medium"}>{t("company.limits.usage")}</p>
        <p className={"font-size-medium mb-0"}>
          <ul className={"mb-0"}>
            <li>
              {t("company.limits.verification")}{" "}
              {formatNumber(company.counterFreeVerified)} |{" "}
              {t("company.limits.limit")} 25 000 {t("company.limits.products")}
            </li>
            <li>
              {t("company.limits.downloadDB")} {t("company.limits.na")}
            </li>
            <li>
              {t("company.limits.catalog.browse")} {t("company.limits.noLimit")}
            </li>
          </ul>
        </p>
      </p>
      <p className={"font-size-medium mt-line"} hidden={isFreePackage}>
        <p>
          <span className="main__detail font-size-medium">{getVerboseSubscription(company.subscription)}:</span>
          {getActive()}
          <p className="font-size-medium">
            {t("company.limits.period")} {t("from")} {company.beginSubscriptionDt} {t("to")} {company.endSubscriptionDt}
          </p>
        </p>
        <p className={"font-size-medium"}>{t("company.limits.usage")}</p>
        <p className={"font-size-medium"}>
          <ul>
            <li>
              {t("company.limits.verification")} {formatNumber(limitVerify)} | {t("company.limits.limit")}{" "}
              {formatNumber(company.limits.verify)} {t("company.limits.products")}
            </li>
            <li>
              {t("company.limits.downloadDB")} {formatNumber(limitApiCsv)} | {t("company.limits.limit")}{" "}
              {formatNumber(company.limits.api)} {t("company.limits.products")}
            </li>
            <li>
              {t("company.limits.catalog.browse")} {t("company.limits.noLimit")}
            </li>
            {company.subscription === "yearly_unlimited" && (
              <div>
                <li>
                  {t("company.limits.counterZippedProductsDownloads")}{" "}
                  {formatNumber(company.counterZippedProductsDownloads)}
                </li>
                <li>
                  {t("company.limits.counterZippedProductsSum")} {formatNumber(company.counterZippedProductsSum)}
                </li>
              </div>
            )}
          </ul>
        </p>
      </p>

      <p className={"font-size-medium mb-0"}>
        {t("company.limits.pricing")} <a href={"/pricing"}>{t("navbar.subscriptions")}</a>{" "}
      </p>

      <p className={"text-center font-size-medium mt-line"}>
        <img src="/icons/tip-icon@2x.png" height="20" className="image" /> {t("company.limits.buyMore")}{" "}
        <a href="https://www.eprodukty.gs1.pl/pricing">eprodukty.gs1.pl/pricing</a>
      </p>
    </>
  );
};

export default LimitsLeftComponent;
