import classNames from "classnames";
import React from "react";

interface NavButtonProps {
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
  arrow?: boolean;
  label: string;
}

const NavButton: React.FC<NavButtonProps> = ({ onClick, disabled, active, label, arrow }) => {
  return (
    <button
      className={classNames("Table__pageButton", { disabled: disabled }, { active: active }, { arrow: arrow })}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
export default NavButton;
