import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";

import SetPasswordForm from "components/SetPasswordForm/SetPasswordForm";
import generateSetPasswordPageSchema from "./SetPasswordPageValidation";
import { setPassword, getUserByResetPasswordId } from "store/thunk/AccountThunk";
import useMessage from "hooks/useMessageStatus";

import NPWSStatus from "utils/statuses";
import { setPendingStatus } from "store/actions/UiActions";

const SetPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const { id }: any = useParams();
  const { setMessage } = useMessage();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isPending } = useSelector((store: any) => store.ui);

  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState<string>("");

  /**
   * Determines whether user exists is
   */
  async function isUserExists() {
    dispatch(setPendingStatus(true));
    try {
      const {
        data: {
          user: { email },
        },
      }: any = await dispatch(getUserByResetPasswordId(id));
      setEmail(email);
      dispatch(setPendingStatus(false));
    } catch {
      dispatch(setPendingStatus(false));
      history.push("/");
      setMessage(NPWSStatus.LINK_EXPIRED);
    }
  }

  const { handleSubmit, ...form } = useForm({
    validationSchema: generateSetPasswordPageSchema(t),
  });

  /**
   * Determines whether submit on
   * @param data
   */
  async function onSubmit(data: Record<string, any>) {
    const { password, passwordRepeat } = data;
    try {
      await dispatch(setPassword(password, passwordRepeat, id));
      setSuccess(true);
      history.push("/login");
      setMessage(NPWSStatus.PASSWORD_RESETED);
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }

  useEffect(() => {
    isUserExists();
  }, []);

  return !isPending ? (
    <div className="set-password">
      <div className="component-container col-5">
        <h2>{t("set.password.newPassword.change")}</h2>
        <SetPasswordForm success={success} form={form} email={email} onSubmit={handleSubmit(onSubmit)} />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SetPasswordPage;
