import { ThunkResult } from "interfaces/StoreInterface";
import CatalogService from "services/CatalogService";
import { Membership } from "../../interfaces/UserInterface";

export type directon = "ACS" | "DESC";

export interface GenerateDatabaseQueryParams {
  categories?: string[];
  gtin?: string;
  product_name?: string;
  brand?: string;
  company_name?: string;
  nip?: string;
}

/**
 * Searchs products
 * @param query
 * @returns products
 */
export function searchProducts(query: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CatalogService.searchProducts(query);
  };
}

/**
 * Downloads catalog file
 * @param activeMembership
 * @param fileId
 * @returns catalog file
 */
export function downloadCatalogFile(activeMembership: Membership, fileId: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CatalogService.downloadCatalogFile(activeMembership, fileId);
  };
}

/**
 * Gets product by id
 * @param id
 * @returns product by id
 */
export function getProductById(id: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CatalogService.getProductById(id);
  };
}

/**
 * Generates database
 * @param activeMembership
 * @param queryParams
 * @returns database
 */
export function generateDatabase(
  activeMembership: Membership,
  queryParams: GenerateDatabaseQueryParams,
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CatalogService.generateDatabase(activeMembership, queryParams);
  };
}

/**
 * Searchs product base
 * @param [id]
 * @returns product base
 */
export function searchProductBase(id: string = ""): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CatalogService.searchProductBase(id);
  };
}

/**
 * Searchs product advance
 * @param activeMembership
 * @param query
 * @returns product advance
 */
export function searchProductAdvance(activeMembership: Membership | null, query: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CatalogService.searchProductAdvance(activeMembership, query);
  };
}

/**
 * Searchs products autocomplete
 * @param activeMembership
 * @param query
 * @returns products autocomplete
 */
export function searchProductsAutocomplete(activeMembership: Membership, query: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CatalogService.searchProductsAutocomplete(activeMembership, query);
  };
}

/**
 * Searchs companies autocomplete
 * @param activeMembership
 * @param query
 * @returns companies autocomplete
 */
export function searchCompaniesAutocomplete(activeMembership: Membership, query: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CatalogService.searchCompaniesAutocomplete(activeMembership, query);
  };
}

/**
 * Downloads zipped csv files
 * @param activeMembership
 * @returns zipped products
 */
export function downloadZippedCsvFile(activeMembership: Membership): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CatalogService.downloadZippedCsvFile(activeMembership);
  };
}
