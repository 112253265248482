import { ThunkResult } from "interfaces/StoreInterface";
import CompanyServices from "services/CompanyService";
import { Membership } from "../../interfaces/UserInterface";

/**
 * Gets company by id
 * @param id
 * @returns company by id
 */
export function getCompanyById(id: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CompanyServices.getCompanyById(id);
  };
}

/**
 * Searchs companies
 * @param activeMembership
 * @param query
 * @returns companies
 */
export function searchCompanies(activeMembership: Membership, query: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CompanyServices.searchRegisteredCompanies(activeMembership, query);
  };
}

/**
 * Updates company access to download
 * @param companyId
 * @param accessToDatabase
 * @returns company access to download
 */
export function updateCompanyAccessToDownload(
  companyId: string | undefined,
  accessToDatabase: boolean,
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CompanyServices.updateCompanyAccessToDownload(companyId, accessToDatabase);
  };
}

/**
 * Order a subscription
 * @param subscription
 * @returns
 */
export function orderSubscription(subscription: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await CompanyServices.orderSubscription(subscription);
  };
}
