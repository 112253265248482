import * as UiActions from "../actions/UiActions";

type UiActions = UiActions.UiActions;

export interface UiState {
  isPending: boolean;
  statusMessage: UiActions.SetMessageAction | null;
}

export const initialUiState: UiState = {
  isPending: false,
  statusMessage: null,
};

/**
 * reducer
 * @param [state]
 * @param action
 * @returns
 */
export default function uiReducer(state: UiState = initialUiState, action: UiActions) {
  switch (action.type) {
    case UiActions.SET_PENDING_STATUS: {
      return {
        ...state,
        isPending: action.payload,
      };
    }
    case UiActions.SET_MESSAGE: {
      return {
        ...state,
        statusMessage: action.payload,
      };
    }
    case UiActions.REMOVE_MESSAGE: {
      return {
        ...state,
        statusMessage: null,
      };
    }
    default: {
      return state;
    }
  }
}
