import AutocompleteSelect from "components/Autocomplete/AutocompleteSelect";
import Input from "components/Inputs/Input";
import useAuthentication from "hooks/useAuthentication";
import { GCPData } from "interfaces/GCPData";
import React, { useEffect, useMemo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { searchCompaniesAutocomplete, searchProductsAutocomplete } from "store/thunk/CatalogThunk";
import { ADVANCED_SEARCH_ACCESS } from "utils/constants";
import { haveAccess } from "utils/permissions";
import MaskedInput from "../../components/Inputs/MaskedInput";
import { Membership } from "../../interfaces/UserInterface";
import CatalogActionButtons from "./CatalogPageActionButtons";
import { generateCatalogSchema } from "./CatalogPageValidation";
import Checkbox from "components/Inputs/Checkbox";
import { AutocompleteValues, FullSearchParams, initAutocompleteDefValues, initAutocompleteValues } from "./interfaces";
interface CatalogPageInputsFormProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalWasShown: React.Dispatch<React.SetStateAction<boolean>>;
  inputParams: FullSearchParams;
  setInputParams: React.Dispatch<React.SetStateAction<FullSearchParams>>;
  selectedKeys: string[];
  onSearch: () => void;
  hasProducts: boolean;
  allProducts: number | undefined;
  setGcpData: React.Dispatch<GCPData | null | string>;
  getDatabase: () => void;
}

const CatalogPageInputsForm: React.FC<CatalogPageInputsFormProps> = ({
  setShowModal,
  setModalWasShown,
  inputParams,
  setInputParams,
  selectedKeys,
  onSearch,
  hasProducts,
  allProducts,
  setGcpData,
  getDatabase,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, authenticated, activeMembership } = useAuthentication();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const getActiveMembership: () => Membership | undefined | null = () => {
    let returnValue: Membership | undefined | null;
    returnValue = user?.memberships?.find((membership) => membership.id === localStorage.getItem("activeId"));
    if (!returnValue && user?.memberships) returnValue = user?.memberships[0];
    return returnValue;
  };
  const {
    handleSubmit,
    register,
    setValue,
    errors,
    formState: { isValid },
  } = useForm({
    validationSchema: generateCatalogSchema(t, setGcpData, executeRecaptcha, getActiveMembership()),
    reValidateMode: "onSubmit",
  });

  const [autocompleteValues, setAutocompleteValues] = useState<AutocompleteValues<string[]>>(initAutocompleteValues);
  const [autocompleteDefValues, setAutocompleteDefValues] = useState<AutocompleteValues<string | undefined>>(
    initAutocompleteDefValues,
  ); // these might be useul while doing the autocomplete inputs def value
  const [isTermsAccepted, setTermsAccepted] = useState<boolean>(false);

  const MAX_AUTOCOMPLETE__PAYLOAD = 15;

  /**
   * Fetchs data for autocomplete inputs
   * @param inputValue
   * @param inputName
   * @param endpoint
   * @returns
   */
  async function fetchDataAutocomplete(inputValue: string, inputName: string, endpoint: "companies" | "products") {
    let res: any;

    /**
     * Maps autocomplete response to keys we use in inputs
     * @param res
     * @returns autocomplete res
     */
    function mapAutocompleteRes(res: any): string[] {
      const data = Object.values(res.data);
      const response: any = data[0];
      const options = response[0].options;
      return options.map((el: any) => el.text);
    }

    const autocompleteProp = endpoint === "companies" ? "company_suggest__completion" : inputName;
    try {
      switch (endpoint) {
        case "companies":
          res = await dispatch(searchCompaniesAutocomplete(activeMembership!, `${inputName}=${inputValue}`));
          break;
        case "products":
          res = await dispatch(searchProductsAutocomplete(activeMembership!, `${inputName}=${inputValue}`));
          break;
        default:
          return;
      }
      setAutocompleteValues({
        ...autocompleteValues,
        [autocompleteProp]: mapAutocompleteRes(res),
      });
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Checks if form is dirty (polluted with any values)
   * @returns true if is form dirty
   */
  function checkIsFormDirty(): boolean {
    const { offset, limit, ...rest } = inputParams;

    return Object.values(rest).some((el) => {
      return `${el}`.length;
    });
  }

  const isFormDirty = useMemo(() => checkIsFormDirty(), [inputParams, checkIsFormDirty]);

  useEffect(() => {
    setAutocompleteDefValues({
      name_suggest__completion: inputParams.name__contains,
      company_suggest__completion: inputParams.company_name__contains,
      brand_suggest__completion: inputParams.brand__contains,
    });
  }, []);

  return (
    <div className="container px-0">
      <form onSubmit={handleSubmit(onSearch)} className="row">
        <div className="col-4">
          <MaskedInput
            labelText={t("catalogPage.filters.gtin.ean")}
            customLabelClassName="mb-0"
            reference={register}
            name="gtin_number"
            error={errors.gtin_number}
            formHooks={{ register, setValue }}
            type="text"
            mask={/^\d+$/}
            defaultValue={inputParams.gtin_number}
            handleChange={(value) => {
              setInputParams({ ...inputParams, gtin_number: value });
            }}
          />
        </div>
        <div className="col-4">
          <AutocompleteSelect
            options={autocompleteValues.name_suggest__completion}
            name="name_suggest__completion"
            maxResults={MAX_AUTOCOMPLETE__PAYLOAD}
            defVal={inputParams.name__contains}
            labelText={t("catalogPage.filters.name")}
            placeholder={t("")}
            customLabelClassName="mb-0"
            disabled={!haveAccess(activeMembership!, ADVANCED_SEARCH_ACCESS)}
            onInputChange={(inputValue: string) => {
              setInputParams((prevState) => ({ ...prevState, name__contains: inputValue }));
            }}
            onSearch={(inputValue: string) => {
              fetchDataAutocomplete(inputValue, "name_suggest__completion", "products");
            }}
            onChange={(value) => {
              setInputParams((prevState) => ({ ...prevState, name__contains: value[0] || "" }));
            }}
          />
        </div>
        <div className="col-4">
          <AutocompleteSelect
            options={autocompleteValues.brand_suggest__completion}
            name="brand_suggest__completion"
            maxResults={MAX_AUTOCOMPLETE__PAYLOAD}
            labelText={t("catalogPage.filters.brand")}
            customLabelClassName="mb-0"
            placeholder={t("")}
            defVal={inputParams.brand__contains}
            disabled={!haveAccess(activeMembership!, ADVANCED_SEARCH_ACCESS)}
            onInputChange={(inputValue: string) => {
              setInputParams((prevState) => ({ ...prevState, brand__contains: inputValue }));
            }}
            onSearch={(inputValue: string) =>
              fetchDataAutocomplete(inputValue, "brand_suggest__completion", "products")
            }
            onChange={(value) => {
              setInputParams((prevState) => ({ ...prevState, brand__contains: value[0] || "" }));
            }}
          />
        </div>
        <div className="col-4">
          <Input
            name="company_suggest__completion"
            reference={register}
            labelText={t("catalogPage.filters.companyName")}
            placeholder=" "
            customLabelClassName="mb-0"
            error={errors.company_name__contains}
            disabled={!haveAccess(activeMembership!, ADVANCED_SEARCH_ACCESS)}
            handleChange={(e) => {
              setInputParams({ ...inputParams, company_name__contains: e.target.value });
            }}
            value={inputParams.company_name__contains}
          />
        </div>
        <div className="col-4">
          <Input
            name="company_nip"
            disabled={!haveAccess(activeMembership!, ADVANCED_SEARCH_ACCESS)}
            error={errors.company_nip}
            reference={register}
            customLabelClassName="mb-0"
            labelText={t("catalogPage.filters.nip")}
            placeholder=" "
            handleChange={(e) => setInputParams({ ...inputParams, company_nip: e.target.value })}
            value={inputParams.company_nip}
          />
        </div>
        <div className="col-4 d-flex flex-column justify-items-end mt-1">
          <label className="font-weight-bold font-size-medium mb-0">{t("database.gpc")}</label>
          <button
            onClick={() => {
              setModalWasShown(true);
              setShowModal(true);
            }}
            data-testid="gpc_number"
            style={{ marginBottom: "16px" }} //Improved-inputs do habe it
            type="button"
            className={`btn border bg-color-white txt-color-dark ${
              !haveAccess(activeMembership!, ADVANCED_SEARCH_ACCESS) ? "disabled gpc-btn-disabled" : ""
            }`}>
            {t("catalogPage.chooseGpc")}
            <i className="fas fa-chevron-right ml-3"></i>
          </button>
        </div>

        {!activeMembership && (
          <div className="d-flex justify-content-center align-items-center" style={{ width: "100%" }}>
            <div style={{ maxWidth: "50%", fontWeight: "bold" }} className="text-center">
              <Checkbox
                reference={register}
                classes={`mb-0 p-2`}
                name="acceptTerms"
                error={errors.acceptTerms}
                handleClick={() => setTermsAccepted(!isTermsAccepted)}
                checked={isTermsAccepted}
                blinking={false}>
                {t("terms.confirm.familiarWith") + " "}
                <a className="link" href="/terms">
                  {t("terms")}
                </a>
                {" " + t("terms.confirm.platformPwS")}
              </Checkbox>
            </div>
          </div>
        )}

        {selectedKeys.length ? (
          <div className="col">
            <div className="mt-n2 paragraph text-right">
              {t("catalogPage.categories.count")} {selectedKeys.length}
            </div>
          </div>
        ) : null}
        {!authenticated && (
          <div className="col-12">
            <p className="paragraph" style={{ textAlign: "center" }}>
              {t("catalogPage.authentication")} <b>{t("catalogPage.authentication.login")}</b>
            </p>
          </div>
        )}
        <CatalogActionButtons
          hasProducts={hasProducts}
          isFormDirty={isFormDirty}
          getDatabase={getDatabase}
          setGcpData={setGcpData}
          allProducts={allProducts}
          isTermsAccepted={isTermsAccepted}
          setTermsAccepted={setTermsAccepted}
        />
      </form>
    </div>
  );
};

export default CatalogPageInputsForm;
