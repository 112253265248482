import validator from "validator";
import { regexps } from "./constants";

export const RegonValidator = () => {
  const validate = (regon: string) => {
    const regonLength = regon.length;
    let weightDigits;
    let controlDigit = 0;

    if ((regonLength !== 9 && regonLength !== 14) || regexps.ONLY_ZEROS.test(regon)) return false;

    if (regonLength === 9) {
      weightDigits = [8, 9, 2, 3, 4, 5, 6, 7];
    } else {
      weightDigits = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
    }

    for (let i = 0; i < regonLength - 1; i++) {
      controlDigit += weightDigits[i] * parseInt(regon.charAt(i));
    }

    controlDigit %= 11;

    if (controlDigit === 10) {
      controlDigit = 0;
    }

    if (controlDigit !== parseInt(regon.charAt(regonLength - 1))) {
      return false;
    } else {
      return true;
    }
  };

  return {
    validate,
  };
};

export const NipValidator = () => {
  const validate = (nip: string) => {
    if (!regexps.NIP.test(nip)) {
      return false;
    } else {
      const digits = ("" + nip).split("");
      const checksum =
        (6 * parseInt(digits[0]) +
          5 * parseInt(digits[1]) +
          7 * parseInt(digits[2]) +
          2 * parseInt(digits[3]) +
          3 * parseInt(digits[4]) +
          4 * parseInt(digits[5]) +
          5 * parseInt(digits[6]) +
          6 * parseInt(digits[7]) +
          7 * parseInt(digits[8])) %
        11;
      return parseInt(digits[9]) === checksum;
    }
  };

  return {
    validate: validate,
  };
};

/**
 * validates phone number
 * @param phoneNumber
 * @returns boolean
 */
export function PhoneValidator(phoneNumber: any) {
  if (!phoneNumber.startsWith("+")) {
    return false;
  }
  return validator.isMobilePhone(phoneNumber.slice(1), "any");
}

/**
 * Emails validator
 * @param email
 * @returns boolean
 */
export function EmailValidator(email: string) {
  return (
    email.indexOf("@") !== -1 &&
    regexps.NOT_ONLY_SPEC_CHAR.test(email.split("@")[0]) &&
    validator.isEmail(email, { allow_utf8_local_part: false })
  );
}

/**
 * Flattens object
 * @param data
 * @param [parentKey]
 * @returns flatted object
 */
export function flattenObject(data: any, parentKey: string = ""): Record<string, any>[] {
  return Object.entries(data)
    .map(([key, value]) => {
      const nestedKey = parentKey ? [parentKey, key].join(".") : key;
      if (value !== null && typeof value === "object") {
        return flattenObject(value, nestedKey);
      } else {
        return { key: nestedKey, value };
      }
    })
    .flat();
}

/**
 * Maps store to registration form
 * @param {
 *   regonChecked,
 *   disableCompanyDataForm,
 *   emailChecked,
 *   disablePersonalForm,
 *   ...formData
 * }
 * @returns
 */
export function mapStoreToRegistrationForm({
  regonChecked,
  disableCompanyDataForm,
  emailChecked,
  disablePersonalForm,
  ...formData
}: any) {
  const { company, personal } = formData;
  return { company, ...personal };
}

/**
 * Gtins img generator
 * @param productName
 * @param gtin
 * @returns
 */
export function gtinImgGenerator(productName: string, gtin: string) {
  return `https://gtin-generator.mojegs1.pl/viewLabel.php?nazwa=${encodeURI(productName)}&gtin=${gtin}`;
}

/**
 * Maps agrees data to boolean
 * @param data
 * @returns
 */
export function mapAgreesDataToBoolean(data: Record<string, any>) {
  if (data.user) {
    data.user.agreeToSellingEmails = data.user?.agreeToSellingEmails === "true";
    data.user.agreeToSellingPhones = data.user?.agreeToSellingPhones === "true";
    data.user.agreeToSharingData = data.user?.agreeToSharingData === "true";
    data.user.agreeToMarketingEmails = data.user?.agreeToMarketingEmails === "true";
    data.user.agreeToMarketingPhones = data.user?.agreeToMarketingPhones === "true";
  }
  return data;
}

/**
 * Shows xletters rest hide
 * @param [text]
 * @param [numberOfLetters]
 * @returns xletters rest hide
 */
export function showXlettersRestHide(text: string = "", numberOfLetters: number = 1): string {
  const showLetters = text.slice(0, numberOfLetters);
  const hideLetters = text.slice(numberOfLetters).replace(/./g, "*");
  return showLetters + hideLetters;
}

/**
 * Matchs exact
 * @param regex
 * @param value
 * @returns
 */
export function matchExact(regex: any, value: any) {
  const match = value.match(regex);
  return match && value === match[0];
}

export const getValidUrl = (url: string = "") => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }

  return newUrl;
};

export function numberWithSpaces(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
