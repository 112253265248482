export const HOME_PAGE_ACCESS = "home_page";
export const CATALOG_PAGE_ACCESS = "catalog_page";
export const DATA_VERIFICATION_PAGE_ACCESS = "data_verification_page";
export const DATABASE_DOWNLOAD_PAGE_ACCESS = "database_download_page";
export const STATUTE_PAGE_ACCESS = "statute_page";
export const LOCATION_USERS_PAGE_ACCESS = "location_users_page";

export const BASE_SEARCH_ACCESS = "base_search";
export const ADVANCED_SEARCH_ACCESS = "advanced_search";
export const FILE_SENDING_TO_VERIFICATION_ACCESS = "file_sending_to_verification";
export const DATABASE_DOWNLOAD_ACCESS = "database_download";
export const CMS_ACCESS = "cms";
export const REPOSITORY_ACCESS = "repository";
export const REGISTERED_COMPANIES_ACCESS = "registered_company";
export const USERS_LIST_ACCESS = "users_list";
export const USERS_MANAGE_ACCESS = "users_manage";
export const USER_DATA = "user_data";
export const CHANGE_PASSWORD = "change_password";

const allPermissions = [
  HOME_PAGE_ACCESS,
  CATALOG_PAGE_ACCESS,
  DATA_VERIFICATION_PAGE_ACCESS,
  DATABASE_DOWNLOAD_PAGE_ACCESS,
  STATUTE_PAGE_ACCESS,
  LOCATION_USERS_PAGE_ACCESS,
  BASE_SEARCH_ACCESS,
  ADVANCED_SEARCH_ACCESS,
  FILE_SENDING_TO_VERIFICATION_ACCESS,
  DATABASE_DOWNLOAD_ACCESS,
  CMS_ACCESS,
  REPOSITORY_ACCESS,
  REGISTERED_COMPANIES_ACCESS,
  USERS_LIST_ACCESS,
  USERS_MANAGE_ACCESS,
  USER_DATA,
];

export type Permissions = typeof allPermissions;

export const PHONE_PREFIX_REGEX = /(^\+48) (\D){3}(-\D{3}){2}/;

export const CMS_PROJECT_FONTS = [
  "'Gotham SSm A', 'Gotham SSm B', Verdana, sans-serif",
  "'Gotham Narrow SSm A', 'Gotham Narrow SSm B', Verdana, sans-serif",
  "'Gotham A', 'Gotham B', Verdana, sans-serif",
];

export const CMS_COLOR_PICKER = [
  "#002c6c",
  "#f26334",
  "#454545",
  "#888b8d",
  "#b1b3b3",
  "#ffffff",
  "#f4f4f4",
  "#008dbd",
  "#f05587",
  "#bf83b9",
  "#af96d4",
  "#89aadb",
  "#00b6de",
  "#8db9ca",
  "#22bcb9",
  "#71b790",
  "#7ac143",
  "#00b74f",
  "#9dbb68",
  "#c1d82f",
  "#c4b000",
  "#fbb034",
  "#ff8200",
  "#b78b20",
  "#d3875f",
];

export const CMS_BLOCK_TYPE = ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"];

export const regexps = {
  PHONE: /^\+[1-9]{2} [1-9]{1,1}[0-9]{2,2}-[0-9]{3,3}-[0-9]{3,3}$/,
  POSTAL_CODE: /^[0-9]{2}[-][0-9]{3}$/,
  NOT_ONLY_SPEC_CHAR: /(?!^[\W_]+$)^.{2,}$/,
  NOT_ONLY_SPEC_CHAR_AND_NUMS: /(?!^[\d\W_]+$)^.{2,}$/,
  ADDRESS: /([\p{L}\p{N}]{2,})/u,
  CITY: /([\p{L}\p{N}]{2,})/u,
  ONLY_ZEROS: /^0*$/,
  NIP: /^[1-9][0-9]{9}$/,
  PHONE_PREFIX_REGEX: /(^\+48) (\D){3}(-\D{3}){2}/,
  PASSWORD_REGEX: /(^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_0-9(){}[\]\|:”;'<>?.,\/]).*$)/,
  NIP_REGEX: /[a-zA-Z0-9_-]+/,
  RESET_PASSWORD_EXCEPTION: /reset_password.{2,}/,
  GTIN: /(0+)?590([0-9]+)?/,
};

export const GPC_SELECT_SEGMENTS_LIMIT = 1;
