import GS1APIClient from "clients/GS1APIClient";
import { Membership } from "../interfaces/UserInterface";

/**
 * Gets company by id
 * @param id
 * @returns
 */
function getCompanyById(id: string) {
  return GS1APIClient.request<any>({
    url: `/companies/get_by_id/?id=${id}`,
    method: "GET",
  });
}

/**
 * Searchs registered companies
 * @param activeMembership
 * @param query
 * @returns
 */
function searchRegisteredCompanies(activeMembership: Membership, query: string) {
  return GS1APIClient.privateRequest<any>({
    url: `/companies/${activeMembership.id}/get_companies/?${query ? query : ""}`,
    method: "GET",
  });
}

/**
 * Searchs companies
 * @param activeMembership
 * @param query
 * @returns
 */
function searchCompanies(activeMembership: Membership, query: string) {
  return GS1APIClient.privateRequest<any>({
    url: `/companies/${activeMembership.id}/get_comps/?${query ? query : ""}`,
    method: "GET",
  });
}

/**
 * Updates company access to download
 * @param companyId
 * @param accessToDownload
 * @returns
 */
function updateCompanyAccessToDownload(companyId: string | undefined, accessToDownload: boolean) {
  return GS1APIClient.privateRequest<any>({
    url: `/companies/company/${companyId}/`,
    method: "PATCH",
    data: {
      accessToDownload,
    },
  });
}

/**
 * Gets data pools
 * @returns
 */
function getDataPools() {
  return GS1APIClient.privateRequest<any>({
    url: "companies/datapools",
    method: "GET",
  });
}

/**
 * Gets issues
 * @param activeMembership
 * @param filters
 * @param page
 * @returns
 */
function getIssues(activeMembership: Membership, filters: object, page: number) {
  return GS1APIClient.privateRequest<any>({
    url: `repository/${activeMembership.id}/issues_by_company/`,
    method: "POST",
    data: {
      filters,
      page,
    },
  });
}

/**
 * Order a subscription
 * @param subscription
 * @returns
 */
function orderSubscription(subscription: string) {
  return GS1APIClient.privateRequest<any>({
    url: `companies/order_subscription/?subscription=${subscription}`,
    method: "GET",
  });
}

const CompanyService = {
  getCompanyById,
  searchRegisteredCompanies,
  updateCompanyAccessToDownload,
  getDataPools,
  getIssues,
  searchCompanies,
  orderSubscription,
};

export default CompanyService;
