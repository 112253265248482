import React from "react";

import { useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import useAuthentication from "hooks/useAuthentication";
import { FILE_SENDING_TO_VERIFICATION_ACCESS } from "../../utils/constants";
import { haveAccess } from "../../utils/permissions";
import { Link, useLocation } from 'react-router-dom';

const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const { activeMembership } = useAuthentication();
  const location = useLocation();
  let shouldShowGen2d = (activeMembership && location.pathname.includes('/generator_2d'));

  useEffect(() => {
    if (shouldShowGen2d) {
      sessionStorage.setItem('shouldShowGen2d', 'true');
    }
  });
  shouldShowGen2d = (activeMembership && (location.pathname.includes('/generator_2d') || Boolean(sessionStorage.getItem('shouldShowGen2d'))));

  return (
    <div className="navigation__background">
      <nav className="navigation d-flex container">
        <div className="row">
          <NavLink to={"/"} className="navigation__link" exact={true} activeClassName="is-active">
            {t("navbar.start")}
          </NavLink>
          <NavLink to={"/how_it_works"} className="navigation__link" activeClassName="is-active">
            Jak to działa
          </NavLink>
          <NavLink to={"/catalog"} data-testid="catalogLink" className="navigation__link" activeClassName="is-active">
            {t("navbar.catalog")}
          </NavLink>
          <NavLink to={"/verify_data"} className="navigation__link" activeClassName="is-active">
            {t("navbar.uploadData")}
          </NavLink>

          {haveAccess(activeMembership!, FILE_SENDING_TO_VERIFICATION_ACCESS) && (
            <NavLink to={"/my_products"} className="navigation__link" activeClassName="is-active">
              {t("navbar.myProducts")}
            </NavLink>
          )}
          {(shouldShowGen2d) && (
          <NavLink to={"/generator_2d"} className="navigation__link" activeClassName="is-active">
            Generator kodów 2D
          </NavLink>
          )}
          <NavLink to={"/pricing"} className="navigation__link" activeClassName="is-active">
            {t("navbar.subscriptions")}
          </NavLink>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
