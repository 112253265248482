import GS1APIClient from "../clients/GS1APIClient";
import { GCPData } from "../interfaces/GCPData";
import { Product } from "../pages/ProductPage/ProductPage";

import i18next from "i18next";
import { Membership } from "../interfaces/UserInterface";

async function searchForGCP(
  gtin: string,
  activeMembership: Membership | null = null,
): Promise<GCPData | string | null> {
  try {
    let requestType;
    if (!GS1APIClient.authenticated()) {
      requestType = GS1APIClient.request;
    } else {
      requestType = GS1APIClient.privateRequest;
    }
    const { data } = await requestType<GCPData>({
      url: `/products/product_has_gcp/${gtin}/?activeMembershipId=${activeMembership?.id}`,
      method: "GET",
    });
    return data;
  } catch (e) {
    if (e.response && e.response.data.detail === "ReCAPTCHA not verified") {
      return "ReCAPTCHA not verified";
    }
    if (e.response && e.response.status == 429) {
      return "too_many_requests";
    }
    if (e.response && e.response.status === 404) {
      if (e.response.data.errors && e.response.data.errors.length > 0) {
        return e.response.data.errors[0].message;
      }
      return e.response.data.detail || e.response.statusText;
    }

    if (e.response && e.response.data === "User not logged") {
      console.log("User not logged");
      return "User not logged";
    }

    return null;
  }
}
/**
 * Creates placeholder record
 * @param gtin
 * @param gcpData
 * @returns placeholder record
 */
function createPlaceholderRecord(
  gtin: string,
  data: any,
  count: number | null = null,
  t: i18next.TFunction | null = null,
): Partial<Product> {
  let {
    name,
    company,
    gtinStatus,
    gtinNumber,
    isGlobal,
    netContent,
    netVolume,
    unit,
    description,
    descriptionLanguage,
    lastModified,
    brand,
    productPage,
    imageUrls = [],
    targetMarket = [],
    categoryDetails = [],
  } = data;

  const conditionsArray = [typeof data === "object", count === 0, !name];

  if (!conditionsArray.includes(false) && t !== null) name = "-";

  return {
    name,
    isGlobal,
    gtinNumber,
    gtinStatus: t !== null && name === t("catalog.details.productData.isNotActive") ? "" : gtinStatus,
    targetMarket,
    brand,
    netContent,
    netVolume,
    unit,
    description,
    descriptionLanguage,
    lastModified,
    imageUrls,
    categoryDetails,
    company: data.company,
    productPage,
    isVerified: data.isVerified || false,
    isPartial: !data.name,  // todo?
    isPublic: data.isPublic || true,
  };
}

const GCPValidationService = {
  searchForGCP,
  createPlaceholderRecord,
};

export default GCPValidationService;
