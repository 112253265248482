import React, { useState, useEffect } from "react";
import { components, ValueContainerProps, OptionTypeBase } from "react-select";
import Select from "react-select";
import Checkbox from "components/Inputs/Checkbox";
import MultiselectValueContainer from "./MultiselectValueContainer";
import AsyncSelect from "react-select/async";

import { useTranslation } from "react-i18next";
import { OptionsType } from "react-select/src/types";
import _ from "lodash";

export type Option = { value: string; label: string };

interface MultiselectProps {
  options: Option[];
  selectAll?: boolean;
  onChange: (value: any) => void;
  placeholder?: string;
  additionalOptions?: Option[];
  canSelectNone?: boolean;
  isAsync?: boolean;
  onloadOptions?: (inputValue: string, callback: (options: OptionsType<any>) => void) => Promise<any> | void;
}

const customStyles = {
  multiValue: (provided: any, state: any) => ({
    ...provided,
    maxWidth: "90%",
  }),
};

const Multiselect: React.FC<MultiselectProps> = ({
  options,
  selectAll = false,
  onChange,
  placeholder = "",
  additionalOptions = [],
  canSelectNone = false,
  isAsync = false,
  onloadOptions,
}) => {
  const [selected, setSelected] = useState<Option[]>([]);
  const [selectNone, setSelectNone] = useState<boolean>(false);
  const { t } = useTranslation();
  const [isAllSelected, setAllSelected] = useState<boolean>(false);
  const selectOptions: Option[] = options;

  /**
   * Gets selected without empty option
   * @param values
   * @returns
   */
  function getSelectedWithoutEmptyOption(values: Option[]) {
    return values.filter((option: Option) => option.value !== "no");
  }

  const ValueContainer = ({ ...props }: ValueContainerProps<OptionTypeBase>) => (
    <MultiselectValueContainer {...props} selected={selected} selectOptions={selectOptions} />
  );

  const Option = ({ children, ...props }: any) => {
    function renderCheckbox(props: any) {
      return (
        <div className="multiselect__option">
          <Checkbox
            name={props.name}
            handleClick={() => props.setValue(props.value)}
            checked={props.isSelected}
            labelText={props.label}
          />
        </div>
      );
    }

    return (
      <components.Option {...props}>
        {props.data.__isNew__ ? (
          <label className="no__results">{t("repository.multiselect.noResults")}</label>
        ) : (
          renderCheckbox(props)
        )}
      </components.Option>
    );
  };

  /**
   * Handles change in multiselect input
   */
  function handleChange() {
    onChange(selected);
  }

  /**
   * Determines whether multiselect changed
   * @param values
   * @param target
   * @returns
   */
  function onMultiselectChange(values: any, target: any) {
    const option = target.option;
    if (option && !option.value) {
      canSelectNone && setSelectNone(!selectNone);
    }
    if (target.action === "remove-value") {
      if (!target.removedValue.value) {
        const options = getSelectedWithoutEmptyOption(selected);
        setSelectNone(false);
        setSelected(options);
      }
      const withoutRemoved = selected.filter((el) => el.value !== target.removedValue.value);
      setSelected(withoutRemoved);
      return;
    }
    if (option) {
      if (target.action === "deselect-option") {
        const withoutRemoved = selected.filter((el) => el.value !== target.option.value);
        setSelected(withoutRemoved);
        return;
      }
      if (option.value === "all") {
        if (isAllSelected) {
          setSelected([]);
          setAllSelected(false);
        } else {
          setSelected([
            {
              value: "all",
              label: t("repository.table.multiselect.all"),
            },
          ]);
          setAllSelected(true);
        }
      } else {
        if (isAllSelected) {
          selected.pop();
          setAllSelected(false);
        }
        selected.push(option);
        setSelected(selected);
        setAllSelected(false);
      }
    }
    if (target.action === "clear") {
      setSelectNone(false);
      setSelected([]);
      setAllSelected(false);
    }
  }

  return (
    <>
      {isAsync && onloadOptions !== undefined ? (
        <AsyncSelect
          isClearable={true}
          className="repository-multiselect"
          onChange={onMultiselectChange}
          isMulti
          components={{
            Option,
            MultiValueContainer: ValueContainer,
          }}
          styles={customStyles}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          onInputChange={handleChange}
          isSearchable={true}
          placeholder={placeholder || t("search")}
          defaultOptions={_.uniqBy([...additionalOptions, ...selected], "value")}
          loadOptions={onloadOptions}
          value={selected}
        />
      ) : (
        <Select
          isClearable={true}
          className="repository-multiselect"
          onChange={onMultiselectChange}
          isMulti
          components={{
            Option,
            MultiValueContainer: ValueContainer,
          }}
          styles={customStyles}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          onInputChange={handleChange}
          isSearchable={true}
          placeholder={placeholder || t("search")}
          options={[...selectOptions]}
          value={[...selected]}
        />
      )}
    </>
  );
};

export default Multiselect;
