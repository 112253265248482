import React, { useState, useEffect } from "react";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";
import classNames from "classnames";

interface CheckboxType {
  name: string;
  reference?: (ref: Element | null) => void;
  labelText?: string;
  error?: FieldError | FieldError[] | NestDataObject<any> | NestDataObject<any>[] | undefined;
  classes?: string;
  handleClick?: () => void;
  checked?: boolean;
  tabIndex?: number;
  blinking?: boolean | false;
}

const Checkbox: React.FC<CheckboxType> = ({
  name = "",
  reference = null,
  labelText = "",

  children,
  classes = "text-right",
  error = undefined,
  handleClick = () => {},
  checked = false,
  blinking = false,
  tabIndex = 1,
}) => {
  const [checkboxState, setCheckboxState] = useState(checked);
  /**
   * Determines whether change on
   */
  function onChange() {
    setCheckboxState((prevState) => !prevState);
    handleClick();
  }

  useEffect(() => {
    setCheckboxState(checked);
  }, [checked]);

  return (
    <div className={"form-group checkbox custom-checkbox ml-3 " + classes}>
      <input
        className={`custom-control-input`}
        type="checkbox"
        tabIndex={tabIndex}
        data-testid={name}
        onChange={onChange}
        name={name}
        id={name}
        checked={checkboxState}
        ref={reference}
      />
      <label
        onClick={onChange}
        className={classNames("custom-control-label form-check-label text-left", {
          "text-danger": error,
          blink: blinking,
        })}>
        {labelText || children}
      </label>
    </div>
  );
};

export default Checkbox;
