import Routes from "./routes/routes";
import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import Subscriber from "components/Subscriber/Subscriber";

export const Layout: React.FC = () => {
  const { isPending } = useSelector((store: any) => store.ui);

  return (
    <div className="app min-vh-100">
      <main className="min-vh-100 d-flex flex-column">
        {/* After the app gets on production and is all set up just get rid if the unhappy path. */}
        {process.env.REACT_APP_ENVIORONMENT === "prod" ? (
          <Subscriber />
        ) : (
          <>
            <Routes />
            {isPending && (
              <div className="w-100 h-100 position-absolute overflow-hidden blurred-background">
                <Spinner
                  className="spinner-border position-absolute global-spinner"
                  animation="border"
                  style={{ zIndex: 1000 }}
                  role="status"
                />
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
};
