import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import useAuthentication from "hooks/useAuthentication";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";
import { orderSubscription } from "store/thunk/CompanyThunk";
import "../../pages/SubscriptionsPage/SubscriptionsPage.scss";

const PricingComponent = () => {
  const { t } = useTranslation();
  const { user, authenticated, activeMembership } = useAuthentication();
  const dispatch = useDispatch();
  const history = useHistory();
  const { setMessage } = useMessage();

  /**
   * Redirects to
   * @param path
   */
  function redirectTo(path: string) {
    history.push(path);
  }

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, subscription: string) => {
    e.preventDefault();
    try {
      await dispatch(orderSubscription(subscription));
      setMessage(NPWSStatus.SUBSCRIPTION_ORDER_SUCCESS);
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  };

  const orderButton = (onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => {
    return (
      <div className="form-group d-flex justify-content-end">
        <button onClick={onClick} className="btn primary mr-3">
          {t("subscription.order")}
        </button>
      </div>
    );
  };

  const renderButton = (subscription: string) => {
    if (!authenticated) {
      return orderButton(() => {
        redirectTo("/login");
        setMessage(NPWSStatus.SUBSCRIPTION_LOGIN_TO_ORDER);
      });
    } else if (activeMembership?.company.subscription === "free") {
      return orderButton((e) => {
        onSubmit(e, subscription);
      });
    }
  };

  return (
    <div className="row">
      <div className="col">
        <h4 className="first-chapter__pricing__header mt-4 mb-0 "> {t("subscription.secondTitle")}</h4>
        <div className="row">
          <div className="col-12  p-3 mt-4 offer-col__starter border">
            <span className="first-chapter__header mr-2"> {t("subscription.sub.starter")}</span>
            <ul style={{ marginTop: "0.7rem" }}>
              <li>{t("subscription.sub.starter.body.first")}</li>
              <li>{t("subscription.sub.starter.body.second")}</li>
              <li>{t("subscription.sub.starter.body.third")}</li>
              <li>{t("subscription.sub.starter.body.fourth")}</li>
              <li>{t("subscription.sub.starter.body.fifth")}</li>
            </ul>
          </div>
          <div className="col-12 p-3 mt-packages offer-col__basic border">
            <span className="first-chapter__header mr-2"> {t("subscription.sub.basic")}</span>
            <ul style={{ marginTop: "0.7rem" }}>
              <li>{t("subscription.sub.basic.body.first")}</li>
              <li>{t("subscription.sub.basic.body.second")}</li>
              <li>{t("subscription.sub.basic.body.third")}</li>
              <li>{t("subscription.sub.basic.body.fourth")}</li>
              {renderButton(t("subscription.sub.basic.name"))}
            </ul>
          </div>
          <div className={"col-12 p-3 mt-packages offer-col__pro border"}>
            <span className="first-chapter__header mr-2"> {t("subscription.sub.pro")}</span>
            <ul style={{ marginTop: "0.7rem" }}>
              <li>{t("subscription.sub.pro.body.first")}</li>
              <li>{t("subscription.sub.pro.body.second")}</li>
              <li>{t("subscription.sub.pro.body.third")}</li>
              <li>{t("subscription.sub.pro.body.fourth")}</li>
              {renderButton(t("subscription.sub.pro.name"))}
            </ul>
          </div>
          <div className={"col-12 offer-col__unlimited border p-3 mt-packages mb-6"}>
            <span className="first-chapter__header mr-2"> {t("subscription.sub.unlimited")}</span>
            <ul style={{ marginTop: "0.7rem" }}>
              <li>{t("subscription.sub.unlimited.body.first")}</li>
              <li>{t("subscription.sub.unlimited.body.second")}</li>
              <li>{t("subscription.sub.unlimited.body.third")}</li>
              <li>{t("subscription.sub.unlimited.body.fourth")}</li>
              {renderButton(t("subscription.sub.unlimited.name"))}
            </ul>
          </div>
        </div>
        <br />
        <p className="text-center">
          <img src="/icons/tip-icon@2x.png" height="40" className="image" /> {t("subscription.footer")}
          <a href="mailto:kontakt@eprodukty.gs1.pl">kontakt@eprodukty.gs1.pl</a>
        </p>
      </div>
    </div>
  );
};
export default PricingComponent;
