import { CurrentUser, Membership } from "../../interfaces/UserInterface";

export const SET_SESSION_ACTION = "account.setSession";
export const UPDATE_SESSION_ACTION = "account.updateSession";
export const REMOVE_SESSION_ACTION = "account.removeSession";

export const ACTIVATION_SUCCESS = "activationSuccess";
export const PASSWORD_RESET_SUCCESS = "passwordResetSuccess";

export const SET_ACTIVE_MEMBERSHIP = "setActiveMembership";

interface SetSessionAction {
  type: typeof SET_SESSION_ACTION;
  payload: {
    user: CurrentUser;
  };
}

interface UpdateSessionAction {
  type: typeof UPDATE_SESSION_ACTION;
  payload: {
    user: CurrentUser;
  };
}

interface RemoveSessionAction {
  type: typeof REMOVE_SESSION_ACTION;
}

interface SetActiveMembership {
  type: typeof SET_ACTIVE_MEMBERSHIP;
  payload: {
    activeMembership: Membership;
  };
}

/**
 * Sets session
 * @param user
 * @returns session
 */
export function setSession(user: CurrentUser): SetSessionAction {
  return {
    type: SET_SESSION_ACTION,
    payload: {
      user,
    },
  };
}

/**
 * Updates session
 * @param user
 * @returns session
 */
export function updateSession(user: CurrentUser): UpdateSessionAction {
  return {
    type: UPDATE_SESSION_ACTION,
    payload: {
      user,
    },
  };
}

/**
 * Removes session
 * @returns session
 */
export function removeSession(): RemoveSessionAction {
  return {
    type: REMOVE_SESSION_ACTION,
  };
}

/**
 * Sets active membership
 * @param activeMembership
 * @returns active membership
 */
export function setActiveMembership(activeMembership: Membership): SetActiveMembership {
  return {
    type: SET_ACTIVE_MEMBERSHIP,
    payload: {
      activeMembership,
    },
  };
}

export type AccountAction = SetSessionAction | UpdateSessionAction | RemoveSessionAction | SetActiveMembership;
