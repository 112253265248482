import React from "react";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";
import classNames from "classnames";

type Option = { option: string; value: string; isHeader?: boolean };

interface InputType {
  name: string;
  error?: FieldError | FieldError[] | NestDataObject<any> | NestDataObject<any>[] | undefined;
  reference?: (ref: Element | null) => void;
  type?: string;
  labelText?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  options: Option[]; // Modified to include `isHeader` property
  tabIndex?: number;
}

const Select: React.FC<InputType> = ({
  name = "",
  error = undefined,
  reference = (ref: Element | null) => {},
  type = "text",
  labelText = "",
  placeholder = "",
  value = "",
  children,
  disabled = false,
  options,
  tabIndex = 1,
}) => {
  return (
    <div className={classNames("form-group mb-0 select", { "margin-correct": error })}>
      <label
        className={classNames("select-label", { "text-danger": error, "label-disabled": disabled })}
        htmlFor={name}
      >
        {labelText}
      </label>
      <div className={classNames("d-flex improved-input", { error: error })}>
        <select
          name={name}
          className={classNames("form-control rounded-0 ", { "border border-danger": error }, { "mr-2": children })}
          id={name}
          defaultValue=""
          data-testid={name}
          ref={reference}
          tabIndex={tabIndex}
          disabled={disabled}
        >
          {placeholder && (
            <option value="" className="disabled optional" disabled>
              {placeholder}
            </option>
          )}
          {options.map(({ value, option, isHeader }: Option) => (
            <option
              key={value}
              value={isHeader ? "" : value} // Set empty value for headers
              disabled={isHeader} // Disable header options
              className={isHeader ? "header-option" : ""}
            >
              {option}
            </option>
          ))}
        </select>
        {children}
      </div>
      {error && <p className="text-right text-danger mb-0 input-error">{(error! as FieldError)!.message}</p>}
    </div>
  );
};

export default Select;
