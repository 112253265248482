import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import useMessage from "hooks/useMessageStatus";
import { useDispatch } from "react-redux";
import NPWSStatus from "utils/statuses";
import useAuthentication from "../../hooks/useAuthentication";
import * as CatalogThunk from "../../store/thunk/CatalogThunk";
import LimitsLeftComponent from "../LimitsLeftComponent/LimitsLeftComponent";

interface CompanyDetailsLabel {
  company: any;
  showIsAuthorized?: boolean;
  showApiKey?: boolean;
}

const CompanyDetailsLabel: React.FC<CompanyDetailsLabel> = ({
  company,
  showIsAuthorized = true,
  showApiKey = false,
}) => {
  const { t } = useTranslation();
  const { activeMembership } = useAuthentication();
  const dispatch = useDispatch();
  const { setMessage } = useMessage();

  /**
   * Gets full address
   * @param {
   *     street = "",
   *     city = "",
   *     postalCode = "",
   *   }
   * @returns
   */
  function getFullAddress({
    street = "",
    city = "",
    postalCode = "",
  }: {
    street?: string;
    city?: string;
    postalCode?: string;
  }) {
    if (!street && !city && !postalCode) {
      return "-";
    } else {
      return `${street || "-"}, ${postalCode || "-"}, ${city || "-"}`;
    }
  }

  /**
   * Downloads file by id sent in URL params
   */
  async function downloadZipFile() {
    try {
      const response: any = await dispatch(CatalogThunk.downloadZippedCsvFile(activeMembership!));
      const link = document.createElement("a");
      link.href = response.data.url;
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }

  // @ts-ignore
  return company ? (
    <>
      <div className="company-details__container row w-100">
        <div className="col-6 main">
          <p className="main__detail">
            <span>{t("company.details.name")}:</span>
            {company.name || "-"}
          </p>
          {showIsAuthorized && (
            <p className="main__detail">
              <span>{t("company.details.type")}:</span>
              {company.isAuthorized ? t("company.details.type.authorized") : t("company.details.type.notAuthorized")}
            </p>
          )}
          <p className="main__detail">
            <span>{t("regon")}:</span>
            {company.regon || "-"}
          </p>
          <p className="main__detail">
            <span>{t("nip")}:</span>
            {company.nip || "-"}
          </p>
          <p className="main__detail">
            <span>{t("registrationDate")}:</span>
            {company.activationDate ? moment(company.activationDate).format("DD-MM-YYYY") : "-"}
          </p>
        </div>
        <div className="col-6 main">
          <p className="main__detail">
            <span>{t("address")}:</span>
            {getFullAddress({ ...company })}
          </p>
          <p className="main__detail">
            <span>{t("district")}:</span>
            {company.district || "-"}
          </p>
          <p className="main__detail">
            <span>{t("webPage")}:</span>
            {company.webPage || "-"}
          </p>
          <p className="main__detail">
            <span>{t("profile")}:</span>
            {company.companyProfile !== null ? t(`company.profile.${company.companyProfile}`) : "-"}
          </p>
        </div>
      </div>
      {showApiKey ? (
        <div className="company-details__container row w-100">
          <div className="col-6">
            <label className="font-size-medium">{t("companyApiKey")}:</label>
            <p className="main__detail font-size-medium">{company.apiKey || "-"}</p>
            <br />
            <p>
              {t("downloadApiDocsV2")}
              <a href={`/external_api/v2/schema/?company_id=${company.id}`} target="_blank">
                <img src="/icons/download2.png" />{" "}
              </a>
            </p>
            <p>
              {t("downloadApiDocsV2Swagger")}

              <a href={`/external_api/v2/schema/swagger-ui/?company_id=${company.id}`} target="_blank">
                <img src="/icons/link.png" />{" "}
              </a>
            </p>
          </div>
          <div className="col-6" hidden={!company.monetizationSwitch}>
            <LimitsLeftComponent company={company} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  ) : null;
};

export default CompanyDetailsLabel;
