import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import ProductsTable from "components/ProductsTable/ProductsTable";
import * as DataThunk from "store/thunk/DataThunk";
import moment from "moment";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";
import useAuthentication from "../../hooks/useAuthentication";
import { Membership } from "interfaces/UserInterface";
import { getVerificationFile } from "services/DataService";

type NewStatus = 0;
type InProgressStatus = 1;
type DoneStatus = 2;
type ErrorStatus = -1;

type Status = NewStatus | InProgressStatus | DoneStatus | ErrorStatus;

interface Product {
  id: string;
  fileName: string;
  createdAt: string;
  processingStatus: Status;
  userEmail: string;
  fileUrl: string;
}

interface ActionsManagerProps {
  product: Product;
  t: i18next.TFunction;
  activeMembership: Membership;
}

async function downloadFile(fileId: string, activeMembership: Membership) {
  const response: any = await getVerificationFile(activeMembership, fileId);
  const fileName = decodeURIComponent(response.headers["content-disposition"]?.match(/utf-8''(.+)/i)[1] || "file.xls");
  const contentType = response.headers["content-type"] || "application/vnd.ms-excel";
  const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}

function checkDates(verificationDate: string) {
  const days = 86400000; //no of miliseconds in a day
  let difference = Math.round((Date.now() - Date.parse(verificationDate)) / days);
  if (difference > 30) {
    return true;
  } else return false;
}

const ActionsManager: React.FC<ActionsManagerProps> = ({ product, t, activeMembership }) => {
  switch (product.processingStatus) {
    case -1:
      return (
        <span>
          <img className="mr-2" height={16} width={16} src="/icons/error.svg" alt="upload file icon" />
          {t("error")}
        </span>
      );
    case 0:
      return <span>{t("new")}</span>;
    case 1:
      return (
        <span className="d-flex align-items-center">
          <img className="mr-2" height={16} width={16} src="/icons/ic-round-access-time.svg" alt="upload file icon" />
          {t("processing")}
        </span>
      );
    case 2:
      return (
        <button
          className="btn primary"
          disabled={checkDates(product.createdAt)}
          onClick={() => downloadFile(product.id, activeMembership)}
        >
          <span className="ml-1">{t("download")}</span>
        </button>
      );
    default:
      return <>t("wrong.status")</>;
  }
};

const MyProductsPage: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setMessage } = useMessage();
  const { activeMembership } = useAuthentication();
  const columns = [
    {
      Header: t("table.column.name"),
      accessor: "fileName",
    },
    {
      Header: t("table.column.date"),
      id: "createdAt",
      accessor: (product: Product) => {
        const date = moment(new Date(product.createdAt));
        return date.format("YYYY-MM-DD H:mm");
      },
    },
    {
      Header: t("table.column.email"),
      accessor: "userEmail",
    },
    {
      Header: t("table.column.action"),
      id: "test",
      accessor: (product: Product) => {
        return ActionsManager({ product, t, activeMembership: activeMembership! });
      },
    },
  ];

  async function getUserProducts(id: string) {
    const { data }: any = await dispatch(DataThunk.getUserProducts(activeMembership!, id));
    setProducts(data.records);
  }

  useEffect(() => {
    if (activeMembership) {
      try {
        getUserProducts(activeMembership.company.id!);
      } catch (err) {
        setMessage(NPWSStatus.GENERIC_ERROR);
      }
    }
  }, []);

  useEffect(() => {
    activeMembership && getUserProducts(activeMembership.company.id!);
  }, [activeMembership]);

  return (
    <div className="my-products-page">
      <div className="col-12">
        <p>{t("myProducts.filesAvailableFor30Days")}</p>
        {products && products.length ? (
          <ProductsTable products={products} columns={columns} />
        ) : (
          <h2 data-testid="noResults">{t("table.h2.noProducts")}</h2>
        )}
      </div>
    </div>
  );
};

export default MyProductsPage;
