import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import _ from "lodash";
import queryString from "query-string";
import useMessage from "hooks/useMessageStatus";
import * as AccountThunk from "store/thunk/AccountThunk";
import { changeUserPermission, inviteNewUserToCompany } from "../../store/thunk/AccountThunk";
import { State } from "interfaces/StoreInterface";
import NPWSStatus from "utils/statuses";
import InviteNewUserModal from "../../components/InviteNewUserModal/InviteNewUserModal";
import ChangePermissionModal from "../../components/ChangePermissionModal/ChangePermissionModal";

import { initialMembershipState, Membership } from "../../interfaces/UserInterface";
import TablePagination from "components/tablePagination/TablePagination";
import { useHistory } from "react-router-dom";
import TooltipWrapper from "components/Tooltip/Tooltip";
import CompanyDetailsLabel from "../../components/CompanyDetailsLabel/CompanyDetailLabel";
import { CompanyData } from "../../store/actions/RegistrationActions";

export interface MembershipData {
  records: MembershipRecord[];
  numPages: number;
  numAllRecords: number;
}

type MembershipRecord = {
  id: string;
  user: { firstName: string; lastName: string; email: string; phoneNumber: string };
  permissionGroup: string;
  company: object;
  isCompanyRepresentative: boolean;
};

type QueryProps = { [key: string]: any };

const defaultQueryProps: QueryProps = {
  page_size: 10,
  page: 0,
};

const UsersListPage: React.FC = () => {
  const { t } = useTranslation();
  const { setMessage } = useMessage();
  const [data, setData] = useState<MembershipData>();
  const [company, setCompany] = useState<CompanyData>();
  const [selectedUserMembership, setSelectedUserMembership] = useState<Membership>(initialMembershipState);

  const [showModal, setShowModal] = useState({
    changePermissionModal: false,
    inviteNewUserModal: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [allUsers, setAllUsers] = useState(0);
  const searchProps: { [key: string]: any } = queryString.parse(history.location.search);
  const [queryProps, setQueryProps] = useState<QueryProps>(searchProps);
  const { activeMembership, user } = useSelector((state: State) => state.account);
  // setCompany(useSelector((store: any) => store.account.activeMembership.company));

  /**
   * Handles close ivitation
   * @param email
   * @param permissionGroup
   * @param save
   */
  async function handleCloseIvitation(email: string, permissionGroup: string, save: boolean) {
    if (save && activeMembership !== null) {
      try {
        await dispatch(inviteNewUserToCompany(activeMembership!, email, permissionGroup));
        await getMembershipsFromCompany(activeMembership, queryProps); // Reload data in the table
        setMessage(NPWSStatus.INVITE_USER_SUCCESS);
      } catch (err) {
        setMessage(NPWSStatus.USER_ALREADY_INVITED);
      }
    }
    setShowModal((prevState) => ({ ...prevState, inviteNewUserModal: false }));
  }

  /**
   * Handles close permission change
   * @param permissionGroup
   * @param save
   */
  async function handleClosePermissionChange(permissionGroup: Membership["permissionGroup"] | null, save: boolean) {
    if (selectedUserMembership!.permissionGroup !== permissionGroup) {
      if (save && selectedUserMembership!.id !== "") {
        try {
          await dispatch(changeUserPermission(activeMembership!, permissionGroup!, selectedUserMembership!.id));
          getMembershipsFromCompany(activeMembership!, {}); // Reload data in the table
          setMessage(NPWSStatus.USER_PERMISSION_CHAGE_SUCCESS);
        } catch (err) {
          if (err.response.status === 400) {
            setMessage(NPWSStatus.CHANGE_PERMISSION_LAST_USER_WITH_REPRESENTATION_ERROR);
          } else {
            setMessage(NPWSStatus.GENERIC_ERROR);
          }
        }
      }
    } else {
      setMessage(NPWSStatus.USER_PERMISSION_NOT_CHANGED);
    }

    setShowModal((prevState) => ({ ...prevState, changePermissionModal: false }));
  }

  /**
   * Updates page state
   * @param page
   */
  function updatePageState(page: number) {
    const tableProps = {
      ...defaultQueryProps,
      ...queryProps,
    };
    setQueryProps({ ...tableProps, page });
  }

  /**
   * Gets memberships from company
   * @param activeMembership
   * @param queryProps
   */
  async function getMembershipsFromCompany(activeMembership: Membership, queryProps: QueryProps) {
    try {
      const { data }: any = await dispatch(
        AccountThunk.getMembershipsFromCompany(
          activeMembership,
          activeMembership.company.id!,
          queryString.stringify(queryProps),
        ),
      );
      if (!data.records.length && queryProps.page > 0) setQueryProps({ ...queryProps, page: 0 });
      setAllUsers(data.numAllRecords);
      setData(data);
      const updatedCompany = data.records.filter((item: any) => {
        if (item.id === activeMembership.id) return item;
      })[0].company;
      setCompany(updatedCompany);
    } catch (err) {}
  }

  useEffect(() => {
    if (!_.isEmpty(queryProps)) {
      activeMembership && getMembershipsFromCompany(activeMembership, queryProps);
      setQueryProps(queryProps);
      history.push({
        pathname: "/users",
        search: queryString.stringify(queryProps),
      });
    } else {
      activeMembership && getMembershipsFromCompany(activeMembership, defaultQueryProps);
      history.push({
        pathname: "/users",
        search: queryString.stringify(defaultQueryProps),
      });
    }
  }, [queryProps]);

  const deleteUser = async (membershipId: string) => {
    try {
      if (activeMembership && activeMembership.company.id) {
        await dispatch(AccountThunk.deleteMembershipById(activeMembership.id, membershipId));
        await getMembershipsFromCompany(activeMembership, queryProps);
      }
      setMessage(NPWSStatus.MEMBERSHIP_DELETED);
    } catch (err) {
      if (err.response.status === 400) {
        setMessage(NPWSStatus.DELETE_LAST_USER_WITH_REPRESENTATION_ERROR);
      } else {
        setMessage(NPWSStatus.GENERIC_ERROR);
      }
    }
  };

  /**
   * options
   * @param state
   * @param rowInfo
   * @returns
   */
  function trOptions(state: any, rowInfo: any) {
    const isActivated = rowInfo.original.activated;
    if (rowInfo && rowInfo.row) {
      return {
        className: !isActivated && "row-disabled",
      };
    } else {
      return {};
    }
  }

  /**
   * Gets full name
   * @param { firstName, lastName }
   * @returns
   */
  function getFullName({ firstName, lastName }: { firstName: string; lastName: string }) {
    return !firstName && !lastName ? "-" : `${firstName} ${lastName}`;
  }

  const columns = [
    {
      id: "name",
      Cell: (data: any) => {
        return (
          <>
            <span>{data.original.user.firstName}</span>
            <br />
            <span>{data.original.user.lastName}</span>
          </>
        );
      },
      headerClassName: "capitalized",
      Header: t(`table.users.name`),
      width: 150,
    },
    {
      id: "email",
      accessor: (data: MembershipRecord) => data.user.email,
      headerClassName: "capitalized",
      Header: t(`table.users.email`),
    },
    {
      id: "role",
      headerClassName: "capitalized",
      Header: t(`table.users.role`),
      Cell: (data: any) => {
        return (
          <>
            {_.isEmpty(data.original.permissionGroup) ? "-" : t(`permission.${data.original.permissionGroup}`)}
            {data.original.isCompanyRepresentative && (
              <>
                <br />
                <small>{t("companies.user.field.authorized")}</small>
              </>
            )}
          </>
        );
      },
      width: 200,
    },
    {
      id: "permissionChange",
      accessor: (data: any) => data,
      Cell: (data: any) => {
        const isLoggedUser = data.value.user.email !== user?.email;
        return isLoggedUser ? (
          <button
            data-testid={`${data.value.user.email}-permissionChange`}
            className="btn btn-outline-secondary rounded-0 capitalized"
            onClick={() => {
              setSelectedUserMembership(data.value);
              setShowModal((prevState) => ({ ...prevState, changePermissionModal: true }));
            }}
          >
            {t("table.users.permissionChange")}
          </button>
        ) : (
          <div></div>
        );
      },
      className: "button--delete",
      width: 175,
    },
    {
      id: "deletetion",
      accessor: (data: any) => data,
      Cell: (data: any) => {
        const isLoggedUser = data.value.user.email !== user?.email;
        const isActive = data.value.activated;
        return isLoggedUser ? (
          <>
            {!isActive && (
              <TooltipWrapper tooltipText={t("table.users.inActive")}>
                <img className="mr-2" src="/icons/icon-help-enabled.svg" />
              </TooltipWrapper>
            )}
            <button
              data-testid={`${data.value.user.email}-deleteButton`}
              className="btn btn-outline-secondary rounded-0 capitalized"
              onClick={() => deleteUser(data.value.id)}
            >
              {t("table.users.delete")}
            </button>
          </>
        ) : (
          <div></div>
        );
      },
      className: "button--delete",
      width: 175,
    },
  ];

  useEffect(() => {
    activeMembership && getMembershipsFromCompany(activeMembership, queryProps);
  }, [activeMembership]);

  return (
    <div>
      <CompanyDetailsLabel company={company} showIsAuthorized={false} showApiKey={false} />
      <div className="row">
        <div className="col-12 py-2 d-flex justify-content-end">
          <button
            data-testid={"inviteNewUser"}
            onClick={() => {
              setShowModal((prevState) => ({ ...prevState, inviteNewUserModal: true }));
            }}
            className="button--inviting btn btn-primary bg-dusty-orange"
          >
            {t("users.invite")}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {data && data?.records?.length ? (
            <ReactTable
              manual={true}
              resizable={false}
              className="users-list"
              sortable={false}
              pageSize={data?.records?.length}
              defaultPageSize={_.ceil(allUsers / 10)}
              pages={_.ceil(allUsers / 10)}
              showPagination={true}
              columns={columns}
              data={data.records}
              getTrProps={trOptions}
              PaginationComponent={TablePagination}
              onPageChange={updatePageState}
            />
          ) : (
            <h2 data-testid="noResults">{t("table.users.empty")}</h2>
          )}
        </div>
      </div>
      <ChangePermissionModal
        show={showModal.changePermissionModal}
        handleClose={handleClosePermissionChange}
        membership={selectedUserMembership!}
      />
      <InviteNewUserModal show={showModal.inviteNewUserModal} handleClose={handleCloseIvitation} />
    </div>
  );
};

export default UsersListPage;
