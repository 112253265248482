import React, { useState, useEffect } from "react";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";
import classNames from "classnames";
import { IMaskMixin } from "react-imask";

interface InputType {
  name: string;
  error: FieldError | FieldError[] | NestDataObject<any> | NestDataObject<any>[] | undefined;
  reference: (ref: Element | null) => void;
  type?: string;
  labelText?: string;
  disabled?: boolean;
  mask?: string | RegExp;
  defaultValue?: string;
  formHooks?: any;
  tabIndex?: number;
  errorTestId?: string;
  handleChange?: (value: string) => void;
  customLabelClassName?: string;
  setTermsAccepted?: (isTermsAccepted: boolean) => void;
}

const InputWithMixin = IMaskMixin(({ inputRef, ...props }: { inputRef: React.Ref<any> }) => (
  <input ref={inputRef} {...props} />
));

const MaskedInput: React.FC<InputType> = ({
  name = "",
  error = undefined,
  reference,
  type = "text",
  labelText = "",
  disabled = false,
  formHooks = {},
  mask, // Format must be like 00-000
  tabIndex = 1,
  errorTestId = "",
  defaultValue,
  handleChange,
  customLabelClassName,
  setTermsAccepted = null,
}) => {
  const { register, setValue } = formHooks;

  const [inputValue, setInputValue] = useState(defaultValue);

  useEffect(() => {
    if (setTermsAccepted) {
      setTermsAccepted(false);
    }
  }, [error]);

  /**
   * Update input for masked component, to aviod loosing registerd input in formik
   * @param value
   */
  function updateInput(value: string) {
    setInputValue(value);
    register(name);
    setValue(name, value);
    if (handleChange) {
      handleChange(value);
    }
  }

  return (
    <div className="form-group mb-0">
      {labelText && (
        <label
          className={classNames(
            "font-weight-bold font-size-medium",
            { "text-danger": error },
            customLabelClassName ? customLabelClassName : null,
          )}
          htmlFor={name}
        >
          {labelText}
        </label>
      )}
      <div className={classNames("d-flex position-relative improved-input", { error: error })}>
        <InputWithMixin
          mask={mask}
          inputRef={reference}
          onAccept={updateInput}
          lazy={false}
          value={inputValue}
          role="input"
          type={type}
          name={name}
          disabled={disabled}
          tabIndex={tabIndex}
          className={"form-control rounded-0 " + (error ? "border border-danger " : "")}
          id={name}
          data-testid={name}
        />
      </div>

      {error && (
        <p data-testid={errorTestId} className={classNames("mb-0 text-right text-danger font-size-small")}>
          {(error! as FieldError)!.message}
        </p>
      )}
    </div>
  );
};

export default MaskedInput;
