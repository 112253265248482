import { ThunkResult } from "interfaces/StoreInterface";
import * as DataService from "../../services/DataService";
import { Membership } from "../../interfaces/UserInterface";

/**
 * Sends data to verify byb GS1
 * @param activeMembership
 * @param file
 * @param description
 * @param membershipId
 * @returns data to verify
 */
export function sendDataToVerify(
  activeMembership: Membership,
  file: any,
  description: string,
  membershipId: string,
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await DataService.sendDataToVerify(activeMembership, file, description, membershipId);
  };
}

/**
 * Gets user products
 * @param activeMembership
 * @param id
 * @returns user products
 */
export function getUserProducts(activeMembership: Membership, id: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await DataService.getUserProducts(activeMembership, id);
  };
}

/**
 * Gets categories
 * @param [limit]
 * @returns categories
 */
export function getCategories(limit: string = "100"): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await DataService.getCategories(limit);
  };
}

export function getProductsCounter(): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    return await DataService.getProductsCounter();
  };
}
