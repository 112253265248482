import { CompanyData } from "store/actions/RegistrationActions";
import { Permissions } from "../utils/constants";

export type PermissionGroup = "location_admin" | "location_user" | "gs1_member";

export interface MembershipUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateJoined: string;
  lastLogin: string | null;
  permissionGroup: PermissionGroup;
  confirmedStatute: boolean;
  agreeToSellingEmails: boolean;
  agreeToSellingPhones: boolean;
  agreeToMarketingEmails: boolean;
  agreeToMarketingPhones: boolean;
  agreeToSharingData: boolean;
}

export const initialMembershipState: Membership = {
  activated: false,
  id: "",
  company: {
    createdAt: undefined,
    registrationDate: undefined,
    id: undefined,
    regon: undefined,
    nip: undefined,
    name: undefined,
    street: undefined,
    city: undefined,
    postalCode: undefined,
    district: undefined,
    country: undefined,
    webPage: undefined,
    companyProfile: undefined,
    isAuthorized: false,
    accessToDownload: undefined,
  },
  user: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dateJoined: "",
    lastLogin: null,
    permissionGroup: "location_user",
    confirmedStatute: false,
    agreeToSellingEmails: false,
    agreeToSellingPhones: false,
    agreeToMarketingEmails: false,
    agreeToMarketingPhones: false,
    agreeToSharingData: false,
  },
  isCompanyRepresentative: false,
  permissions: [],
  permissionGroup: "location_user",
};

export interface Membership {
  activated: boolean;
  id: string;
  company: CompanyData;
  user: MembershipUser;
  isCompanyRepresentative: boolean;
  permissions: Permissions;
  permissionGroup: PermissionGroup;
}

export interface CurrentUser {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  memberships: Membership[];
}
