import React from "react";
import { useTranslation } from "react-i18next";

const ProductCounter: React.FC<{ allProducts: number | undefined }> = ({ allProducts }) => {
  const { t } = useTranslation();
  return (
    <div className="catalog-page__all-results">
      <b>
        {t("catalog.results")} {allProducts}
      </b>
    </div>
  );
};

export default ProductCounter;
