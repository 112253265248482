import classNames from "classnames";
import Checkbox from "components/Inputs/Checkbox";
import Multiselect from "components/Multiselect/Multiselect";
import TablePagination from "components/tablePagination/TablePagination";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OptionsType } from "react-select/src/types";
import ReactTable from "react-table";
import { v4 as uuid } from "uuid";
import useAuthentication from "../../hooks/useAuthentication";
import useMessage from "../../hooks/useMessageStatus";
import CompanyService from "../../services/CompanyService";
import RepositoryService from "../../services/RepositoryService";
import { CompanyData } from "../../store/actions/RegistrationActions";
import NPWSStatus from "../../utils/statuses";

interface Filters {
  companies: string[];
  statuses: string[];
  errors: string[];
  sources: string[];
  showAbandoned: boolean;
}

const initFilters: Filters = {
  companies: [],
  statuses: [],
  errors: [],
  sources: [],
  showAbandoned: false,
};

interface productIssue {
  companyExternalId: string;
  companyName: string;
  numIssues: number;
  issueStatus: string;
  issueError: string;
  dataPoolName: string;
  lastModified: Date;
  assignedUser: string;
  issuesIds: string[];
  checkboxId: string;
}

// @TODO probably we should create global const for this
const EMPTY_VALUE = "-";

const RepositoryPage: React.FC = () => {
  const { t } = useTranslation();
  const { setMessage } = useMessage();
  const [showTable, setShowTable] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>(initFilters);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [data, setData] = useState<productIssue[]>([]);
  const { activeMembership } = useAuthentication();
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const [companiesCheckboxes, setCompaniesCheckboxes] = useState<{ [key: string]: string[] }>({});

  /**
   * Selects all
   */
  function selectAll() {
    if (!selectedAll) {
      setSelectedCheckboxes(Object.entries(companiesCheckboxes).map((key, value) => key[0]));
    } else {
      setSelectedCheckboxes([]);
    }
    setSelectedAll(!selectedAll);
  }

  /**
   * Handles select
   * @param checkboxId
   */
  function handleSelect(checkboxId: string) {
    if (selectedCheckboxes.includes(checkboxId)) {
      const removeValue = selectedCheckboxes.filter((el) => el !== checkboxId);
      setSelectedCheckboxes(removeValue);
      setSelectedAll(false);
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, checkboxId]);
      setSelectedAll(false);
    }
  }

  const companyAdditionalOptions = [
    {
      value: "all",
      label: t("repository.table.multiselect.all"),
    },
    {
      value: "no",
      label: t("repository.table.multiselect.noResult"),
    },
  ];

  /**
   * Maps results to option
   * @param options
   * @returns options in {value, label} format
   */
  function mapResultsToOption(options: CompanyData[]) {
    return options.map((option) => ({
      value: option.id,
      label: option.name,
    }));
  }

  /**
   * Loads companies
   * @param value
   * @param callback
   */
  async function loadCompanies(value: string, callback: (options: OptionsType<any>) => void) {
    if (activeMembership) {
      try {
        const { data } = await CompanyService.searchCompanies(activeMembership, `name__contains=${value}`);
        callback(mapResultsToOption(data.results));
      } catch (e) {
        setMessage(NPWSStatus.GENERIC_ERROR);
      }
    }
  }

  /**
   * Loads datapools
   */
  async function loadDatapools() {
    const { data } = await CompanyService.getDataPools();
    setSourceOptions(data.datapools);
  }

  useEffect(() => {
    try {
      if (sourceOptions.length === 0) {
        loadDatapools();
      }
    } catch (e) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }, []);

  const statusOptions = [
    {
      value: "new_issue",
      label: t("repository.table.statusOptions.new"),
    },
    {
      value: "in_progress_issue",
      label: t("repository.table.statusOptions.inProgress"),
    },
    {
      value: "resolved_issue",
      label: t("repository.table.statusOptions.resolved"),
    },
  ];

  const errorOptions = [
    {
      value: "EP-0003",
      label: t("repository.table.errorOptions.controlNumberError"),
    },
    {
      value: "EP-0005",
      label: t("repository.table.errorOptions.gpcError"),
    },
    {
      value: "EP-0006",
      label: t("repository.table.errorOptions.dataMismatch"),
    },
  ];

  const columns = [
    {
      id: "action",
      accessor: (data: productIssue) => {
        return (
          <Checkbox
            key={data.checkboxId}
            name={data.checkboxId}
            checked={selectedCheckboxes.includes(data.checkboxId) || selectedAll}
            handleClick={() => handleSelect(data.checkboxId)}
          />
        );
      },
      headerClassName: "text-wrap box-shadow-none",
      Header: () => <Checkbox checked={selectedAll} name="all" handleClick={selectAll} />,
    },
    {
      id: "companyId",
      accessor: (data: productIssue) => data.companyExternalId || EMPTY_VALUE,
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("repository.table.header.id"),
    },
    {
      id: "name",
      accessor: (data: productIssue) => data.companyName || EMPTY_VALUE,
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("repository.table.header.name"),
    },
    {
      id: "numberOfErrors",
      accessor: (data: productIssue) => data.numIssues,
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("repository.table.header.countOfErrors"),
    },
    {
      id: "errorType",
      accessor: (data: productIssue) => {
        switch (data.issueError) {
          case "EP-0003":
            return t("repository.table.errorOptions.controlNumberError");
          case "EP-0005":
            return t("repository.table.errorOptions.gpcError");
          case "EP-0006":
            return t("repository.table.errorOptions.dataMismatch");
        }
      },
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("repository.table.header.errorType"),
    },
    {
      id: "source",
      accessor: (data: productIssue) => data.dataPoolName,
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("repository.table.header.source"),
    },
    {
      id: "status",
      accessor: (data: productIssue) => {
        switch (data.issueStatus) {
          case "new_issue":
            return t("repository.table.statusOptions.new");
          case "in_progress_issue":
            return t("repository.table.statusOptions.inProgress");
          case "resolved_issue":
            return t("repository.table.statusOptions.resolved");
          case "abandoned_issue":
            return t("repository.table.statusOptions.abandoned");
        }
      },
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("repository.table.header.status"),
    },
    {
      id: "date",
      accessor: (data: productIssue) => data.lastModified,
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("repository.table.header.date"),
    },
    {
      id: "fullName",
      accessor: (data: productIssue) => <div className="pr-2">{data.assignedUser}</div>,
      headerClassName: "text-wrap box-shadow-none full-name",
      Header: () => t("repository.table.header.person"),
    },
  ];

  /**
   * Files for zj
   * @returns
   */
  function fileForZj() {
    if (activeMembership !== null) {
      let issueIds: string[] = [];
      selectedCheckboxes.forEach((checkbox) => {
        issueIds = issueIds.concat(companiesCheckboxes[checkbox]);
      });
      try {
        RepositoryService.generateIssueFile(issueIds, activeMembership);
        setMessage(NPWSStatus.GENERATE_ISSUE_FILE_SUCCESS);
      } catch (e) {
        setMessage(NPWSStatus.GENERIC_ERROR);
      }
      return;
    }
  }

  /**
   * Shows abandoned gtin
   */
  function showAbandonedGtin() {
    setFilters({ ...filters, showAbandoned: !filters.showAbandoned });
  }

  /**
   * Gets values from multiselect
   * @param options
   * @returns
   */
  function getValuesFromMultiselect(options: { [key: string]: string }[]) {
    return options.map((company) => company.value);
  }

  /**
   * Determines whether company change on
   * @param values
   */
  function onCompanyChange(values: any) {
    const companies = getValuesFromMultiselect(values);
    setFilters({ ...filters, companies });
  }

  /**
   * Determines whether status change on
   * @param values
   */
  function onStatusChange(values: any) {
    const statuses = getValuesFromMultiselect(values);
    setFilters({ ...filters, statuses });
  }

  /**
   * Determines whether error change on
   * @param values
   */
  function onErrorChange(values: any) {
    const errors = getValuesFromMultiselect(values);
    setFilters({ ...filters, errors });
  }

  /**
   * Determines whether source change on
   * @param values
   */
  function onSourceChange(values: any) {
    const sources = getValuesFromMultiselect(values);
    setFilters({ ...filters, sources });
  }

  async function search() {
    if (activeMembership) {
      const { showAbandoned, ...params } = filters;
      const selectedFilters = Object.values(params).flat();
      const { data } = await CompanyService.getIssues(activeMembership, filters, page);
      const records = data.records.map((row: productIssue) => {
        row.checkboxId = uuid();
        return row;
      });

      records.forEach((record: productIssue) => {
        companiesCheckboxes[record.checkboxId] = record.issuesIds;
      });
      setCompaniesCheckboxes(companiesCheckboxes);
      setData(records);
      setPages(_.ceil(data.pages / defaultPageSize));
      if (selectedFilters.length || showAbandoned) {
        setShowTable(true);
      } else {
        setShowTable(false);
      }
    }
  }

  useEffect(() => {
    function keyPress(e: KeyboardEvent) {
      if (e.key === "Enter") {
        search();
      }
    }
    document.addEventListener("keydown", keyPress);
    return () => {
      document.removeEventListener("keydown", keyPress);
    };
  }, [filters]);

  /**
   * Determines whether page change on
   * @param page
   */
  function onPageChange(page: number) {
    setPage(page);
  }

  useEffect(() => {
    search();
  }, [page]);

  return (
    <div className="repository__container">
      <div className="repository">
        <div className="row">
          <div className="col-3">
            <label className="multiselect__label">{t("repository.label.company")}</label>
            <Multiselect
              isAsync={true}
              onloadOptions={loadCompanies}
              onChange={onCompanyChange}
              canSelectNone={true}
              selectAll={true}
              options={[]}
              additionalOptions={companyAdditionalOptions}
            />
          </div>
          <div className="col-3">
            <label className="multiselect__label">{t("repository.label.status")}</label>
            <Multiselect onChange={onStatusChange} options={statusOptions} />
          </div>
          <div className="col-3">
            <label className="multiselect__label">{t("repository.label.errorType")}</label>
            <Multiselect onChange={onErrorChange} options={errorOptions} />
          </div>
          <div className="col-3">
            <label className="multiselect__label">{t("repository.label.source")}</label>
            <Multiselect isAsync={true} onChange={onSourceChange} options={sourceOptions} />
          </div>
        </div>
        <div className="row my-4">
          <div className="col-12 d-flex justify-content-end">
            <Checkbox
              handleClick={showAbandonedGtin}
              checked={filters.showAbandoned}
              classes="mr-4 mb-0"
              name="showAbandoned"
            >
              {t("repository.table.abandonedGTIN")}
            </Checkbox>
            <button onClick={search} className="btn primary">
              {t("search")}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {showTable && data.length && (
              <ReactTable
                manual={true}
                columns={columns}
                data={data}
                PaginationComponent={TablePagination}
                showPagination={true}
                pageSize={defaultPageSize}
                resizable={false}
                className="repository__table"
                sortable={false}
                defaultPageSize={defaultPageSize}
                pages={pages}
                onPageChange={onPageChange}
              />
            )}
          </div>
          <div className="col-12 d-flex justify-content-end">
            <button
              disabled={!selectedCheckboxes.length}
              onClick={fileForZj}
              className={classNames("btn primary mr-4")}
            >
              {t("repository.fileFor.zj")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepositoryPage;
