import React from "react";
import i18n from "i18n/i18n";

const Tooltips = () => {
  const t = (value: string) => i18n.t(value);

  /**
   * Return JSX with passwords requirments "popup"
   * @returns
   */
  function PasswordRequirments() {
    return (
      <div>
        {t("reset.password.requirements")}
        <p>- {t("reset.password.chars")}</p>
        <p>- {t("reset.password.letters")}</p>
        <p>- {t("reset.password.pattern")}</p>
      </div>
    );
  }

  return {
    PasswordRequirments,
  };
};

const NPWSTooltips = Tooltips();

export default NPWSTooltips;
