import React from "react";
import { useTranslation } from "react-i18next";
import ActivateAccountFormProps from "interfaces/ActivationInterface";

const OnlyButtonActivateAccountForm: React.FC<ActivateAccountFormProps> = ({ formSubmit }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="container ">
        <div className="row">
          <div className="col-12 px-0">
            <form onSubmit={formSubmit} className={`form-group d-flex justify-content-end`}>
              <button data-testid="activeSubmit" className="btn primary" type="submit">
                {t("activate")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default OnlyButtonActivateAccountForm;
