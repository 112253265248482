import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Checkbox from "components/Inputs/Checkbox";
import useAuthentication from "hooks/useAuthentication";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { DATABASE_DOWNLOAD_ACCESS } from "utils/constants";
import { hasLimitLeft, haveAccess } from "utils/permissions";
import DownloadDBButton from "../../components/CatalogPage/DownloadDBButton";
import { GCPData } from "../../interfaces/GCPData";
import { Membership } from "../../interfaces/UserInterface";
import { generateCatalogSchema } from "./CatalogPageValidation";
import { useEffect, useState } from "react";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";

interface CatalogActionButtonsProps {
  isFormDirty: boolean;
  hasProducts: boolean;
  getDatabase: () => void;
  setGcpData: React.Dispatch<GCPData | null | string>;
  isTermsAccepted: boolean;
  setTermsAccepted: (isTermsAccepted: boolean) => void;
  allProducts?: number;
}

const CatalogPageActionButtons: React.FC<CatalogActionButtonsProps> = ({
  isFormDirty,
  getDatabase,
  hasProducts,
  setGcpData,
  isTermsAccepted,
  setTermsAccepted,
  allProducts,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { t } = useTranslation();
  const { user, activeMembership } = useAuthentication();

  const getActiveMembership: () => Membership | undefined | null = () => {
    let returnValue: Membership | undefined | null;
    returnValue = user?.memberships?.find((membership) => membership.id === localStorage.getItem("activeId"));
    if (!returnValue && user?.memberships) returnValue = user?.memberships[0];
    return returnValue;
  };

  const {
    formState: { isValid },
  } = useForm({
    validationSchema: generateCatalogSchema(t, setGcpData, executeRecaptcha, getActiveMembership()),
    reValidateMode: "onSubmit",
  });

    const [showWarningOverLimit, setShowWarningOverLimit] = useState(false);
    const [messageSet, setMessageSet] = useState(false); // Flag to track message status

  const { setMessage, removeMessage } = useMessage();


  const PACKAGES = {
    "free": { verify: 25000, api: 0 },
    "monthly": { verify: 100_000, api: 25000 },
    "yearly_limited": { verify: 6_000_000, api: 1_000_000 },
  };
  
  const creditsAvailable = (
    subscriptionType?: string,
    usedCount?: number,
    totalCount?: number
  ): boolean => {
    if (subscriptionType == "yearly_unlimited") {
      return true;
    }
    if (!subscriptionType || !(subscriptionType in PACKAGES)) {
      console.warn(`Invalid subscription type: ${subscriptionType}`);
      return true;
    }
  
    const packageInfo = PACKAGES[subscriptionType as keyof typeof PACKAGES];
    const verifyLimit = packageInfo.api;
    const used = usedCount ?? 0;
    const total = totalCount ?? 0;
    if (typeof verifyLimit !== "number") {
      console.warn(`Invalid verify limit for subscription type ${subscriptionType}: ${verifyLimit}`);
      return true;
    }
    const remainingCredits = verifyLimit - used - total;
    return remainingCredits > 0;
  };
  
  let isOverLimit = false;

  let usedCredits = (activeMembership?.company?.counterCsv || 0) + (activeMembership?.company?.counterAPI || 0)

  try {
    isOverLimit = !creditsAvailable(
      activeMembership?.company?.subscription,
      usedCredits,
      allProducts
    );

  } catch (error) {
    console.error("Error calculating remaining credits:", error);
    isOverLimit = false; // Set to false if there was an error
  }
  // TO DEBUG:
  //isOverLimit = typeof allProducts === 'number' && allProducts > 200;

  const dbButtonDisabled = ((!isFormDirty || !isValid) && !hasProducts);

  const handleDownload = () => {
    if (activeMembership?.company?.subscription === "free") {
      setMessage(NPWSStatus.NO_DOWNLOAD_FOR_THIS_PACKAGE);
    }
    else if (isOverLimit) {
      setMessage({
        ...NPWSStatus.OVERLIMIT_DOWNLOAD_DB,
        message: `${NPWSStatus.OVERLIMIT_DOWNLOAD_DB.message}`,
      });
    } else {
      getDatabase();
    }
  };

  return (
    <>
      <div className="col-12 mt-3 d-flex flex-column justify-content-end align-items-center">
        <div style={{ width: "20%" }}>
          <button
            className={`btn primary border bg-color-white txt-color-dusty-orange px-4 mb-3 ${
              !isFormDirty || (!activeMembership && !isTermsAccepted) ? "disabled" : ""
            }`}
            data-testid="searchButton"
            type="submit"
            style={{ width: "100%" }}>
            {t("search")}
          </button>
        </div>
        <DownloadDBButton
          disabled={dbButtonDisabled}
          company={activeMembership?.company}
          getDatabase={handleDownload}
          showWarningOverLimit={isOverLimit || activeMembership?.company?.subscription === "free"}
        />
      </div>
    </>
  );
};

export default CatalogPageActionButtons;
