import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

interface DownloadDBButtonProps {
  disabled?: boolean;
  company?: any;
  getDatabase: () => void;
  allProducts?: number;
  showWarningOverLimit?: boolean;
}

const DownloadDBButton: React.FC<DownloadDBButtonProps> = ({ 
  disabled,
  getDatabase,
  allProducts,
  showWarningOverLimit,
}) => {
  const { t } = useTranslation();

  let styleButton: CSSProperties = {}

  if (showWarningOverLimit) {
    styleButton = {
      opacity: 0.5,
      cursor:  "not-allowed",
      backgroundColor: "#f0f0f0",
      color:  "#999",
      boxShadow:  "none",
    } 
  } 

  const styleDivWrapper: CSSProperties = {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  };

  return (
    <div style={styleDivWrapper}>
        <button
          className="btn border bg-color-white txt-color-dusty-orange"
          type="button"
          onClick={getDatabase}
          style={styleButton}
          disabled={disabled}
        >
          <i className="fas fa-download mr-3"></i>
          {t("downloadDatabase")}
        </button>
      
    </div>
  );
};

export default DownloadDBButton;
