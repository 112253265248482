import React, { useEffect, useState } from "react";
import { WithWizard, Wizard } from "react-albus";
import { useForm } from "react-hook-form";
import Input from "components/Inputs/Input";
import MaskedInput from "components/Inputs/MaskedInput";
import { useTranslation } from "react-i18next";
import {
  disablePersonalDataForm,
  RegistrationUserData,
  setEmailChecked,
  updateRegistrationUserData,
} from "store/actions/RegistrationActions";
import Checkbox from "components/Inputs/Checkbox";
import generatePersonalDataSchema, { generateEmailSchema } from "./PersonalDataValidation";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsByEmail } from "store/thunk/RegistrationThunk";
import { EmailValidator, flattenObject } from "utils";
import FileInput from "components/Inputs/FileInput";
import PersonalDataLegals from "./PersonalDataLegals";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { AppState } from "../../../store/reducers/RootReducer";
import { debounce } from "../../../utils/debounce";
import classNames from "classnames";
import Agreements from "components/Agreements/Agreements";

const clearPersonalForm = {
  user: {
    firstName: "",
    lastName: "",
    phoneNumber: "+48 ___-___-___",
    confirmedStatute: "",
    agreeToSellingEmails: "",
    agreeToSellingPhones: "",
    agreeToSharingData: "",
    agreeToMarketingEmails: "",
    agreeToMarketingPhones: "",
  },
  isCompanyRepresentative: "",
  file: "",
};

interface PersonalDataProps {
  formData: RegistrationUserData;
  prevStep: () => void;
  nextStep: () => void;
}

const PersonalData: React.FC<PersonalDataProps> = ({ formData, prevStep = () => {}, nextStep = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isEmailPending, setIsEmailPending] = useState<boolean>(false);
  const [uploadFile, setUploadFile] = useState(formData.file);
  const [fileName, setFileName] = useState(formData?.file?.name || "");
  const [checkAllValue, setCheckAllValue] = useState(false);
  const { emailChecked, disablePersonalForm, company, disableCompanyDataForm } = useSelector(
    (state: AppState) => state.registrationForm,
  );
  const form = useForm({
    defaultValues: formData,
    validationSchema: disablePersonalForm ? generateEmailSchema(t) : generatePersonalDataSchema(t),
    mode: "onChange",
  });
  const { handleSubmit, register, errors, triggerValidation, getValues, setValue, formState, reset, watch } = form;
  const [secureInput, setSecureInput] = useState<string>("text");

  const checkEmailDebounce = debounce(
    "checkEmail",
    async function () {
      await checkEmail();
    },
    1500,
  );

  /**
   * Callback to go to next step in registartion and save data to redux
   * @param data
   */
  async function onSubmit(data: any) {
    dispatch(updateRegistrationUserData({ ...data, file: uploadFile }));
    nextStep();
  }

  /**
   * Go to prev step and save data to redux
   */
  function goToPrevStep() {
    const data: any = getValues({ nest: true });
    dispatch(updateRegistrationUserData(data));
    prevStep();
  }

  /**
   * update blobs
   * @param blob
   */
  const updateFile = (blob: { [key: string]: any }) => {
    setUploadFile(blob.file);
  };

  /**
   * Checks email address if it's valid
   */
  async function checkEmail() {
    const {
      user: { email },
    } = getValues({ nest: true });
    const isValid = EmailValidator(email as string);
    if (isValid) {
      const userData: any = await dispatch(getUserDetailsByEmail(email as string));
      const { user, ...rest }: any = getValues({ nest: true });
      if (userData) {
        setSecureInput("password");
        dispatch(
          updateRegistrationUserData({
            ...rest,
            user: {
              ...userData,
              email,
              agreeToSellingEmails: userData.agreeToSellingEmails?.toString(),
              agreeToSellingPhones: userData.agreeToSellingPhones?.toString(),
              agreeToSharingData: userData.agreeToSharingData?.toString(),
              agreeToMarketingEmails: userData.agreeToMarketingEmails?.toString(),
              agreeToMarketingPhones: userData.agreeToMarketingPhones?.toString(),
            },
          }),
        );
        dispatch(disablePersonalDataForm(true));
        dispatch(setEmailChecked(true));
      } else {
        setSecureInput("text");
        dispatch(disablePersonalDataForm(false));
        setCheckAllValue(false);
        dispatch(
          updateRegistrationUserData({
            ...rest,
            ...clearPersonalForm,
            user: {
              ...user,
              ...clearPersonalForm.user,
            },
          }),
        );
        dispatch(setEmailChecked(false));
      }
    } else {
      const { user, ...rest }: any = getValues({ nest: true });
      dispatch(disablePersonalDataForm(true));
      setCheckAllValue(false);
      dispatch(
        updateRegistrationUserData({
          ...rest,
          ...clearPersonalForm,
          user: {
            ...user,
            ...clearPersonalForm.user,
          },
        }),
      );
    }
    setIsEmailPending(false);
  }

  useEffect(() => {
    setSecureInput(secureInput);
  }, [secureInput]);

  /**
   * Determines whether all agreements is true
   * @returns true if all agreements true
   */
  function isAllAgreementsTrue(): boolean {
    const {
      user: { firstName, lastName, email, confirmedStatute, phoneNumber, ...agreements },
    } = getValues({
      nest: true,
    });
    return Object.values(agreements).every((el) => el === "true");
  }

  useEffect(() => {
    const { file, ...restFormData } = formData;
    const flattendData = flattenObject(restFormData);
    flattendData.forEach(({ key, value }) => setValue(key, value));
    isAllAgreementsTrue() && setCheckAllValue(true);
  }, [formData]);

  useEffect(() => {
    isAllAgreementsTrue() && setCheckAllValue(true);
  }, []);

  return (
    <Wizard>
      <WithWizard
        render={() => (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="personal-data">
              <div className="col-12 mb-3">
                <h2>{t("registration.form.personal.h2")}</h2>
              </div>
              <div className="col-md-6 mb-3 pr-3">
                <Input
                  labelText={t("registration.form.personal.user.email")}
                  name="user.email"
                  tabIndex={1}
                  error={errors.user && errors.user.email}
                  handleChange={(e) => {
                    setValue("user.email", e.target.value.toLowerCase());
                    setIsEmailPending(true);
                    checkEmailDebounce();
                  }}
                  inputIcon={isEmailPending && <Spinner className="ml-2 spinner" animation="border" size="sm" />}
                  placeholder=" "
                  reference={register}
                />
                <div className={classNames({ "secure-field": disablePersonalForm })}>
                  <Input
                    labelText={t("registration.form.personal.user.firstName")}
                    type={secureInput}
                    name="user.firstName"
                    tabIndex={3}
                    placeholder=" "
                    handleChange={async () => {
                      await triggerValidation("user.firstName", true);
                    }}
                    error={errors.user && errors.user.firstName}
                    disabled={disablePersonalForm}
                    reference={register}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3 pl-3">
                <div className={classNames({ "secure-field": disablePersonalForm && emailChecked })}>
                  <MaskedInput
                    type={secureInput}
                    labelText={t("registration.form.personal.user.phoneNumber")}
                    name="user.phoneNumber"
                    error={errors.user && errors.user.phoneNumber}
                    defaultValue={formData.user.phoneNumber}
                    mask="+00 000-000-000"
                    formHooks={{ register, setValue }}
                    tabIndex={2}
                    disabled={disablePersonalForm}
                    reference={register}
                  />
                </div>
                <div className={classNames({ "secure-field": disablePersonalForm })}>
                  <Input
                    labelText={t("registration.form.personal.user.lastName")}
                    type={secureInput}
                    name="user.lastName"
                    error={errors.user && errors.user.lastName}
                    tabIndex={4}
                    placeholder=" "
                    handleChange={async () => {
                      await triggerValidation("user.lastName", true);
                    }}
                    disabled={disablePersonalForm}
                    reference={register}
                  />
                </div>
              </div>
              <div className="col-12">
                <Checkbox
                  checked={formData.isCompanyRepresentative}
                  name="isCompanyRepresentative"
                  reference={register}
                  tabIndex={5}
                  error={errors.isCompanyRepresentative}
                  labelText={t("registration.form.personal.confirm.isCompanyRepresentative")}
                />
                <div className="row">
                  <div className="col-4">
                    <FileInput
                      changeHandler={updateFile}
                      value=""
                      setFileName={setFileName}
                      fileName={fileName}
                      initFileName={fileName}
                      tabIndex={6}
                      labelText={t("registration.form.personal.confirm.attachAuthorization")}
                    />
                  </div>
                  <div className="col-8">
                    <p className="file-description">
                      {t("download.pattern.desc")}{" "}
                      <Link
                        download="wzor-uprawnienia.pdf"
                        className="link"
                        target="_blank"
                        tabIndex={7}
                        to="/media/wzor-uprawnienia.pdf"
                      >
                        {t("download.pattern")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Agreements form={form} isAdmin={false} startingTabIndex={8} changePermissionsInfo />
                <PersonalDataLegals />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <button className="btn secondary mr-2 float-right " type="button" onClick={goToPrevStep}>
                {t("back")}
              </button>
              <button className={"btn primary margin-1-b float-right px-5"} tabIndex={13} type="submit">
                {t("next")}
              </button>
            </div>
          </form>
        )}
      />
    </Wizard>
  );
};

export default PersonalData;
