import Cookies from "js-cookie";

const handleReCaptchaVerify = async (executeRecaptcha: any) => {
  if (!executeRecaptcha) {
    console.log("Recaptcha not yet available");
    Cookies.set("Captha-v3", "");
  } else {
    try {
      const token = await executeRecaptcha("login");
      Cookies.set("Captha-v3", token);
    } catch (err) {
      console.log(err);
    }
  }
};
export default handleReCaptchaVerify;
