import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "components/Inputs/Checkbox";
import Radio from "components/Inputs/Radio";
import classNames from "classnames";

interface AgreementsProps {
  form: Record<string, any>;
  isAdmin: Boolean;
  startingTabIndex?: number;
  changePermissionsInfo?: boolean;
}

const Agreements: React.FC<AgreementsProps> = ({
  form,
  isAdmin = false,
  startingTabIndex = 1,
  changePermissionsInfo,
}) => {
  const { t } = useTranslation();

  const { user } = form.getValues({ nest: true });

  /**
   * Sets all agreements to
   * @param value
   * @param [shouldValidate]
   */
  function setAllAgreementsTo(value: string, shouldValidate: boolean = true) {
    form.setValue("user.agreeToSellingEmails", value, shouldValidate);
    form.setValue("user.agreeToSellingPhones", value, shouldValidate);
    form.setValue("user.agreeToSharingData", value, shouldValidate);
    form.setValue("user.agreeToMarketingEmails", value, shouldValidate);
    form.setValue("user.agreeToMarketingPhones", value, shouldValidate);
  }

  const [checkAllValue, setCheckAllValue] = useState(false);

  /**
   * Toggles all checkboxes
   */
  function toggleAllCheckboxes() {
    const {
      user: { firstName, lastName, email, confirmedStatute, isCompanyRepresentative, phoneNumber, ...agreements },
    } = form.getValues({
      nest: true,
    });
    const isAllAgreementsTrue = Object.values(agreements).every((el) => el === "true" || el === true);
    if (isAllAgreementsTrue) {
      setAllAgreementsTo("");
      setCheckAllValue(false);
    } else {
      setAllAgreementsTo("true");
      setCheckAllValue(true);
    }
  }

  const confirmOptions = [
    {
      value: "true",
      labelValue: t("yes"),
    },
    {
      value: "false",
      labelValue: t("no"),
    },
  ];

  return (
    <>
      {isAdmin ? (
        <Checkbox
          checked={form.isCompanyRepresentative}
          name="isCompanyRepresentative"
          reference={form.register}
          tabIndex={startingTabIndex}
          error={form.errors.isCompanyRepresentative}
          labelText={t("registration.form.personal.confirm.isCompanyRepresentative")}
        />
      ) : null}
      <Checkbox
        name="user.confirmedStatute"
        error={form.errors.user && form.errors.user.confirmedStatute}
        tabIndex={startingTabIndex + 1}
        checked={user?.confirmedStatute}
        reference={form.register}>
        {t("terms.confirm.familiarWith") + " "}
        <a className="link" href="/terms" target="_blank">
          {t("terms")}
        </a>
        {" " + t("terms.confirm.platformPwS")}
      </Checkbox>
      <h2 className="txt-marine-blue my-2 py-2 font-size-small font-weight-bold my-2">{t("Zgody marketingowe")}</h2>
      <div className={"form-group checkbox custom-checkbox ml-3 mb-3"}>
        <input
          className="custom-control-input"
          type="checkbox"
          onChange={toggleAllCheckboxes}
          tabIndex={startingTabIndex + 2}
          checked={checkAllValue}
          id="checkAll"
        />
        <label className={classNames("custom-control-label form-check-label text-left")} htmlFor={"checkAll"}>
          {t("checkAll")}
        </label>
      </div>

      <Radio
        inLine={true}
        setValue={form.setValue}
        name="user.agreeToSellingEmails"
        reference={form.register}
        defaultCheckedValue={""}
        tabIndex={startingTabIndex + 3}
        options={confirmOptions}
        error={form.errors.user && form.errors.user.agreeToSellingEmails}>
        {t("activate.account.form.confirm.agreeToSellingEmails")}
      </Radio>
      <Radio
        inLine={true}
        setValue={form.setValue}
        name="user.agreeToSellingPhones"
        reference={form.register}
        defaultCheckedValue={""}
        tabIndex={startingTabIndex + 4}
        options={confirmOptions}
        error={form.errors.user && form.errors.user.agreeToSellingPhones}>
        {t("activate.account.form.confirm.agreeToSellingPhones")}
      </Radio>
      <Radio
        inLine={true}
        setValue={form.setValue}
        name="user.agreeToSharingData"
        tabIndex={startingTabIndex + 5}
        reference={form.register}
        defaultCheckedValue={""}
        options={confirmOptions}
        error={form.errors.user && form.errors.user.agreeToSharingData}>
        {t("activate.account.form.confirm.agreeToSharingData")}
      </Radio>
      <Radio
        inLine={true}
        setValue={form.setValue}
        tabIndex={startingTabIndex + 6}
        name="user.agreeToMarketingEmails"
        reference={form.register}
        defaultCheckedValue={""}
        options={confirmOptions}
        error={form.errors.user && form.errors.user.agreeToMarketingEmails}>
        {t("activate.account.form.confirm.agreeToMarketingEmails")}
      </Radio>
      <Radio
        inLine={true}
        setValue={form.setValue}
        name="user.agreeToMarketingPhones"
        reference={form.register}
        tabIndex={startingTabIndex + 7}
        defaultCheckedValue={""}
        options={confirmOptions}
        error={form.errors.user && form.errors.user.agreeToMarketingPhones}>
        {t("activate.account.form.confirm.agreeToMarketingPhones")}
      </Radio>
      {changePermissionsInfo && (
        <p className="font-size-small">{t("registration.form.personal.confirm.changeAgreements")}</p>
      )}
    </>
  );
};

export default Agreements;
