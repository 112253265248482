import { useDispatch } from "react-redux";
import GS1APIClient from "clients/GS1APIClient";
import { setActiveMembership } from "store/actions/AccountActions";

const useMembershipCookies = () => {
  const dispatch = useDispatch();
  const activeMembership: any = GS1APIClient.getActiveMembership();

  /**
   * Checks membership
   * @param user
   */
  async function checkMembership(user: any) {
    if (activeMembership) {
      const parsedActivateMembership = JSON.parse(activeMembership);
      const userActivateMembership = parsedActivateMembership.find(
        (membership: any) => membership.email === user.email,
      );
      if (userActivateMembership) {
        await dispatch(setActiveMembership(userActivateMembership));
      }
    }
  }

  return { checkMembership };
};

export default useMembershipCookies;
