import GS1APIClient from "clients/GS1APIClient";
import { CurrentUser, Membership } from "interfaces/UserInterface";
import { MembershipUserForm } from "components/UserDataForm/UserDataForm";
import { ChangePasswordForm } from "pages/ChangePasswordPage/ChangePasswordPage";
/**
 * Logs user in to the platform
 * @param email
 * @param password
 * @param rememberMe
 */
function login(email: string, password: string, rememberMe: boolean) {
  return GS1APIClient.authenticate(email, password, rememberMe);
}
/**
 * Clears out session for the user
 */
function logout() {
  GS1APIClient.logout();
}

/**
 * Fetchs current user
 * @returns
 */
function fetchCurrentUser() {
  return GS1APIClient.privateRequest<{ user: CurrentUser }>({
    url: "/accounts/me/",
    method: "GET",
  });
}

/**
 * Resets password
 * @param { email }
 * @returns
 */
function resetPassword({ email }: { email: string }) {
  return GS1APIClient.request<any>({
    url: "accounts/reset_password/",
    method: "PUT",
    data: { email },
  });
}

/**
 * Sets password
 * @param { password, passwordRepeat, id }
 * @returns
 */
function setPassword({ password, passwordRepeat, id }: { password: string; passwordRepeat: string; id: string }) {
  return GS1APIClient.request<any>({
    url: `accounts/reset_password/${id}/`,
    method: "PATCH",
    data: { password, passwordRepeat },
  });
}

/**
 * Gets user by reset password id
 * @param id
 * @returns
 */
function getUserByResetPasswordId(id: string) {
  return GS1APIClient.request<any>({
    url: `accounts/reset_password/${id}/`,
    method: "GET",
  });
}

/**
 * Invites new user to company
 * @param activeMembership
 * @param email
 * @param permissionGroup
 * @returns
 */
function inviteNewUserToCompany(activeMembership: Membership, email: string, permissionGroup: string) {
  return GS1APIClient.privateRequest<any>({
    url: `accounts/${activeMembership.id}/invite_user/${activeMembership.company.id}/`,
    method: "POST",
    data: { email, permissionGroup },
  });
}

/**
 * Determines whether activated membership is
 * @param activationId
 * @returns
 */
function isActivatedMembership(activationId: string) {
  return GS1APIClient.request<{
    isFullRegistration: boolean;
    hasUserActivatedMembership: boolean;
    email: string;
    phoneNumber: string;
  }>({
    url: `accounts/confirm/email/${activationId}/`,
    method: "GET",
  });
}

/**
 * Activates membership
 * @param formData
 * @param activationId
 * @returns
 */
async function ActivateMembership(formData: any, activationId: string) {
  return await GS1APIClient.request<any>({
    url: `accounts/confirm/email/${activationId}/`,
    method: "POST",
    data: formData,
  });
}

/**
 * Gets memberships from company
 * @param activeMembership
 * @param company_id
 * @param [query]
 * @returns
 */
async function getMembershipsFromCompany(activeMembership: Membership, company_id: string, query: string = "") {
  return await GS1APIClient.privateRequest<{ user: CurrentUser }>({
    url: `accounts/${activeMembership.id}/get_company_memberships/${company_id}/?${query ? query : ""}`,
    method: "GET",
  });
}

/**
 * Deletes membership by id
 * @param activeMembershipId
 * @param membershipId
 * @returns
 */
async function deleteMembershipById(activeMembershipId: string, membershipId: string) {
  return await GS1APIClient.privateRequest({
    url: `accounts/${activeMembershipId}/delete_membership/${membershipId}/`,
    method: "DELETE",
  });
}

/**
 * Changes user permission
 * @param activeMembership
 * @param permissionGroup
 * @param membershipId
 * @returns
 */
async function changeUserPermission(
  activeMembership: Membership,
  permissionGroup: Membership["permissionGroup"],
  membershipId: string,
) {
  return GS1APIClient.privateRequest<any>({
    url: `accounts/${activeMembership.id}/set_permission/${membershipId}/`,
    method: "PATCH",
    data: { permissionGroup },
  });
}

/**
 * Gets user by id
 * @param activeMembership
 * @param id
 * @returns
 */
async function getUserById(activeMembership: Membership, id: string) {
  return await GS1APIClient.privateRequest<Membership>({
    url: `/accounts/${activeMembership.id}/user/${id}/`,
    method: "GET",
  });
}

/**
 * Updates user by id
 * @param activeMembership
 * @param id
 * @param userData
 * @returns
 */
async function updateUserById(activeMembership: Membership, id: string, userData: MembershipUserForm) {
  return await GS1APIClient.privateRequest({
    url: `/accounts/${activeMembership.id}/user/${id}/`,
    method: "PUT",
    data: userData,
  });
}

/**
 * Changes user password
 * @param activeMembership
 * @param id
 * @param changePasswordForm
 * @returns
 */
async function changeUserPassword(activeMembership: Membership, id: string, changePasswordForm: ChangePasswordForm) {
  return await GS1APIClient.privateRequest({
    url: `/accounts/${activeMembership.id}/change_password/${id}/`,
    method: "PUT",
    data: changePasswordForm,
  });
}

const AccountService = {
  login,
  logout,
  fetchCurrentUser,
  resetPassword,
  setPassword,
  getUserByResetPasswordId,
  inviteNewUserToCompany,
  ActivateMembership,
  isActivatedMembership,
  getMembershipsFromCompany,
  deleteMembershipById,
  changeUserPermission,
  getUserById,
  updateUserById,
  changeUserPassword,
};

export default AccountService;
