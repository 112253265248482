import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { getValidUrl, gtinImgGenerator } from "utils";

import { Link, useHistory } from "react-router-dom";
import InputIcon from "components/Inputs/InputIcon";
import TooltipWrapper from "components/Tooltip/Tooltip";
import VerifyByGs1Icon from "components/VerifyIcon/VerifyByGs1Icon";
import useMessage from "hooks/useMessageStatus";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as CatalogThunk from "store/thunk/CatalogThunk";
import NPWSStatus from "utils/statuses";
import { v4 as uuid } from "uuid";
import Cookies from "js-cookie";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import GCPValidationService from "../../services/GCPValidationService";
import handleReCaptchaVerify from "../CatalogPage/recaptha";


interface CategoryDetails {
  code: string;
  text: string;
}

export interface Product {
  id: string;
  gtinNumber: string;
  gtinStatus: string;
  name: string;
  targetMarket: string[];
  gpc: string;
  netContent: string[];
  netVolume: number;
  unit: string;
  brand: string;
  imageUrls: string[];
  description: string;
  additionalData: string;
  descriptionLanguage: string;
  productPage: string;
  productCardPage: string;
  isPublic: boolean;
  isPartial?: boolean;
  isVerified?: boolean;
  lastModified: string;
  contents: string;
  group: {
    gcpNumber: string;
  };
  company: {
    name: string;
    nip: string;
    webPage: string;
    street: string;
    city: string;
    postalCode: string;
  };
  categoryDetails: CategoryDetails[];
  isGlobal?: boolean;
}

const EMPTY_VALUE = "-";

const ProductPage: React.FC = () => {
  const { id = "" } = useParams();
  const dispatch = useDispatch();
  const [product, setProduct] = useState<Product>();
  const { t } = useTranslation();
  const { setMessage } = useMessage();
  const [showGtinImgModal, setShowGtinImgModal] = useState<boolean>(false);
  const history = useHistory();
  const [modalImgUrl, setModalImgUrl] = useState<string>("");

  const trimmedGtin = product?.gtinNumber;

  function showModal(url: string) {
    setModalImgUrl(url);
    setShowGtinImgModal(true);
  }

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify2 = async () => {
    if (!executeRecaptcha) {
      console.log("Recaptcha not yet available");
      Cookies.set("Captha-v3", "");
    } else {
      try {
        const token = await executeRecaptcha("login");
        Cookies.set("Captha-v3", token);
        console.log("OKKKK" + token);
      } catch (err) {
        console.log(err);
      }
    }
  };

  /**
   * Gets product by id
   * @param id
   */


  async function getProductById(id: string, executeRecaptcha2: any) {
      const gcpData = await GCPValidationService.searchForGCP(id.padStart(14, "0"));
      if (gcpData && !(typeof gcpData == "string")) {
        const product = GCPValidationService.createPlaceholderRecord(id, gcpData) as Product;
        setProduct(product);
      } else {
        if (gcpData == "too_many_requests") {
          setMessage(NPWSStatus.TOO_MANY_REQUESTS);
        } else {
          setMessage(NPWSStatus.NO_PRODUCTS);
        }

      }
    }

  /**
   * Gets full address
   * @param {
   *     street = "",
   *     city = "",
   *     postalCode = "",
   *   }
   * @returns
   */
  function getFullAddress({
    street = "",
    city = "",
    postalCode = "",
  }: {
    street: string;
    city: string;
    postalCode: string;
  }) {
    if (!street && !city && !postalCode) {
      return EMPTY_VALUE;
    } else {
      return `${street || EMPTY_VALUE}, ${postalCode || EMPTY_VALUE} ${city || EMPTY_VALUE}`;
    }
  }

  /**
   * Renders gpc
   * @param product
   * @returns gpc
   */
  function _renderGPC(product: Product): JSX.Element | string {
    const brick = product.categoryDetails?.pop();
    return (
      <>
        {brick ? (
          <>
            {brick.text ? (
              <>
                {brick.text} - <span className="font-wegiht-normal">{brick.code}</span>
              </>
            ) : (
              <>{brick.code}</>
            )}
          </>
        ) : (
          EMPTY_VALUE
        )}
      </>
    );
  }

  const renderGPC = useMemo(() => {
    if (product) {
      return _renderGPC(product);
    } else {
      return EMPTY_VALUE;
    }
  }, [product]);

  /**
   * Renders product unit
   * @param product
   * @returns
   */
  function renderProductUnit(product: Product) {
    let productUnit;
    if (product.netContent) {
      productUnit = product.netContent?.join(", ") || EMPTY_VALUE;;
    } else if (product.netVolume) {
      productUnit = `${product.netVolume || EMPTY_VALUE} ${product.unit || EMPTY_VALUE}`;;
    } else {
      productUnit = EMPTY_VALUE;
    }
    return productUnit;
  }

  /**
   * Renders not public products (swaps data that should not be available for EMPTY_VALUE)
   * @param product
   * @param value
   * @returns not public
   */
  function renderNotPublic(
    product: Product,
    value: string | JSX.Element | ((product: Product) => string | JSX.Element),
  ): string | JSX.Element {
    if (product.isPartial) {
      return EMPTY_VALUE;
    } else if (!product.isPublic) {
      return t("catalog.details.productData.isNotPublic");
    } else {
      if (typeof value === "function") {
        return value(product);
      }
      return value;
    }
  }

  /**
   * Renders name
   * @param product
   * @param value
   * @returns name
   */
  function renderName(
    product: Product,
    value: string | JSX.Element | ((product: Product) => string | JSX.Element),
  ): string | JSX.Element {
    if (product.isPartial) {
      return "-";
    } else {
      return renderNotPublic(product, value);
    }
  }

  /**
   * Renders product gtin status
   * @param product
   * @returns gtin status
   */
  function renderGtinStatus(product: Product): JSX.Element {
    switch (product.gtinStatus) {
      case "active":
      case "aktywny":
        return <strong style={{color: '#1fb24b', fontWeight: 'bold'}}>Aktywny</strong>;
      case "inactive":
      case "nieaktywny":
        return (
          <strong>
            <strong style={{ color: '#e81723', fontWeight: 'bold'}}>Nieaktywny</strong> | Dane o tym numerze nie są już aktualizowane przez producenta/właściciela marki
          </strong>
        );
      case "withdrawn":
      case "wycofany":
        return (
          <strong>
            <strong style={{ color: '#e7792d', fontWeight: 'bold'}}>Wycofany</strong> | Ten produkt nie jest już wytwarzany przez producenta/właściciela marki
          </strong>
        );
      default:
        return <strong>{EMPTY_VALUE}</strong>;
    }
  }

  /**
   * Renders target market
   * @param product
   * @returns
   */
  function renderTargetMarket(product: Product) {
    return product.targetMarket?.join(", ") || EMPTY_VALUE;
  }

  useEffect(() => {
    if (!product) {
      handleReCaptchaVerify2();
      getProductById(id, null);
    }
  }, [id, product]);

  return product ? (
    <div className="my-products-page__container">
      <button className="my-products-page__back-button" onClick={history.goBack}>
        <img className="mr-1" height={16} width={16} src="/icons/chevron-left.svg" alt="" />
        {t("back")}
      </button>
      <div className="my-products-page">
        <div className="row w-100">
          <div className="col-2">
            {product.imageUrls?.length > 0 ? (
              <img
                key={uuid()}
                className="w-100 pointer"
                data-testid="productPhoto"
                src={product.imageUrls[0]}
                onClick={() => showModal(product.imageUrls[0])}
              />
            ) : (
              <img
                className="w-100 pointer"
                data-testid="productPhoto"
                src={gtinImgGenerator(renderNotPublic(product, product.name).toString(), product.gtinNumber)}
                onClick={() =>
                  showModal(gtinImgGenerator(renderNotPublic(product, product.name).toString(), product.gtinNumber))
                }
              />
            )}
          </div>
          <div className="col-10 main">
            <div className="row">
              <div className="col-7">
                <h5 data-testid="productName" className="main__header">
                  <span>{t("catalog.details.productData.name")}: </span>
                  {renderName(product, product.name)}
                </h5>
              </div>
              <div className="col-5 main__source text-right">
                {t("catalog.details.source")}{" "}
                {product.isGlobal ? t("gs1.global") : <a href="https://www.gs1pl.org/"> {t("gs1.poland")} </a>}
                {product.isVerified && <VerifyIcon />}
              </div>
            </div>
            <p data-testid="productDescription" className="main__gtin">
              <span>{t("catalog.details.productData.gtin")}: </span>
              {product.gtinNumber}
              <TooltipWrapper tooltipText={t("catalog.showEan")}>
                <img
                  className="gtin__img-min"
                  data-testid="productPhoto"
                  onClick={() => showModal(gtinImgGenerator(product.name || "", product.gtinNumber))}
                  src={gtinImgGenerator(product.name || "", product.gtinNumber)}
                />
              </TooltipWrapper>
            </p>
            <p data-testid="productDescription" className="main__gtin">
              <span>{t("catalog.details.productData.gtinStatus")}: </span>
              <strong>{renderGtinStatus(product)}</strong>
            </p>
            <hr />
            <h3>{t("catalog.baseData")}</h3>
            <p className="main__detail">
              <span>{t("catalog.details.productData.mark")}:</span>
              {renderNotPublic(product, product.brand || EMPTY_VALUE)}
            </p>
            <p className="main__detail">
              <span>{t("catalog.details.productData.gpc")}:</span>
              {renderNotPublic(product, renderGPC)}
            </p>
            <p className="main__detail">
              <span>{t("catalog.details.productData.netVolume")}:</span>
              {renderNotPublic(product, renderProductUnit)}
            </p>
            <p className="main__detail">
              <span>{t("catalog.details.productData.originCountry")}:</span>
              {renderNotPublic(product, renderTargetMarket)}
            </p>
            <p className="main__detail">
              <span>{t("catalog.details.descriptionLanguage")}:</span>
              {renderNotPublic(product, product.descriptionLanguage || EMPTY_VALUE)}
            </p>
            <p className="main__detail">
              <span>{t("catalog.details.productData.productPage")}:</span>
              {renderNotPublic(
                product,
                (
                  <a className="link font-weight-normal" href={getValidUrl(product.productPage)} target="_blank">
                    {product.productPage}
                  </a>
                ) || EMPTY_VALUE,
              )}
            </p>
            <hr />
            <h3>{t("catalog.productDescription")}</h3>
            <div className="main__detail">
              <p className="main__description">{renderNotPublic(product, product.description || EMPTY_VALUE)}</p>
            </div>
            <hr />
            <h3>{t("catalog.owner")}</h3>
            <p className="main__detail">
              <span>{t("catalog.details.owner.companyName")}:</span>
              {product.company?.name || EMPTY_VALUE}
            </p>
            <p className="main__detail">
              <span>{t("catalog.details.owner.nip")}:</span>
              {product.company?.nip || EMPTY_VALUE}
            </p>
            <p className="main__detail">
              <span>{t("catalog.details.owner.address")}:</span>
              {getFullAddress(product.company)}
            </p>
            <p className="main__detail">
              <span>{t("catalog.details.owner.www")}:</span>
              {product.company?.webPage ? (
                <a className="link font-weight-normal" href={getValidUrl(product.company?.webPage)} target="_blank">
                  {product.company?.webPage}
                </a>
              ) : (
                EMPTY_VALUE
              )}
            </p>
            <hr />
            <p className="main__last-update">
              <span>{t("catalog.details.productData.lastUpdate")}:</span>
              {product.lastModified ? moment(new Date(product.lastModified)).format("DD-MM-YYYY") : EMPTY_VALUE}
            </p>
            <hr className="my-products-page__additional-data-separator" />
            <div className="d-flex align-items-center">
              <h3>{t("catalog.additionalData")}</h3>{" "}
              <InputIcon
                className="mb-2 ml-1"
                tooltipText={t("catalog.externalSources")}
                show={true}
                src="/icons/icon-help-enabled.svg"
              />
            </div>
            <p className="main__detail">
              <span>{t("catalog.additionalData")}:</span>
              {renderNotPublic(
                product,
                <a className="link font-weight-normal" href={getValidUrl(product.productCardPage)} target="_blank">
                  {product.productCardPage}
                </a>,
              ) || EMPTY_VALUE}
            </p>
            <hr className="my-products-page__additional-data-separator" />
            <p className="main__detail">
              <Link to={`/generator_2d?gtin_number=${product.gtinNumber}`}>
                Przejdź do generatora kodów 2D
                <img style={{ width: '30px', marginLeft: '10px', marginTop: '-5px' }} src="/assets/v3/product-page/symbol-2d.png" />
              </Link>
            </p>

          </div>
        </div>
      </div>
      <Modal
        restoreFocus={false}
        enforceFocus={false}
        autoFocus={false}
        show={showGtinImgModal}
        animation={false}
        onHide={() => setShowGtinImgModal(false)}
        size="sm"
        className="img-modal"
        centered
        style={{ overflow: "hidden" }}
      >
        <Modal.Header>
          <h3 className="modal__gtin-number">{product?.gtinNumber}</h3>
          <span className="modal__close" onClick={() => setShowGtinImgModal(false)}>
            x
          </span>
        </Modal.Header>
        <div
          data-testid="gtinModal"
          className="w-100 h-100 d-flex justify-content-center align-items-center position-relative"
        >
          <img className="modal__img" data-testid="productPhoto" src={modalImgUrl} />
        </div>
      </Modal>
    </div>
  ) : (
    <div></div>
  );
};

const VerifyIcon: React.FC = () => (
  <div className="my-products-page__verify-icon">
    <VerifyByGs1Icon placement="bottom" />
  </div>
);

export default ProductPage;
