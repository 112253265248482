import { State } from "interfaces/StoreInterface";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as AccountThunk from "store/thunk/AccountThunk";
import CompanyDetailsLabel from "../../components/CompanyDetailsLabel/CompanyDetailLabel";
import { Membership } from "../../interfaces/UserInterface";
import { CompanyData } from "../../store/actions/RegistrationActions";
import { MembershipData } from "../UsersListPage/UsersListPage";

type QueryProps = { [key: string]: any };

const SubscriptionsListPage: React.FC = () => {
  const [data, setData] = useState<MembershipData>();
  const [company, setCompany] = useState<CompanyData>();

  const dispatch = useDispatch();
  const history = useHistory();
  const [allUsers, setAllUsers] = useState(0);
  const searchProps: { [key: string]: any } = queryString.parse(history.location.search);
  const [queryProps, setQueryProps] = useState<QueryProps>(searchProps);
  const { activeMembership, user } = useSelector((state: State) => state.account);

  /**
   * Gets memberships from company
   * @param activeMembership
   * @param queryProps
   */
  async function getMembershipsFromCompany(activeMembership: Membership, queryProps: QueryProps) {
    try {
      const { data }: any = await dispatch(
        AccountThunk.getMembershipsFromCompany(
          activeMembership,
          activeMembership.company.id!,
          queryString.stringify(queryProps),
        ),
      );
      if (!data.records.length && queryProps.page > 0) setQueryProps({ ...queryProps, page: 0 });
      setAllUsers(data.numAllRecords);
      setData(data);
      const updatedCompany = data.records.filter((item: any) => {
        if (item.id === activeMembership.id) return item;
      })[0].company;
      setCompany(updatedCompany);
    } catch (err) {}
  }

  useEffect(() => {
    activeMembership && getMembershipsFromCompany(activeMembership, queryProps);
  }, [activeMembership]);
  return (
    <div>
      <CompanyDetailsLabel company={company} showIsAuthorized={false} showApiKey={true} />
    </div>
  );
};

export default SubscriptionsListPage;
