import React, { useState, useEffect } from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react/lib/bootstrap-switch-button-react";

import { useTranslation } from "react-i18next";

interface InputType {
  value: boolean;
  labelKey: string;
  setValue?: any;
}

const SwitchInput: React.FC<InputType> = ({ value = false, setValue = {}, labelKey = "" }) => {
  const { t } = useTranslation();

  return (
    <div className="">
      <label>{t(labelKey)}</label>
      <div>
        <BootstrapSwitchButton
          checked={value}
          width={100}
          onlabel={t("yes")}
          offlabel={t("no")}
          onstyle={"success"}
          offstyle={"secondary"}
          onChange={setValue}
        />
      </div>
    </div>
  );
};

export default SwitchInput;
