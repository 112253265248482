import React from "react";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";
import useAuthentication from "../../hooks/useAuthentication";
import RepositoryService from "../../services/RepositoryService";

const DownloadIssueFilePage: React.FC = () => {
  const { id = "" } = useParams();
  const { setMessage } = useMessage();
  const { t } = useTranslation();
  const { activeMembership } = useAuthentication();

  /**
   * Downloads file
   */
  async function downloadFile() {
    try {
      if (activeMembership !== null) {
        const response: any = await RepositoryService.getIssuesFile(id, activeMembership);
        const fileName = response?.headers["content-disposition"]?.match(/filename=.+/)[0].split("=")[1];
        const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/vnd.ms-excel" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }

  return (
    <div className="row d-flex justify-content-center align-items-center login-page mt-5">
      <div className="col-12 d-flex justify-content-center">
        {/* Move to separate component when toasters will be ready */}
        <button className="btn primary" type="button" onClick={downloadFile}>
          {t("repository.downloadIssuePage.download")}
        </button>
      </div>
    </div>
  );
};

export default DownloadIssueFilePage;
