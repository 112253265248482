import GS1APIClient from "clients/GS1APIClient";
import { Membership } from "../interfaces/UserInterface";

/**
 * Gets issues file
 * @param file_id
 * @param activeMembership
 * @returns
 */
function getIssuesFile(file_id: string, activeMembership: Membership) {
  return GS1APIClient.privateRequest<any>({
    url: `repository/${activeMembership.id}/issue_file/${file_id}`,
    method: "GET",
    responseType: "arraybuffer",
  });
}

/**
 * Generates issue file
 * @param issueIds
 * @param activeMembership
 * @returns
 */
function generateIssueFile(issueIds: string[], activeMembership: Membership) {
  return GS1APIClient.privateRequest<any>({
    url: `repository/${activeMembership.id}/generate_issue_file/`,
    method: "POST",
    data: {
      issueIds,
    },
  });
}

const RepositoryService = {
  getIssuesFile,
  generateIssueFile,
};

export default RepositoryService;
