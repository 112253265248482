import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";

import Input from "components/Inputs/Input";
import InputIcon from "components/Inputs/InputIcon";
import NPWSTooltips from "components/Tooltip/TooltipValues";

interface SetPasswordFormProps {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  form: Record<string, any>;
  success: boolean;
  email: string;
}

const SetPasswordForm: React.FC<SetPasswordFormProps> = ({ success, onSubmit, form: { errors, register }, email }) => {
  const { t } = useTranslation();

  return (
    <form className="set-password-form" onSubmit={onSubmit}>
      <div hidden className="secure-field">
        <Input
          labelText={t("emailAddress")}
          name="email"
          disabled={true}
          type="text"
          value={email}
          reference={register}
          data-testid="email"
          autocomplete="username"
        />
      </div>
      <Input
        labelText={t("set.password.newPassword")}
        name="password"
        type="password"
        data-testid="password"
        placeholder=" "
        error={errors.password}
        reference={register}
        autocomplete="new-password"
        inputIcon={
          <InputIcon tooltipText={NPWSTooltips.PasswordRequirments} show={true} src="/icons/icon-help-enabled.svg" />
        }
      />
      <Input
        labelText={t("set.password.newPassword.repeat")}
        name="passwordRepeat"
        placeholder=" "
        type="password"
        data-testid="passwordRepeat"
        error={errors.passwordRepeat}
        reference={register}
        autocomplete="new-password"
      />
      <div className="set-actions">
        <button data-testid="resetPasswordButton" disabled={success} className="btn primary w-100" type="submit">
          {success ? t("sent") : t("set.password.newPassword.change")}
        </button>
      </div>
    </form>
  );
};

export default SetPasswordForm;
