import * as yup from "yup";
import i18next from "i18next";
import { regexps } from "utils/constants";

const generateChangePasswordSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    oldPassword: yup.string().required(t("form.error.required")),
    newPassword: yup
      .string()
      .required(t("form.error.required"))
      .matches(regexps.PASSWORD_REGEX, {
        excludeEmptyString: true,
        message: `${t("reset.password.wrongPassword")}`,
      }),
    repeatNewPassword: yup
      .string()
      .required(t("form.error.required"))
      .matches(regexps.PASSWORD_REGEX, { excludeEmptyString: true, message: t("form.login.error.passwordFormat") })
      .oneOf([yup.ref("newPassword"), null], t("set.password.diff.passwords")),
  });

export default generateChangePasswordSchema;
