import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import ResetPasswordForm from "components/ResetPasswordForm/ResetPasswordForm";
import useMessage from "hooks/useMessageStatus";
import { resetPassword } from "store/thunk/AccountThunk";
import generateResetSchema from "./ResetValidation";

import NPWSStatus from "utils/statuses";

const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const { setMessage } = useMessage();
  const dispatch = useDispatch();

  const { handleSubmit, ...loginForm } = useForm({
    validationSchema: generateResetSchema(t),
  });

  const onSubmit = async (data: Record<string, any>) => {
    const { email } = data;
    try {
      await dispatch(resetPassword(email));
      setMessage(NPWSStatus.PASSWORD_RESET);
      setSuccess(true);
    } catch (err) {
      setMessage(NPWSStatus.RESET_PASSWORD_INVALID_EMAIL);
    }
  };

  return (
    <div className="reset-passowrd">
      <div className="component-container col-5">
        <h2>{t("reset.password.h2")}</h2>
        <p className="paragraph">{t("reset.password.desc")}</p>
        <ResetPasswordForm success={success} onSubmit={handleSubmit(onSubmit)} form={loginForm} />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
