import { persistReducer } from "redux-persist";
import * as reduxPersistStorage from "redux-persist/lib/storage";

import { CurrentUser, Membership } from "interfaces/UserInterface";
import * as AccountActions from "store/actions/AccountActions";
import GS1APIClient from "../../clients/GS1APIClient";

type AccountAction = AccountActions.AccountAction;

export interface AccountState {
  user: CurrentUser | null;
  authenticated: boolean;
  activeMembership: Membership | null;
}

export const initialAccountState: AccountState = {
  user: null,
  authenticated: false,
  activeMembership: null,
};

/**
 * Accounts reducer
 * @param [state]
 * @param action
 * @returns reducer
 */
function accountReducer(state: AccountState = initialAccountState, action: AccountAction): AccountState {
  switch (action.type) {
    case AccountActions.SET_SESSION_ACTION:
    case AccountActions.UPDATE_SESSION_ACTION: {
      let userActivateMembership = action.payload.user.memberships[0];
      const activeMembership: any = localStorage.getItem("activeId");
      if (activeMembership && typeof activeMembership === "string") {
        userActivateMembership = action.payload.user.memberships.filter((membership) => {
          if (membership.id === activeMembership) return membership;
        })[0];
        if (!userActivateMembership) userActivateMembership = action.payload.user.memberships[0];
      }
      GS1APIClient.setActiveMembership(userActivateMembership, action.payload.user.email);
      return {
        ...state,
        user: action.payload.user,
        authenticated: true,
        activeMembership: userActivateMembership,
      };
    }

    case AccountActions.SET_ACTIVE_MEMBERSHIP: {
      return {
        ...state,
        activeMembership: action.payload.activeMembership,
      };
    }

    case AccountActions.REMOVE_SESSION_ACTION:
      return {
        ...initialAccountState,
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: "account",
  storage: reduxPersistStorage.default,
  whitelist: [],
};

export default persistReducer(persistConfig, accountReducer);
