import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import classNames from "classnames";
import { haveAccess } from "utils/permissions";
import { ADVANCED_SEARCH_ACCESS } from "utils/constants";
import { useTranslation } from "react-i18next";
import Input from "components/Inputs/Input";
import useAuthentication from "hooks/useAuthentication";
import CategoriesTree from "components/CategoriesTree/CategoriesTree";
import { FullSearchParams } from "./interfaces";
import { SelectedCategory, TableCategory } from "interfaces/CategoryInterface";

interface CatalogPageModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalWasShown: boolean;
  selectedKeys: string[];
  setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedCategories: React.Dispatch<React.SetStateAction<SelectedCategory[]>>;
  setInputParams: React.Dispatch<React.SetStateAction<FullSearchParams>>;
  inputParams: FullSearchParams;
  categories: TableCategory[];
}

const CatalogPageModal: React.FC<CatalogPageModalProps> = ({
  showModal,
  setShowModal,
  modalWasShown,
  setInputParams,
  inputParams,
  selectedKeys,
  setSelectedKeys,
  setSelectedCategories,
  categories,
}) => {
  const { t } = useTranslation();
  const { activeMembership } = useAuthentication();

  const [keyword, setKeyword] = useState<string>("");
  const [isSegmentsLimitReached, setSegmentsLimitReached] = useState<boolean>(false);

  /**
   * Handles set selected categories to be mirrored in inputParams
   * @param categories
   */
  function handleSetSelectedCategories(categories: SelectedCategory[]) {
    setSelectedCategories(categories);
    setInputParams({ ...inputParams, gpc_number: categories.map((c) => c.id) });
  }

  /**
   * Handles hide modal without saving any data
   */
  function handleHideModal() {
    if (isSegmentsLimitReached) {
      return;
    }
    setShowModal(false);
  }

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
      document.body.style.overflow = "hidden";
    } else {
      document.body.classList.remove("modal-open");
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  return modalWasShown ? (
    <Modal
      restoreFocus={false}
      enforceFocus={false}
      className={classNames("catalog-categories", { "d-none": !showModal })}
      autoFocus={false}
      show={haveAccess(activeMembership!, ADVANCED_SEARCH_ACCESS)}
      backdropClassName={classNames("modal__background-white", { "d-none": !showModal })}
      animation={false}
      style={{ overflow: "hidden" }}
      onHide={handleHideModal}
      size="lg"
      centered
    >
      <Modal.Header className={"border-0"} closeButton>
        <Modal.Title className="txt-marine-blue font-size-base font-weight-bold">
          {t("catalogPage.chooseGpc")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <button
          className="btn primary float-right"
          onClick={handleHideModal}
          type="button"
          disabled={isSegmentsLimitReached}
        >
          {t("choose")}
        </button>
        {isSegmentsLimitReached && (
          <div className="txt-color-error float-right mr-2 pt-2">{t("catalogPage.modal.segmentsLimitError")}</div>
        )}
        <label className="font-size-small txt-color-grey">
          <Input
            labelText={t("catalogPage.modal.search")}
            placeholder=" "
            name="search"
            handleChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
        </label>

        <CategoriesTree
          searchedKeyword={keyword.trim()}
          setSelectedCategories={handleSetSelectedCategories}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          categories={categories}
          setSegmentsLimitReached={setSegmentsLimitReached}
        />
      </Modal.Body>
      <Modal.Footer className="border-0">
        <button
          className="btn primary mr-3"
          onClick={handleHideModal}
          data-testid="submitInvite"
          type="button"
          disabled={isSegmentsLimitReached}
        >
          {t("choose")}
        </button>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default CatalogPageModal;
