import React from "react";
import { useTranslation, Trans } from "react-i18next";

const PrivacyPolicyPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy-page">
      <h2 className="uppercase">{t("privacyPolicy")}</h2>
      <section>
        <h4>{t("privacyPolicy.intro.header")}</h4>
        <ol>
          <li>
            <Trans i18nKey="privacyPolicy.intro.listItemOne">
              privacyPolicy.intro.listItemOne[0]
              <a className="link" href={"kontakt@eprodukty.gs1.pl"} target="_blank">
                {"kontakt@eprodukty.gs1.pl"}
              </a>
              privacyPolicy.intro.listItemOne[2]
              <span className="font-weight-bold">{t("gs1Foundation")}</span>
              privacyPolicy.intro.listItemOne[4]
              <a className="link" href={`mailto:${t("contact.emailAddressGS1")}`}>
                {t("contact.emailAddressGS1")}
              </a>
            </Trans>
          </li>
          <li>
            <Trans i18nKey="privacyPolicy.intro.listItemTwo">
              privacyPolicy.intro.listItemTwo[0]
              <span className="font-weight-bold">{t("privacyPolicy.iod")}</span>
              <a className="link" href={`mailto:${t("contact.gdpr.emailAddress")}`}>
                {t("contact.gdpr.emailAddress")}
              </a>
            </Trans>
          </li>
          <li>
            <Trans i18nKey="privacyPolicy.intro.listItemThree">
              privacyPolicy.intro.listItemThree[0]
              <span className="font-weight-bold">{t("privacyPolicy.gdpr")}</span>
            </Trans>
          </li>
          <li>{t("privacyPolicy.intro.listItemFour")}</li>
          <ol className="number-parenthesis">
            <li>{t("privacyPolicy.intro.listItemFour.itemOne")}</li>
            <li>{t("privacyPolicy.intro.listItemFour.itemTwo")}</li>
          </ol>
          <li>{t("privacyPolicy.intro.listItemFive")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("privacyPolicy.collectedData.header")}</h4>
        <ol>
          <li>
            <Trans i18nKey="privacyPolicy.collectedData.listItemOne">
              privacyPolicy.collectedData.listItemOne[0]
              <span className="font-weight-bold">III</span>
              privacyPolicy.collectedData.listItemOne[2]
              <span className="font-weight-bold">IV</span>
            </Trans>
          </li>
          <li>{t("privacyPolicy.collectedData.listItemTwo")}</li>
          <li>{t("privacyPolicy.collectedData.listItemThree")}</li>
          <ol className="number-parenthesis">
            <li>{t("privacyPolicy.collectedData.listItemThree.itemOne")}</li>
            <li>{t("privacyPolicy.collectedData.listItemThree.itemTwo")}</li>
            <li>{t("privacyPolicy.collectedData.listItemThree.itemThree")}</li>
            <li>{t("privacyPolicy.collectedData.listItemThree.itemFour")}</li>
          </ol>
          <li>{t("privacyPolicy.collectedData.listItemFour")}</li>
          <ol className="number-parenthesis">
            <li>{t("privacyPolicy.collectedData.listItemFour.itemOne")}</li>
            <li>{t("privacyPolicy.collectedData.listItemFour.itemTwo")}</li>
            <li>{t("privacyPolicy.collectedData.listItemFour.itemThree")}</li>
            <li>{t("privacyPolicy.collectedData.listItemFour.itemFour")}</li>
            <li>{t("privacyPolicy.collectedData.listItemFour.itemFive")}</li>
            <li>{t("privacyPolicy.collectedData.listItemFour.itemSix")}</li>
            <li>{t("privacyPolicy.collectedData.listItemFour.itemSeven")}</li>
          </ol>
          <li>
            <Trans i18nKey="privacyPolicy.collectedData.listItemFive">
              privacyPolicy.collectedData.listItemFive[0]
              <a className="text-dark underline" href="https://uodo.gov.pl/pl/83/155">
                <ins>https://uodo.gov.pl/pl/83/155</ins>
              </a>
            </Trans>
          </li>
          <li>{t("privacyPolicy.collectedData.listItemSix")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("privacyPolicy.userData.header")}</h4>
        <h5 className="subheader-three-counter">{t("privacyPolicy.userData.emailAndTelephone")}</h5>
        <ol>
          <li>{t("privacyPolicy.userData.emailAndTelephone.listItemOne")}</li>
          <li>{t("privacyPolicy.userData.emailAndTelephone.listItemTwo")}</li>
          <li>{t("privacyPolicy.userData.emailAndTelephone.listItemThree")}</li>
        </ol>
        <h5 className="subheader-three-counter">{t("privacyPolicy.userData.contactForm")}</h5>
        <ol>
          <li>{t("privacyPolicy.userData.contactForm.listItemOne")}</li>
          <li>{t("privacyPolicy.userData.contactForm.listItemTwo")}</li>
          <li>{t("privacyPolicy.userData.contactForm.listItemThree")}</li>
          <li>{t("privacyPolicy.userData.contactForm.listItemFour")}</li>
        </ol>
        <h5 className="subheader-three-counter">{t("privacyPolicy.userData.registration")}</h5>
        <ol>
          <li>{t("privacyPolicy.userData.registration.listItemOne")}</li>
          <ol className="number-parenthesis">
            <li>{t("privacyPolicy.userData.registration.listItemOne.itemOne")}</li>
            <li>{t("privacyPolicy.userData.registration.listItemOne.itemTwo")}</li>
            <li>{t("privacyPolicy.userData.registration.listItemOne.itemThree")}</li>
            <li>{t("privacyPolicy.userData.registration.listItemOne.itemFour")}</li>
          </ol>
          <li>{t("privacyPolicy.userData.registration.listItemTwo")}</li>
          <li>{t("privacyPolicy.userData.registration.listItemThree")}</li>
          <ol className="number-parenthesis">
            <li>{t("privacyPolicy.userData.registration.listItemThree.itemOne")}</li>
            <li>{t("privacyPolicy.userData.registration.listItemThree.itemTwo")}</li>
            <li>{t("privacyPolicy.userData.registration.listItemThree.itemThree")}</li>
          </ol>
        </ol>
        <h5 className="subheader-three-counter">{t("privacyPolicy.userData.marketingComunication")}</h5>
        <ol>
          <li>{t("privacyPolicy.userData.marketingComunication.listItemOne")}</li>
          <li>{t("privacyPolicy.userData.marketingComunication.listItemTwo")}</li>
          <li>{t("privacyPolicy.userData.marketingComunication.listItemThree")}</li>
          <li>{t("privacyPolicy.userData.marketingComunication.listItemFour")}</li>
          <ol className="number-parenthesis">
            <li>{t("privacyPolicy.userData.marketingComunication.listItemFour.itemOne")}</li>
            <li>
              <Trans i18nKey="privacyPolicy.userData.marketingComunication.listItemFour.itemTwo">
                privacyPolicy.userData.marketingComunication.listItemFour.itemTwo[0]
                <a className="link" href={`mailto:${t("contact.gdpr.emailAddress")}`}>
                  {t("contact.gdpr.emailAddress")}
                </a>
              </Trans>
            </li>
            <li>{t("privacyPolicy.userData.marketingComunication.listItemFour.itemThree")}</li>
          </ol>
          <li>{t("privacyPolicy.userData.marketingComunication.listItemFive")}</li>
        </ol>
        <h5 className="subheader-three-counter">{t("privacyPolicy.userData.businessData")}</h5>
        <ol>
          <li>{t("privacyPolicy.userData.businessData.listItemOne")}</li>
          <li>{t("privacyPolicy.userData.businessData.listItemTwo")}</li>
          <li>{t("privacyPolicy.userData.businessData.listItemThree")}</li>
        </ol>
        <h5 className="subheader-three-counter">{t("privacyPolicy.userData.dataProcessing")}</h5>
        <ol>
          <li>{t("privacyPolicy.userData.dataProcessing.listItemOne")}</li>
          <li>{t("privacyPolicy.userData.dataProcessing.listItemTwo")}</li>
          <li>{t("privacyPolicy.userData.dataProcessing.listItemThree")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("privacyPolicy.dataCollectedAutomatically.header")}</h4>
        <ol>
          <li>{t("privacyPolicy.dataCollectedAutomatically.listItemOne")}</li>
          <li>{t("privacyPolicy.dataCollectedAutomatically.listItemTwo")}</li>
          <li>{t("privacyPolicy.dataCollectedAutomatically.listItemThree")}</li>
          <li>{t("privacyPolicy.dataCollectedAutomatically.listItemFour")}</li>
          <li>{t("privacyPolicy.dataCollectedAutomatically.listItemFive")}</li>
          <li>{t("privacyPolicy.dataCollectedAutomatically.listItemSix")}</li>
          <li>{t("privacyPolicy.dataCollectedAutomatically.listItemSeven")}</li>
        </ol>
      </section>
      <section>
        <h4>{t("privacyPolicy.Cookies.header")}</h4>
        <ol>
          <li>{t("privacyPolicy.Cookies.listItemOne")}</li>
          <li>{t("privacyPolicy.Cookies.listItemTwo")}</li>
          <li>{t("privacyPolicy.Cookies.listItemThree")}</li>
          <li>{t("privacyPolicy.Cookies.listItemFour")}</li>
          <li>{t("privacyPolicy.Cookies.listItemFive")}</li>
          <ol className="lower-alpha-parenthesis">
            <li>{t("privacyPolicy.Cookies.listItemFive.itemOne")}</li>
            <li>{t("privacyPolicy.Cookies.listItemFive.itemTwo")}</li>
          </ol>
          <p className="font-size-large negative-margin-left">
            <span className="uppercase">{t("privacyPolicy.Cookies.subheaderOne.partOne")}</span>
            <span>{" - " + t("privacyPolicy.Cookies.subheaderOne.partTwo")}</span>
          </p>
          <li>{t("privacyPolicy.Cookies.listItemSix")}</li>
          <ol className="lower-alpha-parenthesis">
            <li>{t("privacyPolicy.Cookies.listItemSix.itemOne")}</li>
            <li>{t("privacyPolicy.Cookies.listItemSix.itemTwo")}</li>
            <li>{t("privacyPolicy.Cookies.listItemSix.itemThree")}</li>
          </ol>
          <li>{t("privacyPolicy.Cookies.listItemSeven")}</li>
          <ol className="number-parenthesis">
            <li>{t("privacyPolicy.Cookies.listItemSeven.itemOne")}</li>
            <ol className="lower-alpha-parenthesis">
              <li>
                <span>
                  <u>{t("privacyPolicy.Cookies.listItemSeven.itemOne.subitemOne.partOne")}</u>
                </span>
                <span>{" - " + t("privacyPolicy.Cookies.listItemSeven.itemOne.subitemOne.partTwo")}</span>
              </li>
              <li>
                <span>
                  <u>{t("privacyPolicy.Cookies.listItemSeven.itemOne.subitemTwo.partOne")}</u>
                </span>
                <span>
                  {" - "}
                  <Trans i18nKey="privacyPolicy.Cookies.listItemSeven.itemOne.subitemTwo.partTwo">
                    privacyPolicy.Cookies.listItemSeven.itemOne.subitemTwo.partTwo[0]
                    <a className="link" href={`https://${t("privacyPolicy.googlePolicies")}`} target="_blank">
                      {t("privacyPolicy.googlePolicies")}
                    </a>
                  </Trans>
                </span>
              </li>
            </ol>
            <li>{t("privacyPolicy.Cookies.listItemSeven.itemTwo")}</li>
            <ol className="lower-alpha-parenthesis">
              <li>{t("privacyPolicy.Cookies.listItemSeven.itemTwo.subitemOne")}</li>
              <li>{t("privacyPolicy.Cookies.listItemSeven.itemTwo.subitemTwo")}</li>
            </ol>
          </ol>
          <p className="font-size-large negative-margin-left">
            <span className="uppercase">{t("privacyPolicy.Cookies.subheaderTwo")}</span>
          </p>
          <li>{t("privacyPolicy.Cookies.listItemEight")}</li>
          <ol className="lower-alpha-parenthesis">
            <li>{t("privacyPolicy.Cookies.listItemEight.itemOne")}</li>
            <li>{t("privacyPolicy.Cookies.listItemEight.itemTwo")}</li>
            <li>{t("privacyPolicy.Cookies.listItemEight.itemThree")}</li>
          </ol>
          <p className="font-size-large negative-margin-left">
            <span className="uppercase">{t("privacyPolicy.Cookies.subheaderThree.partOne")}</span>
            <span>{" - " + t("privacyPolicy.Cookies.subheaderThree.partTwo")}</span>
          </p>
          <li>{t("privacyPolicy.Cookies.listItemNine")}</li>
          <ol className="lower-alpha-parenthesis">
            <li>{t("privacyPolicy.Cookies.listItemNine.itemOne")}</li>
            <li>{t("privacyPolicy.Cookies.listItemNine.itemTwo")}</li>
          </ol>
          <li>{t("privacyPolicy.Cookies.listItemTen")}</li>
          <li>{t("privacyPolicy.Cookies.listItemEleven")}</li>
          <p className="font-size-large negative-margin-left">
            <span className="uppercase">{t("privacyPolicy.Cookies.subheaderFour")}</span>
          </p>
          <li>{t("privacyPolicy.Cookies.listItemTwelve")}</li>
          <ol className="lower-alpha-parenthesis">
            <li>
              <span>{t("privacyPolicy.Cookies.listItemTwelve.itemOne.term")}</span>
            </li>
            <p>
              <Trans i18nKey="privacyPolicy.Cookies.listItemTwelve.itemOne.explanation">
                privacyPolicy.Cookies.listItemTwelve.itemOne.explanation[0]
                <a className="link" href={t("privacyPolicy.googlePolicies.partners")} target="_blank">
                  {t("privacyPolicy.googlePolicies.partners")}
                </a>
              </Trans>
            </p>
            <li>
              <span>{t("privacyPolicy.Cookies.listItemTwelve.itemTwo.term")}</span>
            </li>
            <p>
              <Trans i18nKey="privacyPolicy.Cookies.listItemTwelve.itemTwo.explanation">
                privacyPolicy.Cookies.listItemTwelve.itemTwo.explanation[0]
                <a className="link" href={t("privacyPolicy.googlePolicies.technologies")} target="_blank">
                  {t("privacyPolicy.googlePolicies.technologies")}
                </a>
              </Trans>
            </p>
          </ol>
          <p className="font-size-large negative-margin-left">
            <span className="uppercase">{t("privacyPolicy.Cookies.subheaderFive")}</span>
          </p>
          <li>{t("privacyPolicy.Cookies.listItemThirteen")}</li>
          <li>{t("privacyPolicy.Cookies.listItemFourteen")}</li>
          <li>{t("privacyPolicy.Cookies.listItemFifteen")}</li>
          <li>{t("privacyPolicy.Cookies.listItemSixteen.partOne")}</li>
          <ol className="lower-alpha-parenthesis">
            <li>{t("privacyPolicy.Cookies.listItemSixteen.itemOne")}</li>
            <li>{t("privacyPolicy.Cookies.listItemSixteen.itemTwo")}</li>
            <li>{t("privacyPolicy.Cookies.listItemSixteen.itemThree")}</li>
            <li>{t("privacyPolicy.Cookies.listItemSixteen.itemFour")}</li>
            <li>{t("privacyPolicy.Cookies.listItemSixteen.itemFive")}</li>
            <li>{t("privacyPolicy.Cookies.listItemSixteen.itemSix")}</li>
          </ol>
          <p>{t("privacyPolicy.Cookies.listItemSixteen.partTwo")}</p>
        </ol>
      </section>
      <section>
        <h4>{t("privacyPolicy.finalProvisions.header")}</h4>
        <ol>
          <li>
            <Trans i18nKey="privacyPolicy.finalProvisions.listItemOne">
              privacyPolicy.finalProvisions.listItemOne[0]
              <span className="font-weight-bold">{t("contact.www")}</span>
            </Trans>
          </li>
          <li>{t("privacyPolicy.finalProvisions.listItemTwo")}</li>
          <li>{t("privacyPolicy.finalProvisions.listItemThree")}</li>
          <ol className="number-parenthesis">
            <li>{t("privacyPolicy.finalProvisions.listItemThree.itemOne")}</li>
            <li>{t("privacyPolicy.finalProvisions.listItemThree.itemTwo")}</li>
            <li>{t("privacyPolicy.finalProvisions.listItemThree.itemThree")}</li>
          </ol>
          <li>{t("privacyPolicy.finalProvisions.listItemFour")}</li>
        </ol>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;
