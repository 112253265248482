import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

const PersonalDataLegals: React.FC = () => {
  const { t } = useTranslation();
  const [showFullDisclaimer, setShowFullDisclaimer] = useState(false);

  const toggleFullDisclaimer = () => {
    setShowFullDisclaimer((prevState: any) => {
      return !prevState;
    });
    return showFullDisclaimer;
  };
  return (
    <div className="d-flex py-2 legals">
      <div className="disclaimer w-100  txt-color-bluish-grey border rounded-0 p-3">
        <div className="row mt-1 mb-2">
          <div className="col-md-8">
            <h5 className="m-0 ">{t("registration.form.personalData.legal.disclaimer.partOne.header")}</h5>
          </div>
          <div className="col-md-4 d-flex flex-row-reverse">
            <button onClick={toggleFullDisclaimer} type="button" className="link bg-transparent btn p-0 ">
              {showFullDisclaimer ? t("rollup") : t("rollout")}
            </button>
          </div>
        </div>
        {showFullDisclaimer ? (
          <div style={{ whiteSpace: "pre-line" }}>
            <p className="paragraph">
              {t("registration.form.personalData.legal.disclaimer.partOne")}{" "}
              <Trans i18nKey="registration.form.personalData.legal.disclaimer.privacyPolicy">
                registration.form.personalData.legal.disclaimer.privacyPolicy[0]
                <Link to="/privacy" target="_blank">
                  {t("privacyPolicy.declension")}
                </Link>
              </Trans>
            </p>
          </div>
        ) : (
          <p className="paragraph">
            {t("registration.form.personalData.legal.disclaimer.partOne").slice(0, 95) + "..."}
          </p>
        )}
      </div>
    </div>
  );
};

export default PersonalDataLegals;
