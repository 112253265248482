// DynamicForm.tsx (Child Component)
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Input from "components/Inputs/Input";
import Select, { ValueType, OptionsType } from "react-select";

interface DynamicFormProps {
  form: ReturnType<typeof useForm>;
}

interface FieldOption {
  label: string;
  value: string;
}

const DynamicForm: React.FC<DynamicFormProps> = ({ form }) => {
  const [selectedFields, setSelectedFields] = useState<OptionsType<FieldOption>>([]);
  const [userEnteredValues, setUserEnteredValues] = useState<Record<string, string>>({});
  const { register, errors, setValue, getValues, unregister } = form;

  const fieldOptions: FieldOption[] = [
    { label: "(11) Data produkcji (format: RRMMDD)", value: "n11" },
    { label: "(13) Data pakowania (format: RRMMDD)", value: "n13" },
    { label: '(15) Data "najlepsze do" (format: RRMMDD)', value: "n15" },
    { label: "(21) Numer seryjny (max 20 znaków)", value: "n21" },
    { label: "(22) Wariant produktu (max 20 znaków)", value: "n22" },
    { label: "(3103) Waga netto w kilogramach (np. 000123) = 0.123kg", value: "n3103" }, // chars: 6 ok
    {
      label: "(422) Kraj pochodzenia w formacie ISO 3166 produktu/jednostki handlowej (np. 616)",
      value: "n422", //chars: 3
    },
    { label: "(7008) Gatunek ryby zgodnie z kodem 3-alfa (np. COD)", value: "n7008" }, // chars: 1-3 ok
    { label: "(7009) Narzędzie połowu (np. SUX)", value: "n7009" }, // chars: 1-10 ok
    { label: "(7007) Data połowu lub zakres dat połowu (format: RRMMDDrrmmdd)", value: "n7007" }, // chars: 6-12 ok
    { label: "(7005) Obszar połowu (np. 31)", value: "n7005" }, // chars: 1-12 ok
    { label: "(7006) Data pierwszego mrożenia (format: RRMMDD)", value: "n7006" }, // ok
  ];

  const isString = (value: unknown): value is string => typeof value === "string";

  useEffect(() => {
    if (!selectedFields) {
      return;
    }
    // Update with new field values, but skip user-entered values
    selectedFields.forEach((field) => {
      const fieldKey = field.value;
      const fieldValue = getValues({ nest: true })[fieldKey];
      if (!userEnteredValues.hasOwnProperty(fieldKey)) {
        setValue(fieldKey, isString(fieldValue) ? fieldValue : "");
      }
    });

    // Unregister fields that were removed
    Object.keys(userEnteredValues).forEach((fieldKey) => {
      if (!selectedFields.some((field) => field.value === fieldKey)) {
        unregister(fieldKey);
      }
    });
  }, [selectedFields, userEnteredValues, setValue, unregister, getValues]);

  const handleFieldSelection = (newSelection: ValueType<FieldOption>) => {
    const newFields = newSelection as OptionsType<FieldOption>;
    setSelectedFields(newFields);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const { value } = e.target;
    setUserEnteredValues((prevData) => ({ ...prevData, [fieldName]: value }));
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-12">
          <label>Dodatkowe atrybuty:</label>
          <Select
            options={fieldOptions}
            isMulti
            placeholder="Wybierz"
            onChange={handleFieldSelection}
            value={selectedFields}
            className="w-100" // Ensure full width
          />
        </div>
      </div>

      <div className="row">
        {selectedFields &&
          (selectedFields as FieldOption[])
            .sort((a, b) => a.label.localeCompare(b.label)) // Sort by label
            .map((field: FieldOption) => (
              <div className="col-4" key={field.value}>
                <Input
                  labelText={field.label}
                  customLabelClassName="mb-0"
                  name={field.value}
                  placeholder=" "
                  error={errors[field.value]}
                  reference={register}
                  value={userEnteredValues[field.value] || ""}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, field.value)}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

export default DynamicForm;
