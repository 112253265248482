import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

type Step = {
  id: string;
};

interface RegisterNavigation {
  step: Step;
  steps: Step[];
}

interface StepDot {
  id: string;
  isActive: boolean;
  isDone: boolean;
  index: number;
}

const StepDot: React.FC<StepDot> = ({ id, isActive, isDone, index }) => {
  const { t } = useTranslation();
  const stepName = t(`form.steps.${id}`);
  return (
    <div className="nav-step">
      <div className={classNames("nav-step__dot", { active: isActive, done: isDone })}>
        {isDone ? <i></i> : index + 1}
      </div>
      <p className="nav-step__name">{stepName}</p>
    </div>
  );
};

const RegisterNavigation: React.FC<RegisterNavigation> = ({ step, steps }) => {
  const [currentStep, setCurrentStep] = useState<number>();

  /**
   * Stepper state
   * @param currStep
   * @returns boolean variabels about current state of stepper
   */
  function stepState(currStep: Step) {
    const currStepIndex = steps.indexOf(currStep);
    const isActive = currentStep === currStepIndex;
    const isDone = currentStep! > currStepIndex;
    const isLast = currStepIndex + 1 === steps.length;
    return { isActive, isDone, isLast };
  }

  useEffect(() => {
    setCurrentStep(steps.indexOf(step));
  }, [step]);

  return (
    <div className="d-flex justify-content-center">
      {steps.map((step: Step, index: number) => {
        const { isActive, isDone, isLast } = stepState(step);
        const isFirstDot = index === 0;
        return !isFirstDot ? (
          <div key={step.id} className="nav-steps">
            <StepDot id={step.id} isActive={index !== 1 ? isActive : true} isDone={isDone} index={index - 1} />
            {!isLast && <hr />}
          </div>
        ) : null;
      })}
    </div>
  );
};

export default RegisterNavigation;
