import React from "react";
import Input from "components/Inputs/Input";
import InputIcon from "components/Inputs/InputIcon";
import { Form } from "interfaces/ActivationInterface";
import { useTranslation } from "react-i18next";
import NPWSTooltips from "components/Tooltip/TooltipValues";

const PasswordInputForm: React.FC<Form> = ({ form, email }) => {
  const { t } = useTranslation();

  return (
    <>
      <div hidden className="secure-field">
        <Input
          labelText={t("emailAddress")}
          name="email"
          type="text"
          value={email}
          disabled={true}
          reference={form.register}
          data-testid="email"
          autocomplete="username"
        />
      </div>
      {/* Quick explaination on why we hide this field:
      https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands*/}
      <Input
        labelText={t("password")}
        name="password"
        type="password"
        data-testid="password"
        error={form.errors.password}
        reference={form.register}
        autocomplete="new-password"
        inputIcon={
          <InputIcon tooltipText={NPWSTooltips.PasswordRequirments} show={true} src="/icons/icon-help-enabled.svg" />
        }
      />
      <Input
        labelText={t("password.repeat")}
        name="passwordRepeat"
        type="password"
        data-testid="passwordRepeat"
        error={form.errors.passwordRepeat}
        reference={form.register}
        autocomplete="new-password"
      />
    </>
  );
};

export default PasswordInputForm;
