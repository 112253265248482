import { useSelector } from "react-redux";
import { State } from "interfaces/StoreInterface";

const useAuthentication = () => {
  const account = useSelector((state: State) => state.account);
  const { user, authenticated, activeMembership } = account;
  return { user, authenticated, activeMembership };
};

export default useAuthentication;
