import * as ActivationAction from "store/actions/ActivationActions";

type ActivationActions = ActivationAction.ActivationAction;

export interface ActivationState {
  emailChecked: boolean;
  isFullRegistration: boolean;
  hasUserActivatedMembership: boolean;
  disablePersonalForm: boolean;
  personal: ActivationAction.ActivationUserData;
}

export const initialActivationState: ActivationState = {
  emailChecked: false,
  isFullRegistration: false,
  hasUserActivatedMembership: false,
  disablePersonalForm: false,
  personal: {
    user: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "+48",
      isAdmin: false,
      confirmedStatute: false,
      agreeToSellingEmails: "",
      agreeToSellingPhones: "",
      agreeToSharingData: "",
      agreeToMarketingEmails: "",
      agreeToMarketingPhones: "",
    },
    isCompanyRepresentative: false,
    file: null,
  },
};

/**
 * Activations reducer
 * @param [state]
 * @param action
 * @returns
 */
export default function activationReducer(state: ActivationState = initialActivationState, action: ActivationActions) {
  switch (action.type) {
    case ActivationAction.UPDATE_ACTIVATION_USER_DATA: {
      return {
        ...state,
        personal: {
          ...state.personal,
          ...action.payload,
        },
      };
    }

    case ActivationAction.RESET_FORM: {
      return {
        ...initialActivationState,
      };
    }

    case ActivationAction.DISABLE_PERSONAL_FORM: {
      return {
        ...state,
        personal: {
          ...state.personal,
          user: {
            ...state.personal.user,
            firstName: action.payload ? "xxxxxxxx" : "",
            lastName: action.payload ? "xxxxxxxx" : "",
            phoneNumber: action.payload ? "+99 999-999-999" : "+48 ___-___-___",
          },
        },
        disablePersonalForm: action.payload,
      };
    }

    case ActivationAction.UPDATE_HAS_USER_ACTIVATED_MEMBERSHIP: {
      return {
        ...state,
        hasUserActivatedMembership: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
