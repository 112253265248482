import React, { useEffect, useRef, useState } from "react";
import { EditorState, convertFromHTML, ContentState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Tab, Tabs } from "react-bootstrap";
import HomePageContainer from "../../pages/HomePage/HomePage";
import { useTranslation } from "react-i18next";
import EditorInput from "../../components/Inputs/Editor";
import { CmsService } from "../../services";
import { CmsDataInterface } from "../../interfaces/CmsInterface";
import CarouselForm from "../../components/CmsComponents/CarouselForm/CarouselForm";

import { stateToHTML } from "draft-js-export-html";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";
import HowItWorks from "pages/HomePage/HowItWorks";

const HowItWorksCMS: React.FC = () => {
  const [editorState, setEditorState] = useState<EditorState>();
  const [content, setContent] = useState<CmsDataInterface>({
    id: "",
    slug: "",
    images: undefined,
    newImages: undefined,
    content: "",
  });
  const { t } = useTranslation();
  const { setMessage } = useMessage();
  const pageSlug = "how-it-works";

  /**
   * Detects changes in editor and calls this function
   * @param editState
   * @param options
   */
  function onEditorStateChange(editState: EditorState, options: object) {
    setEditorState(editState);
    if (editorState) {
      setContent({ ...content, content: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
    }
  }

  /**
   * Gets page content
   */
  async function getPageContent() {
    const response = await CmsService.getPageContent(pageSlug);
    // OLD WAY TO RENDER CONTENT WITH BUTTON
    // const blocksFromHTML = convertFromHTML(response.data.content);	    // OLD WAY TO RENDER CONTENT WITH BUTTON
    // const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);	    // const blocksFromHTML = convertFromHTML(response.data.content);
    // setEditorState(EditorState.createWithContent(state));
    const contentBlock = htmlToDraft(response.data.content);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
    setContent(response.data);
  }

  const editor = useRef(null);

  /**
   * Saves page content
   */
  function savePageContent() {
    try {
      CmsService.savePageContent(pageSlug, content);
      setMessage(NPWSStatus.CMS_SAVE_SUCCESS);
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }

  useEffect(() => {
    if (!editorState) {
      getPageContent();
    }
  }, []);

  return (
    <div className="">
      <Tabs transition={false} defaultActiveKey="edit-mode" id="uncontrolled-tab-example">
        <Tab eventKey="edit-mode" title={t("cms.editmode")}>
          <div className="row">
            <EditorInput
              ref={editor}
              name="edit"
              onEditorStateChange={onEditorStateChange}
              editorState={editorState}
              pageSlug={pageSlug}
            />
          </div>
        </Tab>
        <Tab eventKey="preview-mode" title={t("cms.preview")}>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-end mb-5">
              <button className="btn primary" onClick={() => savePageContent()}>
                {t("cms.preview.save")}
              </button>
            </div>
            <HowItWorks preview={true} cmsData={content} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default HowItWorksCMS;
