// Auth0 Docs: Where to Store Tokens
// https://auth0.com/docs/security/store-tokens
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import ReactGA from "react-ga";

interface TokenBody {
  exp: number;
  iat: number;
}

interface Tokens {
  access: string;
  refresh: string;
}

interface ClientHooks {
  onSessionExpired?: () => void;
  onTokenRefreshed?: (data: Tokens) => void;
}

/* Config */

const COOKIE_EXPIRES = 30; // 30 days
const CSRF_ENABLED = process.env.REACT_APP_CSRF_ENABLED || false;
export const baseURL = process.env.REACT_APP_API_BASE;

if (!baseURL) {
  throw new Error("REACT_APP_API_BASE is not defined in .env file");
}

/* Http */

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
});

/* TODO: Probably we should replace this by adding parameters to the orginal URL before sending the event to backend */
/* Interceptors */
axiosInstance.interceptors.request.use((config) => {
  if (!_.isEmpty(config.params)) {
    ReactGA.pageview(window.location.pathname + "?" + new URLSearchParams(config.params).toString());
  }
  return config;
});

/* Exceptions */

class TokenNotFound extends Error {
  constructor(item: string) {
    super(`cookie "${item}" not found`);
  }
}

class RefreshTokenExpired extends Error {
  constructor() {
    super(`Refresh token expired`);
  }
}

/* Auth */

let _tokenIsRefreshing = false;
let _refreshTokenPromiseMemoized: Promise<AxiosResponse<Tokens>>;
let _hooks: ClientHooks = {};

/**
 * Gets access token
 * @throws error when no access token found
 * @returns token
 */
function getAccessToken() {
  const token = Cookies.get("accessToken");
  if (!token) {
    throw new TokenNotFound("accessToken");
  }
  return token;
}

/**
 * Gets refresh token
 * @returns token
 */
function getRefreshToken() {
  const token = Cookies.get("refreshToken");
  if (!token) {
    throw new TokenNotFound("refreshToken");
  }
  return token;
}

/**
 * Determines whether remember me is
 * @returns boolean
 */
function isRememberMe() {
  return Cookies.get("rememberMe") === "true";
}

/**
 * Sets tokens
 * @param accessToken
 * @param refreshToken
 * @param [rememberMe]
 */
function setTokens(accessToken: string, refreshToken: string, rememberMe: boolean = false) {
  const attributes = rememberMe ? { expires: COOKIE_EXPIRES } : {};
  Cookies.set("accessToken", accessToken, attributes);
  Cookies.set("refreshToken", refreshToken, attributes);
  Cookies.set("rememberMe", rememberMe.toString(), attributes);
}

/**
 * Removes tokens
 */
function removeTokens() {
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
  Cookies.remove("rememberMe");
}

/**
 * Tokens expired
 * @param token
 * @returns
 */
function tokenExpired(token: string) {
  const tokenDecoded = jwtDecode<TokenBody>(token);
  const currentTime = new Date().getTime();
  return currentTime >= tokenDecoded.exp * 1000;
}

/**
 * Agrees cookies
 */
function agreeCookies() {
  Cookies.set("cookieAgree", "true");
}

/**
 * Determines whether cookies accepted is
 * @returns boolean
 */
function isCookiesAccepted() {
  return Cookies.get("cookieAgree") === "true";
}

/**
 * Access token expired
 * @returns boolean
 */
function accessTokenExpired() {
  return tokenExpired(getAccessToken());
}

/**
 * Refreshs token expired
 * @returns boolean
 */
function refreshTokenExpired() {
  return tokenExpired(getRefreshToken());
}

/**
 * Gets active membership
 * @returns membership
 */
function getActiveMembership() {
  return Cookies.get("activeMembership");
}

/**
 * Sets active membership
 * @param membership
 * @param userEmail
 */
function setActiveMembership(membership: any, userEmail: string) {
  const activeMemberships = getActiveMembership();
  if (membership) membership.email = userEmail;

  if (activeMemberships) {
    Cookies.set("activeMembership", []);
  } else {
    membership.email = userEmail;
    Cookies.set("activeMembership", [membership]);
  }
}

/**
 *
 *  Checks if the user is authenticated
 * @returns boolean
 */
function authenticated() {
  try {
    return !refreshTokenExpired();
  } catch (e) {
    return false;
  }
}

/**
 *Sends a request to check if the user exists in DB
 *
 * @param {string} email
 * @param {string} password
 * @param {boolean} rememberMe
 * @returns response
 */
async function authenticate(email: string, password: string, rememberMe: boolean) {
  const response = await request<Tokens>({
    url: "/token/",
    method: "POST",
    data: { email, password },
  });
  setTokens(response.data.access, response.data.refresh, rememberMe);
  return response;
}

function convertParamsToKeys(params: any) {
  const convertedKeys = Object.entries(params)
    .filter(([key, value]) => value !== null && value !== undefined && value !== "")
    .map(([key, value]) => `(${key.substring(1)})${value}`)
    .join("");

  return convertedKeys;
}

async function generate2d(params: any) {
  const config: AxiosRequestConfig = {
    headers: {},
    url: "/generator_2d/generate?text=" + convertParamsToKeys(params),
    method: "GET",
  };
  try {
    const accessToken = getAccessToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
  } catch (error) {}

  return await axiosInstance.request(config);
}

async function download2d(type: string, format: string, params: any) {
  try {
    const config: AxiosRequestConfig = {
      headers: {},
      url: "/generator_2d/download/" + type + "/" + format + "?text=" + convertParamsToKeys(params),
      method: "GET",
      responseType: "blob",
    };
    try {
      const accessToken = getAccessToken();
      config.headers.Authorization = `Bearer ${accessToken}`;
    } catch (error) {}

    const response = await axiosInstance.request(config);

    if (response.data && response.data instanceof Blob) {
      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: "application/octet-stream" });

      // Create a URL for the Blob
      const fileURL = window.URL.createObjectURL(blob);

      // Create a temporary link to trigger the download
      const tempLink = document.createElement("a");
      tempLink.href = fileURL;
      tempLink.setAttribute("download", params.n01 + "." + format); // Replace 'filename.ext' with the desired filename and extension
      tempLink.style.display = "none";
      document.body.appendChild(tempLink);

      // Trigger the download by clicking the link
      tempLink.click();

      // Clean up by removing the temporary link and revoking the Blob URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(fileURL);
    }
  } catch (error) {
    console.error("Error downloading file: ", error);
  }
}

/**
 * Clears out cookies responsible for handling user
 */
function logout() {
  removeTokens();
}

/**
 * Refreshs token
 * @returns response
 */
async function refreshToken() {
  const refreshToken = getRefreshToken();
  const response = await request<Tokens>({
    url: "/token/refresh/",
    method: "POST",
    data: { refresh: refreshToken },
  });
  const rememberMe = isRememberMe();
  setTokens(response.data.access, response.data.refresh, rememberMe);
  _hooks.onTokenRefreshed && _hooks.onTokenRefreshed(response.data);
  return response;
}

/**
 *
 *
 * @template T
 * @param {AxiosRequestConfig} config
 * @returns
 */

function request<T>(config: AxiosRequestConfig) {
  config.headers = config.headers || {};
  config.headers["g-recaptcha-response"] = Cookies.get("Captha-v3") || "";
  return axiosInstance.request<T>(config);
}
/**
 * Privates request
 * @template T
 * @param config
 * @returns
 */
async function privateRequest<T>(config: AxiosRequestConfig) {
  if (refreshTokenExpired()) {
    _hooks.onSessionExpired && _hooks.onSessionExpired();
    throw new RefreshTokenExpired();
  }

  if (accessTokenExpired()) {
    // support multiple async requests
    if (!_tokenIsRefreshing) {
      // new promise with feedback
      _tokenIsRefreshing = true;
      _refreshTokenPromiseMemoized = refreshToken();
      try {
        await _refreshTokenPromiseMemoized;
        _tokenIsRefreshing = false;
      } catch (error) {
        _tokenIsRefreshing = false;
        throw error;
      }
    } else if (_refreshTokenPromiseMemoized) {
      // current promise without feedback
      await _refreshTokenPromiseMemoized;
    }
  }

  const accessToken = getAccessToken();
  config.headers = config.headers || {};
  config.headers["Authorization"] = `Bearer ${accessToken}`;
  config.headers["g-recaptcha-response"] = Cookies.get("Captha-v3") || "";

  if (CSRF_ENABLED && config.method !== "GET") {
    const csrfToken = Cookies.get("csrftoken");
    config.headers["X-CSRFToken"] = csrfToken;
    config.withCredentials = true;
  }

  return request<T>(config);
}

/**
 * Sets hooks
 * @param newHooks
 */
function setHooks(newHooks: ClientHooks) {
  _hooks = {
    ..._hooks,
    ...newHooks,
  };
}

/* Exports */

const instances = {
  axios: axiosInstance,
};

const exceptions = {
  TokenNotFound,
  RefreshTokenExpired,
};

const GS1APIClient = {
  // Public API
  authenticated,
  isRememberMe,
  authenticate,
  logout,
  request,
  privateRequest,
  setHooks,
  exceptions,
  agreeCookies,
  isCookiesAccepted,
  getActiveMembership,
  setActiveMembership,
  generate2d,
  download2d,
  // Private
  _instances: instances,
  _getAccessToken: getAccessToken,
  _getRefreshToken: getRefreshToken,
  _accessTokenExpired: accessTokenExpired,
  _refreshTokenExpired: refreshTokenExpired,
  _setTokens: setTokens,
  _removeTokens: removeTokens,
  _refreshToken: refreshToken,
};

export default GS1APIClient;
