import React from "react";
import { useTranslation } from "react-i18next";
import {
  FULL_ACTIVATION,
  PASSWORD_ONLY_ACTIVATION,
  ONLY_BUTTON_ACTIVATION,
} from "pages/ActivateAccountPage/ActivateAccountPage";

interface ActivationSubheadersProps {
  accountActivationType: any;
}

const ActivationSubheaders: React.FC<ActivationSubheadersProps> = ({ accountActivationType }) => {
  const { t } = useTranslation();
  const headerRenderer = (translation: string) => {
    return (
      <>
        <p className="paragraph mb-3 d-inline">{t(translation)}</p>
      </>
    );
  };

  switch (accountActivationType) {
    case FULL_ACTIVATION:
      return headerRenderer("fullActivation.additional.description");
    case PASSWORD_ONLY_ACTIVATION:
      return headerRenderer("passwordOnlyActivation.additional.description");
    case ONLY_BUTTON_ACTIVATION:
      return headerRenderer("onlyButtons.additional.description");
    default:
      return <></>;
  }
};

export default ActivationSubheaders;
