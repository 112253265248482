import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CMSPage = () => {
  const { t } = useTranslation();
  return (
    <div className="database row d-flex justify-content-center align-items-center login-page mt-5">
      <div className="col-12">
      <p>
          <Link to="/homepage_cms">Strona: Homepage</Link>
        </p>

      <p>
          <Link to="/how_it_works_cms">Strona: Jak to działa</Link>
        </p>

        <p>
          <Link to="/verify_data_cms">Strona: Zweryfikuj dane</Link>
        </p>
      </div>
    </div>
  );
};

export default CMSPage;
