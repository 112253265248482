import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

interface FileTableProps {
  columns: Record<string, any>[];
  products: any;
}

const ProductsTable: React.FC<FileTableProps> = ({ columns, products }) => {
  return (
    <ReactTable
      manual={true}
      resizable={false}
      sortable={false}
      pageSize={products.length}
      showPagination={false}
      columns={columns}
      data={products}
    />
  );
};

export default ProductsTable;
