import classNames from "classnames";
import React from "react";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";

export type RadioOptions = {
  value: string;
  labelValue: string;
};

interface RadioType {
  name: string;
  error: FieldError | FieldError[] | NestDataObject<any> | NestDataObject<any>[] | undefined;
  reference: (ref: Element | null) => void;
  options: RadioOptions[];
  defaultCheckedValue?: string;
  inLine?: boolean;
  setValue: (name: string, e: string, shouldValidate: boolean) => void;
  tabIndex?: number;
  className?: string;
  errorTestId?: string;
}

const Radio: React.FC<RadioType> = ({
  name = "",
  error = undefined,
  reference,
  options,
  defaultCheckedValue,
  children,
  inLine = false,
  setValue,
  tabIndex = 1,
  className = "",
  errorTestId = "",
}) => {
  return (
    <div className={className + " form-group radio " + `${inLine ? "d-flex flex-row " : "d-flex flex-column "}`}>
      {options.map((option, index) => (
        <div className={`form-check mr-2 ${inLine ? "d-flex flex-row " : "d-flex flex-column "}`} key={option.value}>
          <input
            ref={reference}
            className="form-check-input"
            type="radio"
            name={name}
            id={`${name + index}`}
            value={option.value}
            tabIndex={tabIndex}
            data-testid={name}
            defaultChecked={defaultCheckedValue === option.value}
          />
          <label
            className={classNames("form-check-label")}
            htmlFor={option.value}
            onClick={() => setValue(name, option.value, true)}
          >
            {option.labelValue}
          </label>
        </div>
      ))}
      <div data-testid={errorTestId} className={classNames({ "text-danger": error })}>
        {children}
      </div>
    </div>
  );
};

export default Radio;
